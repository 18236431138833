/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../outpace/modules/auth'
import {useDispatch} from 'react-redux'

export function AsideFooterMain() {
  const {logout} = useAuth()
  const dispatch = useDispatch()

  const processLogout = () => {
    //TODO: Should <a> be replaced with Link to Logout component instead of this function call
    //Clear redux state on logout
    dispatch({type: 'RESET_APP'})
    logout()
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <a target='_blank' className='menu-link' href='https://docs.outpace.ai/v1/Quickstart'>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen002.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Quickstart</span>
        </a>
      </div>

      <div className='menu-item'>
        <a target='_blank' className='menu-link' href='https://docs.outpace.ai/v1/Documentation'>
          <span className='menu-icon'>
            {/*<KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-2' />*/}
            <i className='bi bi-question-circle-fill'></i>
          </span>
          <span className='menu-title'>Documentation</span>
        </a>
      </div>

      <AsideMenuItem
        to='/account'
        // icon='/media/icons/duotune/general/gen001.svg'
        title='My Account'
        fontIcon='bi-person-circle'
      />

      <div className='menu-item'>
        <a onClick={processLogout} className='menu-link' href='#'>
          <span className='menu-icon'>
            {/*<KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-2' />*/}
            <i className='bi bi-box-arrow-left'></i>
          </span>
          <span className='menu-title'>Log Off</span>
        </a>
      </div>
    </>
  )
}
