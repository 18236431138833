/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {getUserByToken, signInGoogle} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {get_server_errors} from '../../errors/error_helper'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function SignInGoogleCallback() {
  const query = useQuery()
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  //   Useref to prevent re-rendering example ..
  //       https://blog.logrocket.com/usestate-vs-useref/
  //   const isFirstRun = useRef(true)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  // Added so that await can be used to block call until API call completes
  // without this login status is not registered and redirect to dashboard does not work
  async function getUserInfo(auth_token) {
    const user_info = await getUserByToken(auth_token)
    return user_info.data
  }

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      const key = localStorage.getItem('invite_key')
      signInGoogle(query.get('code'), key)
        .then((response) => {
          const auth_local = response.data
          saveAuth(auth_local)
          // const user = getUserInfo(auth_local.api_token)
          // setCurrentUser(user)
          // navigate(`/`, { replace: true });
          // return auth_local
          try {
            response = getUserByToken(auth_local.api_token)
            localStorage.setItem('invite_key', '')
            return response
          } catch (error) {
            let errors = get_server_errors(error)
            saveAuth(undefined)
            setLoading(false)
          }
        })
        .then((response) => {
          const user = response.data
          setCurrentUser(user)
          navigate(`/`, {replace: true})
        })
        .catch((error) => {
          localStorage.setItem('invite_key', '')
          let error_message = get_server_errors(error)
          setErrorMsg(error_message)
          saveAuth(undefined)
          setLoading(false)
        })
    } else {
      
      // navigate(`/dash/dashboard`, { replace: true });
    }
  }, [])

  return (
    <>
      {errorMsg && (
        <div className='mb-10 text-center '>
          {/* begin::Title */}
          <div className='text-gray-800 fw-bold mb-10'>{errorMsg}</div>
          <div className='text-gray-800 fw-bold mb-10'>
            Please contact support@outpace.ai for help with activating your account.
          </div>
          {/* end::Title */}
        </div>
      )}

      {errorMsg.length == 0 && (
        <div className='text-center mb-10'>
          <div className='text-gray-400 fw-bold fs-4'>Logging in to Outpace</div>
        </div>
      )}
    </>
  )
}
