/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import RichEditor from 'src/outpace/partials/misc/RichEditor';


export function ProtoComponent() {
  const [plotlyData, setPlotlyData] = useState({});
  const dispatch = useDispatch()

  return (
      <>
        <div style={{ width: '100%', height: '100%' }}>
          In component
          <RichEditor/>
        </div>        
      </>
  )
}
