export const options = [
  { value: 'act.note', label: 'Note', classN: 'btn-light-primary', extra2: 'e2', style: '' },
  { value: 'act.question', label: 'Question', classN: 'btn-light-primary', extra2: 'e2', style: '' },
  { value: 'act.alert', label: 'Alert', classN: 'btn-light-primary', extra2: 'e2', style: '' },
  {
    value: 'act.deploy',
    label: 'Model Deploy',
    classN: 'btn-light-primary',
    extra2: 'e2',
    style: '',
  },
]

export const severity = [
  { value: 'critical', label: 'Critical', classN: 'btn-light-warning', extra2: 'e2', style: '' },
  { value: 'warning', label: 'Warning', classN: 'btn-light-warning', extra2: 'e2', style: '' },
  { value: 'info', label: 'Info', classN: 'btn-light-warning', extra2: 'e2', style: '' },
]

export const status = [
  { value: 'open', label: 'Open', classN: 'btn-light-success', extra2: 'e2', style: '' },
  { value: 'closed', label: 'Closed', classN: 'btn-light-success', extra2: 'e2', style: '' },
  { value: 'none', label: 'None', classN: 'btn-light-success', extra2: 'e2', style: '' },
]
