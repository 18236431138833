import { createSlice } from '@reduxjs/toolkit';

// "filters": {
//     "1": {
//         "condition": "is", ... selected option from condition dropdown
//         "field": "conv_id",
//         "id": 1698784807775, ... random number for uniqueness of ui
//         "inputType": 1, ... to display the input elements, 1 for single input, 2 for tags (pills), 3 for betweem
//         "start_value": "a6e6cfc6-ed32-4d54-b663-cf3dfb0a120f"
//         "end_value": "a6e6cfc6-ed32-4d54-b663-cf3dfb0a120f" ... string or number
//     }
// }

const singleQueryInitialState = {
    instructions: {
        method: '',
    },
    aggregations: {
        type: '',
        field: '',
        interval: '',
    },
    application_id: '',
    metric: '',
    datasets: [
        {
            artifactId: '',
            startDate: '',
            endDate: '',
            filters: [],
            name: '',
            metric: ''
        },
        {
            artifactId: '',
            startDate: '',
            endDate: '',
            filters: [],
            name: '',
            metric: ''
        },
    ],
};

const initialState = {
    queries: {}
};

export const llmQueryStateSlice = createSlice({
    name: 'llmQueryState',
    initialState: initialState,
    reducers: {
        reset: () => {
            return initialState
        },

        setInstructions: (state, action) => {
            const { queryKey = 'default', instructions } = action.payload;
            if (!state.queries[queryKey]) return;
            state.queries[queryKey].instructions = instructions;
        },

        setAggregations: (state, action) => {
            const { queryKey = 'default', aggregations } = action.payload;
            if (!state.queries[queryKey]) return;
            state.queries[queryKey].aggregations = aggregations;
        },

        setApplicationId: (state, action) => {
            const { queryKey = 'default', application_id } = action.payload;
            if (!state.queries[queryKey]) return;
            state.queries[queryKey].application_id = application_id;
        },

        setMetric: (state, action) => {
            const { queryKey = 'default', metric } = action.payload;
            if (!state.queries[queryKey]) return;
            state.queries[queryKey].metric = metric;
        },

        setDatasetFilters: (state, action) => {
            // // For setting dataset filters
            // dispatch(setDatasetFilters({
            //     queryKey: 'uniqueKey1',
            //     datasetIndex: 0, // or whichever index you're targeting
            //     filters: { /* your filters here */ }
            // }));
            const { queryKey = 'default', datasetIndex, filters } = action.payload;
            if (!state.queries[queryKey]) return;
            if (state.queries[queryKey].datasets[datasetIndex]) {
                state.queries[queryKey].datasets[datasetIndex].filters = filters;
            }
        },

        addFilter: (state, action) => {
            const { queryKey = 'default', datasetIndex, filter } = action.payload;
            if (!state.queries[queryKey]) return;
            if (!state.queries[queryKey].datasets[datasetIndex]) return;
            state.queries[queryKey].datasets[datasetIndex].filters[filter.id] = filter;
        },

        removeFilter: (state, action) => {
            const { queryKey = 'default', datasetIndex, filterKey } = action.payload;
            if (!state.queries[queryKey]) return;
            if (!state.queries[queryKey].datasets[datasetIndex]) return;
            delete state.queries[queryKey].datasets[datasetIndex].filters[filterKey];
        },
        
        setDataset: (state, action) => {
            // For setting dataset
            // dispatch(setDataset({
            //     queryKey: 'uniqueKey1',
            //     datasetIndex: 0, // or whichever index you're targeting
            //     name: 'newDatasetName',
            //     // ... other properties
            // }));
            const { queryKey = 'default', datasetIndex, name, ...rest } = action.payload;
            if (!state.queries[queryKey]) return;
            if (state.queries[queryKey].datasets[datasetIndex]) {
                state.queries[queryKey].datasets[datasetIndex] = {
                    ...state.queries[queryKey].datasets[datasetIndex],
                    name,
                    ...rest,
                };
            }
        },

        initNewQuery: (state, action) => {
            const { queryKey } = action.payload;
            state.queries[queryKey] = { ...singleQueryInitialState };
        },

        // setWholeQuery: (state, action) => {
        //     const { queryKey = 'default', query } = action.payload;
        //     if (!state.queries) state.queries = {};
        //     state.queries[queryKey] = query;
        // },

        updateNewQuery: (state, action) => {
            // Usage: 
            // dispatch(updateNewQuery({ queryKey: 'uniqueKey1', query: { metric: 'newMetric', application_id: 'newAppId' } }));
            const { queryKey = 'default', query } = action.payload;
        
            if (!state.queries[queryKey]) {
                // If query doesn't exist, initialize it
                state.queries[queryKey] = { ...singleQueryInitialState, ...query };
            } else {
                state.queries[queryKey] = { ...state.queries[queryKey], ...query };
            }
        },

        addNewQuery: (state, action) => {
            // Replaces entire existing query
            // Usage: 
            // dispatch(addNewQuery({ queryKey: 'uniqueKey1', query: { metric: 'newMetric', application_id: 'newAppId' } }));
            const { queryKey = 'default', query } = action.payload;        
            state.queries[queryKey] = { ...singleQueryInitialState, ...query };
        },

        removeQuery: (state, action) => {
            const { queryKey } = action.payload;
            delete state.queries[queryKey];
        },

        updateDatasetDateRange: (state, action) => {
            const { queryKey, datasetIndex, startDate, endDate } = action.payload;
            
            // Check if the specified queryKey exists
            if (!state.queries[queryKey]) return;
    
            // Check if the specified datasetIndex is within bounds
            const dataset = state.queries[queryKey].datasets[datasetIndex];
            if (dataset) {
                dataset.startDate = startDate;
                dataset.endDate = endDate;
            }
        },        
    },
});

export const {
    reset,
    setInstructions,
    setAggregations,
    setApplicationId,
    setMetric,
    setDataset,
    setDatasetFilters,
    addFilter,
    removeFilter,
    initNewQuery,
    addNewQuery,
    updateNewQuery,
    removeQuery,
    updateDatasetDateRange,
} = llmQueryStateSlice.actions;

export default llmQueryStateSlice.reducer;
