import {AuthModel} from './_models'
import {logout_server} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  logout_server()
    .then(() => {
      try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
      } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
      }
    })
    .catch(error => {
      console.error('Unable to logout from backend', error)
    })
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
