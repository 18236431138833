import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
// import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {shallowEqual, useSelector} from 'react-redux'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  // toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-2x'

const Topbar: FC = () => {
  const {config} = useLayout()

  const {applicationState} = useSelector(
    (state: any) => ({applicationState: state.applications}),
    shallowEqual
  )

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Activities */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen007.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        {/*badge-light-danger*/}
        {applicationState.totalCount !== 0 && (
          <span className='top-0 start-100 translate-middle badge badge-danger'>
            {applicationState.totalCount}
          </span>
        )}
        {/* end::Drawer toggle */}
      </div>

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
