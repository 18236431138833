import { combineReducers } from 'redux'
import { artifactSlice } from '../partials/artifact/artifactSlice'
import { applicationsSlice } from '../modules/application/core/applicationsSlice'
import { applicationDetailSlice } from '../modules/application/core/applicationDetailSlice'

import { activityStateSlice } from './state/activityStateSlice'
import {queryStateSlice} from './state/queryStateSlice'
import { llmQueryStateSlice } from './state/llmQueryStateSlice'

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }

  return allReducers(state, action)
}

export const allReducers = combineReducers({
  artifacts: artifactSlice.reducer,
  applications: applicationsSlice.reducer,
  application: applicationDetailSlice.reducer,
  activity: activityStateSlice.reducer,
  query: queryStateSlice.reducer,
  llmqueries: llmQueryStateSlice.reducer,
  //  auth: authSlice.reducer,
})
