import axios from 'axios'
import {API_URL} from "../../../constants";

class UploadFilesService {
  upload(file, info, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file);
    let json = {
      application_id: info.id,
      object_id: info.annotId,
      object_category: "art.activity",
    }
    formData.append("outpace_body_json", JSON.stringify(json))

    return axios.post(`${API_URL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return axios.get("/files");
  }
}

export default new UploadFilesService();