/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useLayoutEffect, useRef } from 'react'
import Plot from 'react-plotly.js'

import { AnnotateModal } from '../../../modules/annotation/component/AnnotateModal'
import axios from 'axios'
import { API_URL } from '../../../../constants'
import { template_plotly_white } from './plotly_template'
import { Spinner } from 'react-bootstrap'

export const PlotlyWrapper = (props) => {
  // props: {query, plotly, act, size
  //    query: server side query object
  //    plotly: contains "data" and "layout" to render Plot component
  //            if plotly is not provided, PlotlyWrapper will call server to get plotly data using query 
  //    source: where the request originated. Potential values are:
  //           similar to <Activity> "tab", "navbar", "notif", "singleview"
  //           "compare" when coming from Investigate -> Compare for smaller size
  //    act: activity [currently not used]
  //    size: size of the plot object, "small", "medium", "large", default is medium
  //    width: width of the plot object, default is 700
  //    height: height of the plot object, default is 400

  const query = props.query
  const plotly = props.plotly;  
  const source = props.source || 'tab'
  const [showModal, setShowModal] = useState(false)
  const [ts, setTs] = useState('')
  // const plot_width = props.height ?? 400;
  // const plot_height = props.width ?? 700;
  const plot_margin_10 = {
    t: 10,  // set top margin to 0
    r: 10,  // right margin
    b: 10,  // bottom margin
    l: 10   // left margin
  }

  const plot_margin_20 = {
    t: 20,  // set top margin to 0
    r: 20,  // right margin
    b: 20,  // bottom margin
    l: 20   // left margin
  }

  const getSize = (source) => {
    let sz = {width: 450, height: 200}
    if (source === 'compare') {
      let sz = {width: 300, height: 100}
    } else if (source === 'notif') {
        let sz = {width: 250, height: 250}
    }
    return sz
  }

  const [chart, setChart] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [size, setSize] = useState(getSize(source));
  const containerRef = useRef(null);

  // useLayoutEffect(() => {
  //   function updateSize() {
  //     if (containerRef.current) {        
  //       setSize({
  //         width: containerRef.current.offsetWidth,
  //         // height: containerRef.current.offsetHeight
  //         height: containerRef.current.offsetHeight > 100 ? containerRef.current.offsetHeight : 100
  //       });
  //     } else {
  //       setSize({ width: 400, height: 250 });
  //     }
  //   } 
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);

  const getPlot = async (query) => {
    // Future: Use endpoint form query.instructions if present.
    // let endpoint = `${API_URL}/data?src=chart&method=${query.instructions.method}`
    
    let plot = await axios
      .post(`${API_URL}/data?src=plot&method=${query.instructions.method}`, query)
      .then((response) => {
        return response;
      })
      .catch((error) => {

        console.log({
          error: { message: error.response.data['error_message'] },
        })
        return error;
        // setChart(initChartData)3
        // setChart([])
      })
    // data returned will be in format
    // {success: true,
    //  data: {data: plotly_data, layout: plotly_layout}
    return plot;
  }


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let plot_data = {}
      try {
        if (props.plotly) {
          let temp = plotly;
          if (temp && !temp.layout) {
            temp.layout = {};
          }
          if (temp && temp.layout) {
            temp.layout.template = template_plotly_white;
            temp.layout.width = size.width;
            temp.layout.height = size.height;
            temp.layout.margin = plot_margin_10
          }
  
          if (props.plotly) {
            temp.data = props.plotly;
            if (temp.data.plotly) {
              temp.data = temp.data.plotly;
              if (temp.data.data) {
                temp.data = temp.data.data;
              }
            }
          }
          setChart(temp);
        } else if (query) {
          // TODO: Need to generalize, data fetch query must return "data": {data: plotly_data, layout: plotly_layout}
          let plotData = await getPlot(query);
  
          const keysArray = Object.keys(plotData.data.data);
          const firstKey = keysArray[0];
  
          plotData = plotData.data.data[firstKey];
          let temp = {
            layout: {},
          };
          if (temp && temp.layout) {
            temp.layout.template = plotData.plotly.layout;
            temp.layout.width = size.width;
            temp.layout.height = size.height;
            temp.layout.margin = plot_margin_10
          }
          temp.data = plotData.plotly.data;
          setChart(temp);
        }
      } catch (error) {
        // Handle the error here
        console.error("Error:", error);
      }
      setIsLoading(false);
    };
  
    fetchData();
  }, [props.plotly]);
  

  const handleDotClick = (ts, show) => {
    setTs(ts)
    setShowModal(show)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  // const p_var = plot_Test

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' ref={containerRef} >
      <div style={{ width: "75%" }}> 
        {isLoading ?
          <Spinner />
          :
          <>
            {props.ploty &&
              <>
                {props.plotly.error_message &&

                  <div> {props.plotly.error_message}  </div>
                }
                <AnnotateModal show={showModal} handleClose={handleClose} ts={ts} />
              </>
            }
            {/* <AnnotateModal show={showModal} handleClose={handleClose} ts={ts} /> */}
            <Plot
              data={chart.data}
              // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
              layout={chart.layout}
              config={{ displayModeBar: false }}
            />
          </>
        }
      </div>
    </div>
  )
}
