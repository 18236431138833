import React from 'react'

export const AnnotateDotChart = (props) => {
  // const {cx, cy, annotate} = props
  const {cx, annotate} = props
  const defaultColor = 'grey';
  const icon_color = annotate.icon_color || defaultColor
  const icon_type = annotate.icon
  const timestamp_key = props.x

  const dotMouseClick = (e) => {
    props.handleDotClick(timestamp_key, true)
  }

  const dotMouseOver = (e) => {
    // 
    // props.handleDotMouseOver
    // 
  }

  return (
    <>
      {/* question was exclamtion */}
      {icon_type === 'act.question' && (
        // react_app/public/media/icons/duotune/general/gen044.svg
        <svg
          onClick={dotMouseClick}
          onMouseOver={dotMouseOver}
          x={cx - 10}
          y={10}
          width={24}
          height={24}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect fill={icon_color} opacity='0.3' x='2' y='2' width='20' height='20' rx='10' />
          <rect
            fill={icon_color}
            x='11'
            y='14'
            width='7'
            height='2'
            rx='1'
            transform='rotate(-90 11 14)'
          />
          <rect
            fill={icon_color}
            x='11'
            y='17'
            width='2'
            height='2'
            rx='1'
            transform='rotate(-90 11 17)'
          />
        </svg>
      )}
      {icon_type === 'act.pin' && (
        // /media/icons/duotune/communication/com009.svg
        <svg
          onClick={dotMouseClick}
          onMouseOver={dotMouseOver}
          x={cx - 10}
          y={10}
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z'
            fill={icon_color}
          />
          <path
            d='M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.alert' && (
        // /media/icons/duotune/general/gen007.svg
        <svg
          onClick={dotMouseClick}
          onMouseOver={dotMouseOver}
          x={cx - 10}
          y={10}
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z'
            fill={icon_color}
          />
          <path
            d='M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.note' && (
        // /media/icons/duotune/communication/com003.svg
        <svg
          onClick={dotMouseClick}
          onMouseOver={dotMouseOver}
          x={cx - 10}
          y={10}
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
            fill={icon_color}
          />
          <path
            d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.deploy' && (
        // /media/icons/duotune/general/gen002.svg
        <svg
          onClick={dotMouseClick}
          onMouseOver={dotMouseOver}
          x={cx - 10}
          y={10}
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" 
            fill={icon_color}/>
          <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" 
            fill={icon_color}/>
        </svg>
      )}
    </>
  )
}

export const AnnotateIcon = (props) => {
  const icon_color = props.annotate.icon_color
  const icon_type = props.annotate.icon

  return (
    <>
      {icon_type === 'act.exclamation' && (
        // react_app/public/media/icons/duotune/general/gen044.svg
        <svg
          width={24}
          height={24}
          viewBox='0 0 24 24'
          fill={"black"}
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect fill={icon_color} opacity='0.3' x='2' y='2' width='20' height='20' rx='10' />
          <rect
            fill={icon_color}
            x='11'
            y='14'
            width='7'
            height='2'
            rx='1'
            transform='rotate(-90 11 14)'
          />
          <rect
            fill={icon_color}
            x='11'
            y='17'
            width='2'
            height='2'
            rx='1'
            transform='rotate(-90 11 17)'
          />
        </svg>
      )}
      {icon_type === 'act.pin' && (
        // /media/icons/duotune/communication/com009.svg
        <svg
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z'
            fill={icon_color}
          />
          <path
            d='M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.alert' && (
        // /media/icons/duotune/general/gen007.svg
        <svg
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z'
            fill={icon_color}
          />
          <path
            d='M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.note' && (
        // /media/icons/duotune/communication/com003.svg
        <svg
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            opacity='0.3'
            d='M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z'
            fill={icon_color}
          />
          <path
            d='M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z'
            fill={icon_color}
          />
        </svg>
      )}
      {icon_type === 'act.note' && (
        // /media/icons/duotune/communication/com003.svg
        <svg
          width={20}
          height={20}
          viewBox='0 0 24 24'
          fill={icon_color}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" 
            fill={icon_color}/>
          <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" 
            fill={icon_color}/>
        </svg>
      )}
    </>
  )
}
