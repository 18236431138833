import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { parseSecureHTML } from 'src/outpace/utils/core_helpers';
// import { parseSecureHTML } from './sanitizeHTML';
import axios from 'axios';
import DOMPurify from 'dompurify';

const RichEditor = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editorContent, setEditorContent] = useState('This is some html content');
  const quillRef = useRef();

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleBlur = (event) => {
        
    const relatedTarget = event.relatedTarget;
    const quillNode = quillRef.current.getEditor().container;
    
    
    if (quillNode.contains(relatedTarget)) {
      // Do not lose focus when interacting with the toolbar
      return;
    }

    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const sanitizedContent = DOMPurify.sanitize(editorContent);
      const response = await axios.post('https://your-api-endpoint.com/save', {
        content: sanitizedContent,
      });
      ;
    } catch (error) {
      console.error('Error saving content:', error);
    } finally {
      setIsEditing(false);
    }
  };

  const viewContentStyle = {
    border: isEditing ? 'none' : '1px solid light-gray'
    // padding: isEditing ? '4px' : '0',
  };

  const quillStyle = {
    border: '1px solid blue',
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  return (
    // <div onClick={handleClick} style={containerStyle}>
    <div onClick={handleClick} >
      {isEditing ? (
        <>1
          <ReactQuill
            ref={quillRef}
            value={editorContent}
            onChange={handleEditorChange}
            onBlur={handleBlur}
            modules={modules}
            style={quillStyle}
          />
          <button onClick={handleSave}>Save</button>
        </>
      ) : (
        <div style={viewContentStyle} dangerouslySetInnerHTML={parseSecureHTML(editorContent)} 
        />
      )}
    </div>
  );
};

export default RichEditor;
