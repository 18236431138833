/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import { Form, Card, Row, Col, Button, Table } from 'react-bootstrap'
import {plot_oai, plot_dc} from "../../partials/charts/plotly/plotdata";
import { PlotlyWrapper } from 'src/outpace/partials/charts/plotly/PlotlyWrapper'
import {template_plotly_white} from "../../partials/charts/plotly/plotly_template";
import Plot from "react-plotly.js";


export function ProtoOaiPlot() {
  const [plotlyDataOai, setPlotlyDataOai] = useState({});
  const [plotlyDataDc, setPlotlyDataDc] = useState({});
  const query = "select query"
  const dispatch = useDispatch()

  useEffect(() => {
    let plot_data = plot_oai
    plot_data.layout.template = template_plotly_white
    setPlotlyDataOai(plot_data);

    let plot_data_dc = plot_dc
    plot_data_dc.layout.template = template_plotly_white
    setPlotlyDataDc(plot_data_dc);
    
  }, [])

  return (
    <div className={`tab-pane p-3 px-lg-7 py-lg-5 fade show active`}>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div id='data_table'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <Card>
                <div className="row">
                    <div className="col-sm-6">
                      <h2 className="mb-3">OAI</h2>
                        <Plot
                          data={plotlyDataOai.data}
                          // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
                          layout={plotlyDataOai.layout}
                          config={{displayModeBar: false, responsive: true}}
                        />
                    </div>
                    <div className="col-sm-6">
                      <h2 className="mb-3">DC</h2>
                      {/*<PlotlyWrapper query={query} plotly={plotlyDataOai} />*/}
                        <Plot
                          data={plotlyDataDc.data}
                          // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
                          layout={plotlyDataDc.layout}
                          config={{displayModeBar: false, responsive: true}}
                        />
                    </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
