import moment from 'moment'

export function createAnnots(data) {
    let annots = []
    for (let i = 0; i < data.length; i = i + 1) {
        let annotObject = {}
        let dataObject = data[i]
        let date = new Date(dataObject.event_time_utc)
        // TODO: Perform conversion based on whether the interval is daily, hourly etc
        date.setUTCHours(0, 0, 0, 0);
        let epochTime = date.getTime()
        annotObject.annoteId = dataObject.id
        annotObject.ts_numeric = epochTime
        annotObject.icon = dataObject.type_name        
        annotObject.desc_markdown =  dataObject.desc_markdown
        // created_by is automatically set by backend based on logged in user
        // annotObject.created_by = dataObject.created_by
        annotObject.note = dataObject.description
        annotObject.status = dataObject.status
        annotObject.severity = dataObject.severity
        annotObject.icon_color = get_activity_color(dataObject.status, dataObject.severity)
        annotObject.type_name = dataObject.type_name
        annots.push(annotObject)
    }
    return annots;
}

export const get_activity_color = (status, severity) => {
  if (status === null || status === undefined || severity === null || severity === undefined) {
    return 'grey';
  }

  if (status === 'open') {
    if (severity === 'critical') {
      return 'red'
    } else if (severity === 'warning') {
      return 'orange'
    } else if (severity === 'info') {
      return 'green'
    } else {
      return 'grey'
    }
  } else {
    return 'grey'
  }
}

export function setStyles(tab, value) {
    for (var i = 0; i < tab.length; i++) {
      if (tab[i].value === value) {
        tab[i].style = "nav-link active";
      } else {
        tab[i].style = "nav-link";
      }
    }
  }


  export function getLabelName(tab, labelType) {
    for (var i = 0; i < tab.length; i++) {
      if (tab[i].value === labelType) {
        return tab[i].label
      }
    }
  }

  export const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  }
