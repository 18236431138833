/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {RequestAccess} from './components/RequestAccess'
import {ForgotPassword} from './components/ForgotPassword'
import {VerifyEmail} from './components/VerifyEmail'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {SignInGithubCallback} from './components/SignInGithubCallback'
import {SignInGoogleCallback} from './components/SignInGoogleCallback'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/omedia/logos/logo-dark.svg')} className='h-45px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://outpace.ai/about' className='text-muted text-hover-primary px-2'>
            About 1
          </a>

          <a href='https://outpace.ai/contactus' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration*' element={<Registration />} />
      <Route path='request-access' element={<RequestAccess />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='resend-email' element={<VerifyEmail />} />
      <Route path='google/' element={<SignInGoogleCallback />} />
      <Route path='github' element={<SignInGithubCallback />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
