import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import {useDispatch} from 'react-redux'

export function Logout() {
  const {logout} = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    //TODO: Where is Logout component invoked ?
    //Clear redux state on logout
    dispatch({type: 'RESET_APP'})
    // logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
