import MockAdapter from 'axios-mock-adapter'
import {API_URL} from '../../constants'
import {getAuth} from '../modules/auth'
// import * as mock_data from "./_mock_tmp_/_mock_data"
import * as mock_app_data from './_mock_tmp_/_mock_app_detail'
import * as mock_charts from './_mock_tmp_/_mock_charts'
import * as mock_activity from './_mock_tmp_/_mock_activity_log'
import * as mock_plot from './_mock_tmp_/_mock_plot'
import {syncId_dt} from './_mock_tmp_/_mock_charts'

export function setupAxiosMain(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    // (config: {headers: {Authorization: string}}) => {
    //   const auth = getAuth()
    //   if (auth && auth.api_token) {
    //     config.headers.Authorization = `Token ${auth.api_token}`
    //   }
    (config: any) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Token ${auth.api_token}`
      }
      // Pass BrowserTimezone. Solve for CORS policy error when this is enabled
      // Current hack is to pass in where needed
      // config.headers.OaiBrowserTz = Intl.DateTimeFormat().resolvedOptions().timeZone

      return config
    },
    (err: any) => Promise.reject(err)
  )
  setupMockRequests(axios)
}

function setupMockRequests(axios: any) {
  if (process.env.NODE_ENV !== 'production') {
    // Set the mock adapter on the default axios instance
    const mock = new MockAdapter(axios, {
      onNoMatch: 'passthrough', // ! set default to passthrough
    })

    // Model detail request ... Set up a mock response for requests to /application/${application_id}/models
    // mock.onGet(/\/application\/\d+\/models/).reply(200,
    //     { data: [
    //             {"id": 125, "name": "v1.0", "deployed": "12/2/23 22:10:34"},
    //             {"id": 9876, "name": "model name2", "live": "yes", "deployed": "12/2/23"}
    //         ]
    //     });

    // mock.onPost(`${API_URL}/rest/login/`).reply(200, mock_app_data.myAuth)
    //
    // mock.onPost(`${API_URL}/auth/user_profile`).reply(200, mock_app_data.myInfo)
    // mock.onGet(`${API_URL}/applications`).reply(200, mock_app_data.app)
    //
    // mock.onGet(`${API_URL}/applications/185/app_datasets`).reply(200, mock_app_data.dataSets)
    //
    // mock.onGet(`${API_URL}/applications/185/select-artifacts`).reply(200, mock_app_data.selArt)
    //
    // mock.onGet(`${API_URL}/artifacts/all.649/col_info`).reply(200, mock_app_data.artCol)
    // mock.onPost(`${API_URL}/data`).reply(200, mock_app_data.data)
    // mock.onGet(`${API_URL}/activities`).reply(200, mock_activity.mock_activity_log)
    // mock.onPost(`${API_URL}/plot`).reply(200, mock_plot.server_plot_data)
    // Mock col json for data artifact
    // mock
    //     // Request /artifacts/<<artifact id>>/col_info
    //     .onGet(/\/col_info/)
    //     .reply(200, mock_app_data.mock_field_select2)

    // mock
    //     // Request /artifacts/<<artifact id>>/col_info
    //     .onGet(/\/app_datasets/)
    //     .reply(200, mock_app_data.mock_app_datasets)

    // mock
    //     .onPost(/\/data/)
    //         .reply(200, mock_charts.syncId_dt)
    // .reply(function(config) {
    //     return new Promise(function(resolve, reject) {
    //         setTimeout(function() {
    //             resolve([200, mock_charts.syncId_data])
    //         }, 2000)
    //     })
    // })

    // ***** MOCK examples ****
    // MOCK AXIOS EXAMPLES : https://github.com/ctimmerm/axios-mock-adapter
    //   TEST REGEX: https://www.regextester.com/
    // mock delay example with Promise from: https://github.com/ctimmerm/axios-mock-adapter/issues/232

    // mock
    //     // Request /artifacts/<<artifact id>>/col_info
    //     .onGet(/\/col_info/)
    //     .reply(function(config) {
    //         return new Promise(function(resolve, reject) {
    //             setTimeout(function() {
    //                 resolve([200, mock_app_data.mock_field_select2])
    //             }, 2000)
    //         })
    //     })
    //
    // MOCK function reply example
    //     .onGet(/\/users\/\d+/)
    //     .reply(function (config) {
    //             // the actual id can be grabbed from config.url
    //             return [200, {}];
    //           })
  }
}
