import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {API_URL} from '../../../../constants'

// const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/user_profile`
// Email based login, registration
export const LOGIN_URL = `${API_URL}/rest/login/`
export const REGISTER_URL = `${API_URL}/signup/`
export const REQUEST_ACCESS_URL = `${API_URL}/invitations/request-access/`
export const REQUEST_PASSWORD_URL = `${API_URL}/rest/password/reset/`
export const VERIFY_INVITE_URL = `${API_URL}/invitations/verify-invite/`
export const LOGOUT_URL = `${API_URL}/rest/logout/`

// Resend email not tested
export const RESEND_EMAIL_URL = `${API_URL}/rest/resend_email`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  companyName: string,
  invite_key: string | null
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    company: companyName,
    password1: password,
    password2: password_confirmation,
    key: invite_key,
  })
}

// Server returns message on success
export function requestAccess(email: string, fullname: string) {
  return axios.post(REQUEST_ACCESS_URL, {
    email,
    fullname: fullname,
  })
}

// Server returns action and message based on state of token
export function verifyInvite(key: string | null) {
  if (key === undefined) {
    key = 'none'
  }
  const req = VERIFY_INVITE_URL + key
  return axios.get(req)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resendVerifyEmail(email: string) {
  return axios.post<{result: boolean}>(RESEND_EMAIL_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export const signInGoogle = (code: any, key: any) => {
  const query = key === null ? '' : `?key=${key}`
  return axios.post(`${API_URL}/auth/google/${query}`, {
    code,
  })
}

export const signInGithub = (code: any) => {
  return axios.post(`${API_URL}/auth/github/`, {
    code,
  })
}

export function logout_server() {
  return axios.post(
    LOGOUT_URL,
    {}
    // {withCredentials: true}
  )
}
