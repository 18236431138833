import axios from 'axios'
import { API_URL } from 'src/constants'

export const uploadImage = async (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/upload`, data)
      .then((response) => {
        if (response.data['success']) {
          //setActivityLog(response.data.data)
        } else {
          // Display error notification and setChart to normal
          
        }
        resolve(response)
      })
      .catch((error) => {
        
        reject(error)
      })
  })

export const addActivity = async (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/activity`, data)
      .then((response) => {
        if (response.data['success']) {
          //setActivityLog(response.data.data)
        } else {
          // Display error notification and setChart to normal
          
        }
        resolve(response)
      })
      .catch((error) => {
        
        reject(error)
      })
  })

export const editActivity = async (data, activityId) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/activity/${activityId}`, data)
      .then((response) => {
        if (response.data['success']) {
          //setActivityLog(response.data.data)
        } else {
          // Display error notification and setChart to normal
          
        }
        resolve(response)
      })
      .catch((error) => {
        
        reject(error)
      })
  })

export const getActivities = async (url) =>
  new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data['success']) {
          //setActivityLog(response.data.data)
        } else {
          // Display error notification and setChart to normal
          
        }
        resolve(response)
      })
      .catch((error) => {
        
        reject(error)
      })
  })
