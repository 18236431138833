import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const initialFilter = {
  artifactId: '',
  startDate: '',
  endDate: '',
  filters: {},
}

const initialState = {
  // controls the ability to update state after application is initialized
  appInitialized: false,
  loading: false,
  actionsLoading: false,
  investigateEnabled: false,
  applicationId: null,

  // TODO: Eventually move model_type to config.model_type
  modelType: null,
  // Stored column names indexed by data artifact id associated with the application's model
  // Keeping his separate from query since it can be used for different purposes
  colInfo: {},
  // For dataset comparisons
  compareArtifacts: [],
  // List of all datasets associated with the model associated with the Application
  datasetList: [],

  // Contains the list of fields that are shared across tabs and pages of the application
  // e.g. startDate and endDate selected by Datepicker or from the server config settings
  // Added 110123, to simplify accessing info on dates 
  commonFilterData: {
    "startDate": "",
    "endDate": "",
  },
  // moment().subtract(14, 'days').format()
  // moment().format()

  // Config associated with the application. Fetched when application is initalized
  // Current format returned from server via API call
  // {
	// 	"end_date": "2023-04-05T00:00:16.420703",
	// 	"start_date": "2023-03-22T00:00:16",
	// 	"model_type": "classification" or "LLM",
  // 	"metric": "f1", //default metric to show on overview
	// 	"baseline": {  // baseline to set for overview comparison graphs
	// 		"stage": "Prod",
	// 		"window": -3
	// 	}
  config: {},

  driftMetrics: [],

  // Metrics available based on model type and enabled functionality
  availableMetrics: [],

  error: null,

  // Metrics to render for the chart. This should be moved to Query object.
  // State should be updated when user selects a metric from the dropdown
  metric: "",

  // Stores the dataset artifact ids selected and filters associated with them from UI DetailToolbar
  // TODO: Process of being deprecated so that it can be centralized in queryStateSlice
  datasetsFilters: [{ ...initialFilter }, { ...initialFilter }],

  // TODO Nov22: Deprecated query and split into components
  // Still used by QueryFilterList component
  query: {
    instructions: {},
    application_id: '',
    // e.g. Accuracy, F1_Score
    metric: '',
    // Support for only 2 comparisons at the moment, easy to expand
    // Filters format =>
    datasets: [{ ...initialFilter }, { ...initialFilter }],
  },

  // NOT USED
  // dataMetrics: [
  //   { value: 'max', label: 'Stats' },
  //   { value: 'max', label: 'Max' },
  //   { value: 'stddev', label: 'Std Dev' },
  //   { value: 'count', label: 'Count' },
  // ],

}

  // Metrics available for classification models
export const classifAvailableMetrics = [
    { value: 'acc', label: 'Accuracy' },
    { value: 'f1', label: 'F1 Score' },
    { value: 'prec', label: 'Precision' },
    { value: 'rec', label: 'Recall' },
    { value: 'sen', label: 'Sensitivity' },
    { value: 'sp', label: 'Specificity' },
  ]

  // Metrics available for LLM models
  export const  llmAvailableMetrics = [
    { value: 'avg_qs_per_conv', label: 'Average Questions per Thread' },
    { value: 'convo_counts', label: 'Number of Conversations' },
    { value: 'llm_req_counts', label: 'Total requests to LLM' },
    { value: 'long_conversations', label: '5 longest threads' }
  ]

// Used for controlling loading and loading done states
// TODO: Needs testing 
export const callTypes = {
  overview: 'overview',
  action: 'action',
}

export const applicationDetailSlice = createSlice({
  name: 'applicationDetail',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false
      } else {
        state.loading = false
      }
    },
    // const someFn: () => undefined = () =>
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true
      } else {
        state.loading = true
      }
    },

    setCommonFilterData: (state, action) => {
      // Completely replaces the commonFilterData with the new dict
      state.commonFilterData = action.payload;
    },
  
    updateCommonFilterData: (state, action) => {
      // Only updates the keys present in the dict passed in the payload
      state.commonFilterData = {
        ...state.commonFilterData,
        ...action.payload
      };
    },

    updateApplicationData: (state, action) => {
      const applicationId = action.payload.applicationId
      const config = action.payload.config
      const datasets = action.payload.datasets

      const model_type = config.model_type
      let metrics = []
      if (model_type === "LLM") {
        metrics = llmAvailableMetrics
      } else {
        metrics = classifAvailableMetrics
      }
      state.applicationId = applicationId
      state.config = _.cloneDeep(config)
      state.modelType = model_type
      state.availableMetrics = _.cloneDeep(metrics)
      state.appInitialized = true
      state.loading = false
      state.error = null
      state.datasetList = _.cloneDeep(datasets)      
    },

    setApplicationConfig: (state, action) => {
      const { config } = action.payload
      state.config = _.cloneDeep(config)
    },

    setInvestigateState: (state, action) => {
      state.investigateEnabled = action.payload.investigateEnabled
    },
    
    applicationDatasetsFetched: (state, action) => {
      const { datasets } = action.payload
      state.loading = false
      state.error = null
      state.datasetList = _.cloneDeep(datasets)
      // Initialize query object with default dataArtifactId for graphs
      // if (datasets.length > 0) {
      //     state.query.datasets[0].dataArtifactId = datasets[0].options[0].value
      // }
    },

    artifactColumnInfo: (state, action) => {
      const { artifact_id, cols_info } = action.payload
      state.error = null
      state.colInfo[artifact_id] = _.cloneDeep(cols_info['options'])
    },

    setDatasetsFilters: (state, action) => {
      const filters = action.payload.datasetsFilters
      state.datasetsFilters = filters
    },

    setQueryMetric: (state, action) => {
      const metric = action.payload.metric
      // state.query.metric = metric
      state.metric = metric
    },

    resetApplication: (state, action) => {
      const { application_id } = action.payload
      // TODO: Clean up to remove localstorage dependency
      // START - Used by QueryFilterList
      localStorage.removeItem("oai-app-filter-1")
      localStorage.removeItem("oai-app-filter-2")
      localStorage.removeItem("oai-app-filter-appId")
      // END - Used by QueryFilterList
      // if (application_id !== state.applicationId) return { ...initialState }
      return { ...initialState }
    },
  },
})
