import React from 'react'
import { Navigate, Routes, Route, Outlet } from 'react-router-dom'

import {ProtoPlotly} from "./ProtoPlotly";
import {ProtoOaiPlot} from "./ProtoOaiPlot";
import JsonPlot from "./JsonPlot";
import { ProtoComponent } from './ProtoComponent';


const ProtoPage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='base'
        element={
          <>
            <ProtoPlotly />
          </>
        }
      />
      <Route
        path='plot'
        element={
          <>
            <ProtoOaiPlot />
          </>
        }
      />
      <Route
        path='jsonplot'
        element={
          <>
            <JsonPlot />
          </>
        }
      />
      <Route
        path='component'
        element={
          <>
            <ProtoComponent />
          </>
        }
      />
      <Route index element={<Navigate to='/proto/plot' />} />
    </Route>
  </Routes>
)

export default ProtoPage
