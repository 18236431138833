import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  // Example to render count in badge on topbar
  totalCount: 0,
  // TODO: intialize to empty array and test []
  applications: [],
  // entityStatuses: {},
  // entitySummaries: {},
  // compareArtifacts: [],
  // typeForEdit: "EDIT",
  error: null,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    // const someFn: () => undefined = () =>
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },

    applicationsFetched: (state, action) => {
      const {applications} = action.payload
      // Reset the entire state to initialState
      Object.assign(state, initialState);

      // state.listLoading = false
      // state.error = null
      state.applications = [...applications]
      state.totalCount = applications.length
    },

    removeApplication: (state, action) => {
      const application_id = action.payload?.application_id
      const updatedApplications = state.applications.filter(
        (app) => app.id !== application_id
      );
      return {
        ...state,
        applications: updatedApplications,
      };        
    },    

    // applicationStatusFetched: (state, action) => {
    //     const { entityStatus } = action.payload;
    //     state.listLoading = false;
    //     state.error = null;
    //     state.entityStatuses[entityStatus.application_id] = entityStatus;
    // },
    //
    // applicationSummaryFetched: (state, action) => {
    //     const { entitySummary } = action.payload;
    //     state.listLoading = false;
    //     state.error = null;
    //     state.entitySummaries[entitySummary.id] = entitySummary;
    // },
    //
    // applicationArtifactsSelectionFetched: (state, action) => {
    //     const { compareArtifacts } = action.payload;
    //     state.listLoading = false;
    //     state.error = null;
    //     state.compareArtifacts = _.cloneDeep(compareArtifacts);
    // },
    //
  },
})
