import DOMPurify from 'dompurify';

export const parseSecureHTML = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export function showDefaultValue(value, defaultValue = '-') {
  return value ? value : defaultValue;
}

export function getTimeFromNow(datetimeStr, return_string=true) {
  if (!datetimeStr) {
    return "-";
  }
  // Parse the datetime string into a Date object
  const datetime = new Date(Date.parse(datetimeStr + "Z"));

  // Get the current time as a Date object
  const now = new Date();

  // Calculate the difference between the two times in milliseconds
  const diff = now - datetime;

  // Convert the difference to days and hours
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  // Return the result as either a string or an object
  if (return_string) {
    if (days > 0) {
      return `${days} days, ${hours} hours ago`;
    } else {
      return `${hours} hours ago`;
    }
  } else {
      return {
        days: days,
        hours: hours };
  }
}
