/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { activityStateSlice } from 'src/outpace/redux/state/activityStateSlice';
import { KTSVG } from '../../../../_metronic/helpers';
import { format_ts_event } from "../../../partials/charts/chartHelper";
import UploadFilesService from "../../../partials/upload/uploadService";
import { createAnnots, getLabelName, setStyles } from '../helpers/ActivityUtil';
import { options, severity, status } from "../helpers/ActivityVariables";
import { addActivity, editActivity } from "../helpers/activity-context";
import ActivityMarkup from './ActivityMarkup';

const { actions } = activityStateSlice

export const AnnotateModalEdit = ({ currentAnnot, show, annotateData, handleClose, ts,
  query, queryPropName, share, userModalMessage, defaultComment }) => {


  const dispatch = useDispatch()
  const propName = queryPropName || "chart";
  const [time, setTime] = useState(new Date(ts));
  const [optionsType, setOptions] = useState(currentAnnot && currentAnnot.type_name ? currentAnnot.type_name : options[0].value);
  const [severityType, setSeverity] = useState(currentAnnot && currentAnnot.severity ? currentAnnot.severity : severity[0].value);
  const [statusType, setStatus] = useState(currentAnnot && currentAnnot.status ? currentAnnot.status : status[0].value);
  const [markdown, setMarkdown] = useState(currentAnnot && currentAnnot.note ? currentAnnot.note : "");
  const [markdownValue, setMarkdownValue] = useState(currentAnnot && currentAnnot.desc_markdown ? currentAnnot.desc_markdown : "");
  const [includeChart, setIncludeChart] = useState(true);
  // const [c, setChartInfo] = useState(annotateData.chartData ?? {});
  // const { application } = useSelector((state) => ({ application: state.application }), shallowEqual)
  const [updateType, setUpdateType] = useState((share && currentAnnot) ? "Reply" : "");
  const { annots } = useSelector((state) => ({ annots: state.activity.annots }), shallowEqual)
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');

  function setValue(valueType, value) {
    if (valueType === "options") {
      setOptions(value);
      setStyles(options, value);
    } else if (valueType === "severity") {
      setSeverity(value);
      setStyles(severity, value);
    } else {
      setStatus(value);
      setStyles(status, value);
    }
  }

  useEffect(() => {
    initButtonState(currentAnnot);
  }, [])

  function initButtonState(currentAnnot) {
    setStyles(options, currentAnnot ? currentAnnot.type_name : options[0].value);
    setStyles(severity, currentAnnot ? currentAnnot.severity : severity[0].value);
    setStyles(status, currentAnnot ? currentAnnot.status : status[0].value);
    setMarkdown(currentAnnot ? currentAnnot.note : "")
  }

  async function addImage(info) {
    try {
      let upload = await UploadFilesService.upload(file, info, (event) => {
      })
        .then((response) => {
          return response;
        })
        .catch(() => {
        });
      return upload;
    } catch (e) {

    }
  }

  async function saveAnnotation() {
    if (markdownValue.length > 1) {

      let data = {
        "type_name": optionsType,
        "application_id": annotateData.applicationId,
        "model_group_id": null,
        "artifact_id": null,
        "title": "Test mark",
        "description": markdown,
        "desc_markdown": markdownValue,
        "status": statusType,
        "severity": severityType,
        "properties": {},
      }

      if (propName === 'plot' || propName === 'table') {
        data.properties[propName] = query
      }

      if (includeChart) {
        data.properties[propName] = query
      }

      let response;

      if (currentAnnot && (currentAnnot.annoteId || currentAnnot.id) && updateType === "Reply") {
        if (currentAnnot.annoteId !== undefined) {
          data.parent_activity_id = currentAnnot.annoteId
          data.event_time_utc = time.toISOString();

        } else {
          data.parent_activity_id = currentAnnot.id
          let newTime = new Date().toISOString();
          data.event_time_utc = newTime
        }
        response = await addActivity(data);
      } else if (currentAnnot && (currentAnnot.annoteId || currentAnnot.id) && updateType === "Edit") {
        let id = currentAnnot.annoteId || currentAnnot.id;

        response = await editActivity(data, id);
      } else {
        if (!isNaN(time)) {
          data.event_time_utc = time.toISOString();
        } else {
          let dateVal = new Date();
          let timeIso = dateVal.toISOString();
          data.event_time_utc = timeIso;
        }
        response = await addActivity(data);
      }
      if (response.status === 200 || response.status === 201) {
        let newAnnot = createAnnots([response.data.data]);
        checkOrReplaceAnnot(newAnnot[0], response.data.data, updateType);
        if (file && fileUrl) {
          let info = {
            id: annotateData.applicationId,
            annotId: response.data.data.id
          }
          let responseImage = await addImage(info);
          if (responseImage.status === 200 || responseImage.status === 201) {
            let newAnnot = createAnnots([response.data.data]);
            // checkOrReplaceAnnot(newAnnot[0], responseImage.data.data, updateType);
          } else {
            ;
          }
        }
        initButtonState({});
        handleClose();
      } else {
        ;
      }
    }
  }



  function checkOrReplaceAnnot(newAnnot, newActivity, updateType) {
    let tempAnnots = annots.slice();
    // if (updateType !== "Reply") {
    if (tempAnnots.length === 0) {
      dispatch(actions.addAnnots(newAnnot))
      dispatch(actions.addActivity(newActivity))
    } else {
      let replaced = false;
      for (var i = 0; i < tempAnnots.length; i++) {
        if (tempAnnots[i].annoteId === newAnnot.annoteId) {
          dispatch(actions.replaceAnnot({ index: i, data: newAnnot }));
          dispatch(actions.replaceActivity({ index: i, data: newActivity }))
          replaced = true;
          break;
        }
      }
      if (!replaced) {
        dispatch(actions.addAnnots(newAnnot))
        dispatch(actions.addActivity(newActivity))
      }
    }
  }


  // const renderSelect = (selectType, defaultValue, label, header) => {
  //   return (
  //     <div className="pt-3">
  //       <div className="row">
  //         <div className="col-sm-2">
  //           <span className='fs-6 fw-bold mb-2 me-3'>{header}</span>
  //         </div>
  //         <div className="col-sm-10">
  //           <select class="form-select form-select-sm" aria-label=".form-select-sm example"
  //             onChange={(e) => setValue(e, label)} defaultValue={defaultValue}>
  //             {selectType.map((value => {
  //               return (
  //                 <option value={value.value}>{value.label}</option>
  //               )
  //             }))}
  //           </select>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const renderSelect = (selectType, defaultValue, label, header) => {
    return (
      // <div className="pt-3">
      <div className="col-sm-4 pb-4">
        <div className="">
          <span className='fs-6 fw-bold mb-2 me-3'>{header}</span>
        </div>
        <div className="">
          <select className="form-select form-select-sm" aria-label=".form-select-sm example"
            onChange={(e) => setValue(e, label)} defaultValue={defaultValue}>
            {selectType.map((value => {
              return (
                <option value={value.value} key={value.value} >{value.label}</option>
              )
            }))}
          </select>
        </div>
      </div>
      // </div>
    )
  }

  function setValue(e, type) {
    if (type === "options") {
      setOptions(e.target.value)
    } else if (type === "severity") {
      setSeverity(e.target.value)
    } else {
      setStatus(e.target.value);
    }
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setFileUrl(reader.result);
        setFile(file);
      };
      ;
    }
  };

  return (
    <>
      {/*TODO: Make this scrollable */}
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          {/*data-bs-dismiss='modal'>*/}
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-5 pt-0 pb-10'>
        {currentAnnot ? (
          <>
            <div className="row pb-5">
              <div className="col-sm-6">
                <h2 className='mb-3 pt-4'>
                  Event @ {format_ts_event(currentAnnot.ts_numeric)}
                </h2>
              </div>
              {!share &&
                <>
                  <div className="col-sm-2">
                    <button type='submit' className={updateType === "Edit" ? "btn btn-success" : "btn btn-secondary"} id='kt_send_invites' onClick={(e) => { setUpdateType("Edit") }}>
                      Edit
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <button type='submit' className={updateType === "Reply" ? "btn btn-success" : "btn btn-secondary"} id='kt_send_invites' onClick={(e) => { setUpdateType("Reply") }}>
                      Reply
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <Link
                      to={`/application/${annotateData.applicationId}/activity/${currentAnnot.annoteId ?? currentAnnot.id}`}
                      className='btn btn-secondary me-2'
                      id='kt_user_follow_button'
                      onClick={handleClose}
                    >Replies</Link>
                  </div>
                </>
              }
            </div>
            {updateType !== "Edit" &&
              <div className="row mb-5"
                style={{
                  backgroundColor: "white",
                  border: "1px solid black",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                  padding: "3px",
                  borderRadius: "4px",
                }}
              >
                <div className="row mt-2">
                  <div className="col-sm-8">
                    <ReactMarkdown children={currentAnnot.desc_markdown} rehypePlugins={[rehypeRaw]} />
                  </div>
                  <div className="col-sm-4">
                    <span className="badge bg-primary me-3">{getLabelName(options, currentAnnot.type_name)}</span>
                    <span className="badge bg-warning me-3">{getLabelName(severity, currentAnnot.severity)}</span>
                    <span className="badge bg-success">{getLabelName(status, currentAnnot.status)}</span>
                  </div>
                  {/* <p>Created by {currentAnnot.created_by}</p> */}
                </div>
              </div>
            }
          </>
        ) : (
          <div className='text-left mb-7'>
            <h2 className='mb-3'>Create activity @ {format_ts_event(ts)}</h2>
          </div>
        )}
        {updateType === "" && currentAnnot ?
          <></>
          :
          <div>
            {(share && !currentAnnot) ?
              <h2 className='mb-5'>Create {userModalMessage ? userModalMessage : ""} </h2> :
              <h2 className='mb-5'>{updateType} {userModalMessage ? userModalMessage : ""} </h2>
            }
            <div className="row">
              {renderSelect(options, optionsType, "options", "Type")}
              {renderSelect(severity, severityType, "severity", "Severity")}
              {renderSelect(status, statusType, "status", "Status")}
            </div>
            <div className='mb-10'>
              <div className='fs-6 fw-bold mb-2'> <p>     Comment            {
                markdownValue.length < 2 &&
                <span style={{ color: "red", fontSize: "10px" }}> *Required </span>
              }</p></div>
              <div className='mh-300px scroll-y' style={{
                border: "1px solid rgb(0, 0, 0, 0.2)", borderRadius: "5px",
              }}>
                {currentAnnot && updateType === "Edit" ?
                  <ActivityMarkup markdown={markdown} setMarkdown={setMarkdown} setMarkdownValue={setMarkdownValue} />
                  :
                  <ActivityMarkup setMarkdown={setMarkdown} setMarkdownValue={setMarkdownValue} />
                }
              </div>
            </div>
            <div className='mb-10'>
              {!currentAnnot && propName === 'chart' &&
                <div className='fs-6 fw-bold mb-2'>
                  <label className="checkbox success-checkbox">
                    <input type="checkbox" name="Checkboxes5" checked={includeChart ? "checked" : ""} onChange={() => setIncludeChart(!includeChart)} />
                    <span></span>
                    &nbsp;&nbsp;&nbsp;Include Chart
                  </label>
                </div>
              }
            </div>
            <div>
              <input className="mb-2" type="file" accept='.png, .jpg, .jpeg' onChange={(e) => onChangeFile(e)} />
            </div>
            <div className='separator d-flex flex-center mb-8 mt-3'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'></span>
            </div>
            <div className='mb-10'>
              <div className='col mb-3 mb-lg-0'>
                <button
                  type='submit'
                  className={`btn btn-light-primary ${markdownValue.length < 2 && "disabled"}`}

                  id='kt_send_invites'
                  onClick={(e) => {
                    saveAnnotation()
                  }}
                  style={{
                    float: "right"
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}
