import axios, {AxiosResponse} from 'axios'

// Format error messages from server
export function get_server_errors(error: Error | any) {
  
  let error_messages = []
  if (axios.isAxiosError(error)) {
    const res: AxiosResponse | undefined = error.response
    if (res) {
      // response.data should be format {"password1":["This password is too common."]}
      if ('error_message' in res.data) {
        // {"success":false,"error_code":500,"error_message":"Please contact Outpace Support support@outpace.ai"}
        error_messages.push(res.data.error_message)
      } else {
        // TODO: Verify ... Most likely used for registration errors where result is not in format for outpace error
        for (let [key, values] of Object.entries(res.data)) {
          if (typeof values === 'string') {
            error_messages.push(values)
          } else {
            // @ts-ignore
            for (const [index, val] of Object.entries(values)) {
              error_messages.push(val)
            }
          }
        }
      }
    }
  } else {
    error_messages.push(error.message)
  }
  return error_messages
}

export function get_error_message_if_present(result: any) {
  // Check if result object has content representing error
  // TODO: Ideally all server requests should return non 200 HTTP error code so
  //       that it is clear that an error has occured
  // {"success": false, "error_code": 404, "error_message": "Page not found."}
  let error_messages = null
  if (result && typeof result === 'object' && 'error_message' in result) {
    error_messages = []
    error_messages.push(result['error_message']);
  }
  return error_messages
}

export function render_server_errors(error: Error | any) {
  let error_messages = get_server_errors(error)
  return (
    <ul>
      {error_messages.map((value, index) => {
        return <li key={index}>{value}</li>
      })}
    </ul>
  )
}
