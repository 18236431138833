/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import { Form, Card, Row, Col, Button, Table } from 'react-bootstrap'
import {const_test_numeric} from "../../partials/charts/plotly/plotdata";
import { PlotlyWrapper } from 'src/outpace/partials/charts/plotly/PlotlyWrapper'
import {template_plotly_dark, template_plotly_white} from "../../partials/charts/plotly/plotly_template";
import Plot from "react-plotly.js";


export function ProtoPlotly() {
  const [plotlyData, setPlotlyData] = useState({});
  const query = "select query"
  const dispatch = useDispatch()

  useEffect(() => {
    let plot_data = const_test_numeric
    // plot_data.layout.template = template_plotly_dark
    // plot_data["data"] = [
    //     {
    //       x: [1, 2, 3, 4],
    //       y: [10, 15, 13, 17],
    //       type: 'scatter'
    //     }
    // ]

    // plot_data["layout"] = {
    //     width: 320, height: 240,
    //     margin: {
    //       t: 10,  // set top margin to 0
    //       r: 20,  // right margin
    //       b: 30,  // bottom margin
    //       l: 40   // left margin
    //     }
    // }

    // plot_data["layout"] = {}
    plot_data.layout.width = 400
    plot_data.layout.height = 400
    // Margin is important to remove extra white space from around graph
    plot_data.layout.margin = {
          t: 10,  // set top margin to 0
          r: 10,  // right margin
          b: 10,  // bottom margin
          l: 10   // left margin
        }
    plot_data.layout.template = template_plotly_white

    setPlotlyData(plot_data);
  }, [])

  return (
      <>
        <div className='card-body pt-9 pb-0'>
            <Plot
              data={plotlyData.data}
              // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
              layout={plotlyData.layout}
              config={{displayModeBar: false, responsive: true, height: 200}}
            />
        </div>
        <div className='card-body pt-9 pb-0'>
            <Plot
              data={plotlyData.data}
              layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
              divId={"plotly-chart"}
              // layout={plotlyData.layout}
              // config={{displayModeBar: false, responsive: true, height: 400}}
            />
        </div>
      </>
  )
}
