/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {requestAccess} from '../core/_requests'
import {Link} from 'react-router-dom'
import {render_server_errors} from '../../errors/error_helper'

const initialValues = {
  fullname: '',
  email: '',
}

const requestSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 50 symbols')
    .required('Fullname is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function RequestAccess() {
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(true)
  const [reqMessage, setReqMessage] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: requestSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: data} = await requestAccess(values.email, values.fullname)
        setReqMessage(data.data.message)
        setShowForm(!data.success)
        setLoading(false)
      } catch (error) {
        let status_message = render_server_errors(error)
        setStatus(status_message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {!showForm && (
        <div className='mb-10 text-center'>
          {/* Show server message */}
          <h3 className='text-dark mb-3'>{reqMessage}</h3>
        </div>
      )}
      {showForm && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_request_access_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='mb-10 text-center'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Request Access</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
                Already have an account?
              </Link>
              <Link
                to='/auth/forgot-password'
                className='link-primary fw-bolder'
                style={{marginLeft: '5px'}}
              >
                Forgot Password ?
              </Link>
            </div>
            {/* end::Link */}
          </div>
          {/* end::Heading */}

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Error requesting access.
                {formik.status}
              </div>
            </div>
          )}

          {/* begin::Form group Fullname */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Full name</label>
            <input
              placeholder='Full name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('fullname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.fullname && formik.errors.fullname,
                },
                {
                  'is-valid': formik.touched.fullname && !formik.errors.fullname,
                }
              )}
            />
            {formik.touched.fullname && formik.errors.fullname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fullname}</span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_request_access_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Request</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>
  )
}
