export const plot_api_feature_drift_response = {
  "success": true,
  "data": {
    "features.loan_amnt": {
      "drift": {
        "Method": "Earth Mover's Distance",
        "Importance": null,
        "score": 0.005466600170825097
      },
      "plotly": {
        "data": [
          {
            "fill": "tozeroy",
            "line": {
              "color": "#00008b",
              "shape": "spline"
            },
            "name": "Dataset1 Dataset",
            "x": [
              1000,
              1693.8775510204082,
              2175,
              2387.7551020408164,
              2400,
              3000,
              3081.6326530612246,
              3100,
              3775.5102040816328,
              3950,
              4000,
              4469.387755102041,
              4500,
              4800,
              5000,
              5000,
              5000,
              5163.265306122449,
              5400,
              5857.142857142857,
              5975,
              6000,
              6000,
              6000,
              6475,
              6551.0204081632655,
              6686.999999999989,
              7000,
              7025,
              7244.897959183674,
              7500,
              7625,
              7938.775510204082,
              8000,
              8000,
              8000,
              8200,
              8632.65306122449,
              8800,
              9000,
              9326.530612244898,
              9375,
              9500,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10020.408163265307,
              10200,
              10714.285714285714,
              11000,
              11000,
              11408.163265306122,
              11550,
              11600,
              12000,
              12000,
              12000,
              12000,
              12000,
              12102.040816326531,
              12109.000000000015,
              12795.91836734694,
              13000,
              13000,
              13000,
              13000,
              13489.795918367348,
              13800,
              14000,
              14183.673469387755,
              14400,
              14400,
              14768.82535379054,
              14858.950590238223,
              14877.551020408164,
              15000,
              15000,
              15000,
              15000,
              15000,
              15000,
              15571.428571428572,
              16000,
              16000,
              16000,
              16200,
              16265.30612244898,
              16959.183673469386,
              17000,
              17475,
              17653.061224489797,
              18000,
              18000,
              18346.938775510203,
              18375,
              19000,
              19040.816326530614,
              19734.69387755102,
              19825,
              20000,
              20000,
              20000,
              20000,
              20000,
              20428.571428571428,
              20700,
              21000,
              21122.448979591838,
              21600,
              21816.326530612245,
              22000,
              22510.204081632655,
              23204.081632653062,
              23325,
              23897.95918367347,
              24000,
              24000,
              24000,
              24591.83673469388,
              25000,
              25000,
              25200,
              25285.714285714286,
              25979.591836734697,
              26200,
              26673.469387755104,
              27367.34693877551,
              28000,
              28000,
              28061.22448979592,
              28755.102040816328,
              29448.979591836734,
              29975,
              30000,
              30142.857142857145,
              30746.000000000095,
              30836.73469387755,
              31530.61224489796,
              31950,
              32224.48979591837,
              32918.36734693877,
              33612.244897959186,
              34306.12244897959,
              35000,
              35000,
              35000,
              35000,
              35000
            ],
            "y": [
              0.000012117300771121456,
              0.000016372203145406023,
              0.000019586991007797337,
              0.00002105231266458528,
              0.00002113719660804773,
              0.000025325724886721604,
              0.000025894312470139505,
              0.000026022011713794623,
              0.000030606574702973393,
              0.00003173768161803051,
              0.00003205648614282009,
              0.000034915602686325576,
              0.00003509286989648625,
              0.000036764352766175763,
              0.0000378095002336885,
              0.0000378095002336885,
              0.0000378095002336885,
              0.00003862016798422504,
              0.000039726862584556685,
              0.000041636045012493006,
              0.000042081222498491676,
              0.00004217327315903527,
              0.00004217327315903527,
              0.00004217327315903527,
              0.000043772764902266906,
              0.00004400398940334302,
              0.000044401919917134097,
              0.000045246571356779075,
              0.00004531000903396155,
              0.00004584409956877341,
              0.00004641278623193501,
              0.00004667253230067149,
              0.00004727160037656429,
              0.00004737970636240666,
              0.00004737970636240666,
              0.00004737970636240666,
              0.00004771254231568662,
              0.000048320473361826405,
              0.00004851100176968377,
              0.00004870281357868058,
              0.000048924700254985583,
              0.000048947360228541946,
              0.00004899293840163627,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048980993419571263,
              0.000048973700027915965,
              0.00004888620439634145,
              0.000048408865348587784,
              0.000048008614806661126,
              0.000048008614806661126,
              0.000047296604973422164,
              0.000047017403707935416,
              0.00004691573837510662,
              0.00004605430724527573,
              0.00004605430724527573,
              0.00004605430724527573,
              0.00004605430724527573,
              0.00004605430724527573,
              0.00004582417147129064,
              0.00004580836586236461,
              0.00004421439105683365,
              0.00004373951415487152,
              0.00004373951415487152,
              0.00004373951415487152,
              0.00004373951415487152,
              0.000042613646281331084,
              0.000041908364111939356,
              0.00004145333259299237,
              0.000041032788137775545,
              0.000040531440873461465,
              0.000040531440873461465,
              0.00003965360749772608,
              0.000039433638256149694,
              0.00003938794881983378,
              0.00003908463520892015,
              0.00003908463520892015,
              0.00003908463520892015,
              0.00003908463520892015,
              0.00003908463520892015,
              0.00003908463520892015,
              0.0000376134599452576,
              0.000036468353721148655,
              0.000036468353721148655,
              0.000036468353721148655,
              0.00003593218187952753,
              0.000035757956284819455,
              0.00003398172208417023,
              0.00003388365587738296,
              0.000032814438728984296,
              0.00003245005205264042,
              0.00003179573947075192,
              0.00003179573947075192,
              0.000031203777143082674,
              0.000031158009148949314,
              0.000030175797096034436,
              0.000030111856469519912,
              0.000028950140813444474,
              0.000028783444162003037,
              0.000028447634512540505,
              0.000028447634512540505,
              0.000028447634512540505,
              0.000028447634512540505,
              0.000028447634512540505,
              0.00002755215729490398,
              0.000026935324335592853,
              0.0000262192856218332,
              0.00002591990647399676,
              0.00002474002686128211,
              0.000024212160806358082,
              0.000023773445412610448,
              0.000022622523486733428,
              0.000021243853643733913,
              0.000021022985785205682,
              0.0000200213045862548,
              0.000019846612712753295,
              0.000019846612712753295,
              0.000019846612712753295,
              0.000018820566163724294,
              0.00001808166133464372,
              0.00001808166133464372,
              0.000017708444829736482,
              0.000017546541898571308,
              0.00001621600503948505,
              0.000015797760274375375,
              0.000014935410120504633,
              0.00001381419332460796,
              0.000012962803271673938,
              0.000012962803271673938,
              0.000012888130282376702,
              0.000012109206820600395,
              0.00001140304182536289,
              0.000010899016310703685,
              0.00001087604789543975,
              0.000010747240322619773,
              0.000010270640299353049,
              0.000010211987289338638,
              0.000009928262027654283,
              0.000009924544812464296,
              0.000009993551903197608,
              0.000010367964510811397,
              0.00001083083252659079,
              0.00001104167792333366,
              0.00001068656592735198,
              0.00001068656592735198,
              0.00001068656592735198,
              0.00001068656592735198,
              0.00001068656592735198
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset1 Mean",
            "x": [
              14858.950590238223,
              14858.950590238223
            ],
            "y": [
              0,
              0.000039433638256149694
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b"
            },
            "mode": "lines",
            "name": "Dataset1 Median",
            "x": [
              13000,
              13000
            ],
            "y": [
              0,
              0.00004373951415487152
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "fill": "tozeroy",
            "line": {
              "color": "#69b3a2",
              "shape": "spline"
            },
            "name": "Dataset2 Dataset",
            "x": [
              1000,
              1693.8775510204082,
              2175,
              2387.7551020408164,
              2400,
              3000,
              3081.6326530612246,
              3100,
              3775.5102040816328,
              3950,
              4000,
              4469.387755102041,
              4500,
              4800,
              5000,
              5000,
              5000,
              5163.265306122449,
              5400,
              5857.142857142857,
              5975,
              6000,
              6000,
              6000,
              6475,
              6551.0204081632655,
              6686.999999999989,
              7000,
              7025,
              7244.897959183674,
              7500,
              7625,
              7938.775510204082,
              8000,
              8000,
              8000,
              8200,
              8632.65306122449,
              8800,
              9000,
              9326.530612244898,
              9375,
              9500,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10020.408163265307,
              10200,
              10714.285714285714,
              11000,
              11000,
              11408.163265306122,
              11550,
              11600,
              12000,
              12000,
              12000,
              12000,
              12000,
              12102.040816326531,
              12109.000000000015,
              12795.91836734694,
              13000,
              13000,
              13000,
              13000,
              13489.795918367348,
              13800,
              14000,
              14183.673469387755,
              14400,
              14400,
              14768.82535379054,
              14858.950590238223,
              14877.551020408164,
              15000,
              15000,
              15000,
              15000,
              15000,
              15000,
              15571.428571428572,
              16000,
              16000,
              16000,
              16200,
              16265.30612244898,
              16959.183673469386,
              17000,
              17475,
              17653.061224489797,
              18000,
              18000,
              18346.938775510203,
              18375,
              19000,
              19040.816326530614,
              19734.69387755102,
              19825,
              20000,
              20000,
              20000,
              20000,
              20000,
              20428.571428571428,
              20700,
              21000,
              21122.448979591838,
              21600,
              21816.326530612245,
              22000,
              22510.204081632655,
              23204.081632653062,
              23325,
              23897.95918367347,
              24000,
              24000,
              24000,
              24591.83673469388,
              25000,
              25000,
              25200,
              25285.714285714286,
              25979.591836734697,
              26200,
              26673.469387755104,
              27367.34693877551,
              28000,
              28000,
              28061.22448979592,
              28755.102040816328,
              29448.979591836734,
              29975,
              30000,
              30142.857142857145,
              30746.000000000095,
              30836.73469387755,
              31530.61224489796,
              31950,
              32224.48979591837,
              32918.36734693877,
              33612.244897959186,
              34306.12244897959,
              35000,
              35000,
              35000,
              35000,
              35000
            ],
            "y": [
              0.000011142152279004786,
              0.00001531888108164506,
              0.000018530065962540687,
              0.000020008262486769762,
              0.000020094161178447722,
              0.00002436827333874623,
              0.000024953752915623843,
              0.000025085417440986042,
              0.00002985421098090804,
              0.00003104356927399474,
              0.00003137974446496504,
              0.000034414394804647036,
              0.00003460377954433857,
              0.000036397331116182925,
              0.000037526572552060924,
              0.000037526572552060924,
              0.000037526572552060924,
              0.0000384070707428351,
              0.000039616373072802064,
              0.00004172618852847478,
              0.00004222330662872797,
              0.000042326376875222186,
              0.000042326376875222186,
              0.000042326376875222186,
              0.00004413492506355462,
              0.00004439949460429275,
              0.00004485695338875217,
              0.000045837920886479744,
              0.00004591219566823152,
              0.00004654120883913558,
              0.000047218909516579015,
              0.000047531517860558346,
              0.000048261128011173335,
              0.00004839426583116237,
              0.00004839426583116237,
              0.00004839426583116237,
              0.000048807622870777324,
              0.00004958130393286002,
              0.00004983195148716884,
              0.00005009197730139055,
              0.00005041522252111868,
              0.000050451739798989296,
              0.00005053150906428481,
              0.000050631856007518215,
              0.000050631856007518215,
              0.000050631856007518215,
              0.000050631856007518215,
              0.000050631856007518215,
              0.000050631856007518215,
              0.000050631856007518215,
              0.0000506282526817023,
              0.000050570034441347735,
              0.000050143959735028636,
              0.000049751346562221085,
              0.000049751346562221085,
              0.00004902604615252257,
              0.00004873613564175021,
              0.000048630009389920685,
              0.00004772136639895248,
              0.00004772136639895248,
              0.00004772136639895248,
              0.00004772136639895248,
              0.00004772136639895248,
              0.00004747627986505546,
              0.00004745941672503911,
              0.00004574200669556969,
              0.00004522497060522695,
              0.00004522497060522695,
              0.00004522497060522695,
              0.00004522497060522695,
              0.00004399125838449598,
              0.00004321360488905116,
              0.00004271028116928479,
              0.0000422441899703901,
              0.000041687632031487906,
              0.000041687632031487906,
              0.000040711550302313684,
              0.000040466785791413,
              0.000040415942025957984,
              0.000040078393874829445,
              0.000040078393874829445,
              0.000040078393874829445,
              0.000040078393874829445,
              0.000040078393874829445,
              0.000040078393874829445,
              0.000038442126879044,
              0.00003717193884150465,
              0.00003717193884150465,
              0.00003717193884150465,
              0.00003657897342852539,
              0.00003638660273571085,
              0.00003443724407463563,
              0.00003433042516040683,
              0.00003317356957584822,
              0.000032783238643718855,
              0.00003208851164287852,
              0.00003208851164287852,
              0.000031467232523415184,
              0.00003141946361960978,
              0.000030399191333431247,
              0.000030332810156090543,
              0.00002911734503934101,
              0.000028941116115840785,
              0.000028584756737638013,
              0.000028584756737638013,
              0.000028584756737638013,
              0.000028584756737638013,
              0.000028584756737638013,
              0.000027627159682186675,
              0.000026963078701848528,
              0.000026189545722194413,
              0.000025865668670367778,
              0.000024589260140632517,
              0.000024019449371642055,
              0.000023547115273055766,
              0.000022316665220761184,
              0.000020869639349411995,
              0.000020641458071275272,
              0.000019619497126355174,
              0.000019443103338909854,
              0.000019443103338909854,
              0.000019443103338909854,
              0.000018412972603497193,
              0.00001767303637237117,
              0.00001767303637237117,
              0.00001729892815932605,
              0.000017136503237629248,
              0.000015797908027715787,
              0.000015375622979650357,
              0.000014502211701332523,
              0.000013358033755911,
              0.000012476028963167948,
              0.000012476028963167948,
              0.000012397791283649625,
              0.000011570738167067476,
              0.000010805544709712527,
              0.000010255818201259353,
              0.000010230782481030786,
              0.000010090479446404666,
              0.000009574127840990874,
              0.000009511131716614162,
              0.000009213143184548702,
              0.000009219152087938244,
              0.00000930224651517569,
              0.000009736132075513217,
              0.00001028020213677643,
              0.000010573248555484158,
              0.000010283517656544574,
              0.000010283517656544574,
              0.000010283517656544574,
              0.000010283517656544574,
              0.000010283517656544574
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset2 Mean",
            "x": [
              14768.82535379054,
              14768.82535379054
            ],
            "y": [
              0,
              0.000040711550302313684
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2"
            },
            "mode": "lines",
            "name": "Dataset2 Median",
            "x": [
              13000,
              13000
            ],
            "y": [
              0,
              0.00004522497060522695
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          }
        ],
        "layout": {
          "xaxis": {
            "anchor": "y",
            "domain": [
              0,
              1
            ],
            "showgrid": false,
            "gridcolor": "black",
            "linecolor": "black",
            "range": [
              0,
              0.4
            ],
            "dtick": 0.05,
            "fixedrange": true
          },
          "yaxis": {
            "anchor": "x",
            "domain": [
              0.9200000000000002,
              1
            ],
            "showgrid": false,
            "showline": false,
            "showticklabels": false,
            "zeroline": false,
            "color": "black",
            "autorange": true,
            "rangemode": "normal",
            "fixedrange": true
          },
          "xaxis2": {
            "anchor": "y2",
            "domain": [
              0,
              1
            ],
            "fixedrange": false,
            "range": [
              2175,
              35000
            ],
            "title": {
              "text": "features.loan_amnt"
            }
          },
          "yaxis2": {
            "anchor": "x2",
            "title": {
              "text": "Probability Density"
            },
            "fixedrange": true
          },
          "legend": {
            "title": {
              "text": "Legend"
            },
            "yanchor": "top",
            "y": 0.6
          },
          "height": 400,
          "bargroupgap": 0,
          "showlegend": false
        }
      }
    },
    "features.sub_grade": {
      "drift": {
        "Method": "Cramer's V",
        "Importance": null,
        "score": 0.01680197619334343
      },
      "plotly": {
        "data": [
          {
            "marker": {
              "color": "#00008b"
            },
            "name": "Dataset1 Dataset",
            "x": [
              "A1",
              "A4",
              "A5",
              "B1",
              "B2",
              "B3",
              "B5",
              "C2",
              "D3",
              "D5"
            ],
            "y": [
              0.02279583891441233,
              0.030056457569194946,
              0.04418962732997008,
              0.042149143121815655,
              0.046392849542455844,
              0.05201356984589963,
              0.05500544546399109,
              0.0603006897587722,
              0.03123317852359075,
              0.027690497352377854
            ],
            "type": "bar",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "marker": {
              "color": "#69b3a2"
            },
            "name": "Dataset2 Dataset",
            "x": [
              "A1",
              "A4",
              "A5",
              "B1",
              "B2",
              "B3",
              "B5",
              "C2",
              "D3",
              "D5"
            ],
            "y": [
              0.019286462505391583,
              0.032472733994700846,
              0.0467681311232978,
              0.04031877092447676,
              0.043769384024482924,
              0.04861667385544396,
              0.05292994023045166,
              0.06202887834534886,
              0.03280136381374905,
              0.029761537987553145
            ],
            "type": "bar",
            "xaxis": "x2",
            "yaxis": "y2"
          }
        ],
        "layout": {
          "xaxis": {
            "anchor": "y",
            "domain": [
              0,
              1
            ],
            "showgrid": false,
            "gridcolor": "black",
            "linecolor": "black",
            "range": [
              0,
              0.4
            ],
            "dtick": 0.05,
            "fixedrange": true
          },
          "yaxis": {
            "anchor": "x",
            "domain": [
              0.9200000000000002,
              1
            ],
            "showgrid": false,
            "showline": false,
            "showticklabels": false,
            "zeroline": false,
            "color": "black",
            "autorange": true,
            "rangemode": "normal",
            "fixedrange": true
          },
          "xaxis2": {
            "anchor": "y2",
            "domain": [
              0,
              1
            ],
            "type": "category",
            "range": [
              -3,
              12
            ]
          },
          "yaxis2": {
            "anchor": "x2",
            "fixedrange": true,
            "autorange": true,
            "rangemode": "normal",
            "title": {
              "text": "Frequency"
            }
          },
          "legend": {
            "title": {
              "text": "Legend"
            },
            "yanchor": "top",
            "y": 0.6
          },
          "height": 400,
          "bargroupgap": 0,
          "showlegend": false
        }
      }
    },
    "features.int_rate": {
      "drift": {
        "Method": "Earth Mover's Distance",
        "Importance": null,
        "score": 0.004907807780869135
      },
      "plotly": {
        "data": [
          {
            "fill": "tozeroy",
            "line": {
              "color": "#00008b",
              "shape": "spline"
            },
            "name": "Dataset1 Dataset",
            "x": [
              5.32,
              5.803061224489796,
              5.93,
              6.24,
              6.286122448979592,
              6.39,
              6.39,
              6.769183673469388,
              6.89,
              6.89,
              6.92,
              7.252244897959184,
              7.26,
              7.73530612244898,
              7.89,
              7.89,
              7.89,
              7.89,
              7.89,
              7.91,
              8.18,
              8.18,
              8.18,
              8.218367346938775,
              8.39,
              8.701428571428572,
              9.17,
              9.17,
              9.17,
              9.17,
              9.184489795918367,
              9.667551020408164,
              9.8,
              9.99,
              9.99,
              9.99,
              9.99,
              10.15061224489796,
              10.633673469387755,
              10.99,
              10.99,
              10.99,
              10.99,
              10.99,
              11.11673469387755,
              11.47,
              11.53,
              11.53,
              11.53,
              11.53,
              11.53,
              11.599795918367347,
              11.99,
              12.082857142857144,
              12.29,
              12.29,
              12.29,
              12.29,
              12.29,
              12.565918367346939,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              13.048979591836734,
              13.33,
              13.33,
              13.33,
              13.33,
              13.33,
              13.33,
              13.357043050461298,
              13.427965165239181,
              13.53204081632653,
              13.99,
              13.99,
              13.99,
              13.99,
              13.99,
              14.015102040816327,
              14.498163265306122,
              14.65,
              14.65,
              14.65,
              14.65,
              14.65,
              14.981224489795919,
              15.41,
              15.464285714285714,
              15.61,
              15.61,
              15.61,
              15.77,
              15.94734693877551,
              16.430408163265305,
              16.55,
              16.55,
              16.55,
              16.9134693877551,
              16.99,
              16.99,
              16.99,
              16.99,
              17.396530612244895,
              17.57,
              17.57,
              17.86,
              17.86,
              17.86,
              17.86,
              17.879591836734694,
              18.25,
              18.25,
              18.36265306122449,
              18.55,
              18.55,
              18.845714285714287,
              19.19,
              19.19,
              19.328775510204082,
              19.811836734693877,
              19.99,
              19.99,
              20.294897959183672,
              20.777959183673467,
              20.99,
              20.99,
              21.261020408163265,
              21.74408163265306,
              22.227142857142855,
              22.45,
              22.710204081632654,
              22.99,
              23.19326530612245,
              23.676326530612243,
              24.15938775510204,
              24.5,
              24.5,
              24.642448979591837,
              25.125510204081632,
              25.608571428571427,
              26.09163265306122,
              26.57469387755102,
              27.057755102040815,
              27.54081632653061,
              28.023877551020405,
              28.506938775510203,
              28.99
            ],
            "y": [
              0.021188657080167102,
              0.029492882601071708,
              0.03179518566138092,
              0.037484331103413024,
              0.038328194565429824,
              0.04021591172160741,
              0.04021591172160741,
              0.04680442024337948,
              0.048745988905065644,
              0.048745988905065644,
              0.049212535645557984,
              0.05388356284611493,
              0.053980513054088394,
              0.058715851718897025,
              0.05973145911622165,
              0.05973145911622165,
              0.05973145911622165,
              0.05973145911622165,
              0.05973145911622165,
              0.05984451256678091,
              0.060994311526397386,
              0.060994311526397386,
              0.060994311526397386,
              0.061105751417479696,
              0.06147321737928992,
              0.061745415906457005,
              0.061944746453954605,
              0.061944746453954605,
              0.061944746453954605,
              0.061944746453954605,
              0.061958854442206564,
              0.06312421392631136,
              0.06374735281982707,
              0.0649117221434888,
              0.0649117221434888,
              0.0649117221434888,
              0.0649117221434888,
              0.06615169668214477,
              0.07119893727841096,
              0.07586332050182991,
              0.07586332050182991,
              0.07586332050182991,
              0.07586332050182991,
              0.07586332050182991,
              0.07761545428855625,
              0.08245150284923251,
              0.08323502947602283,
              0.08323502947602283,
              0.08323502947602283,
              0.08323502947602283,
              0.08323502947602283,
              0.0841225405931778,
              0.08840573172246369,
              0.08920530340607986,
              0.09060992897692548,
              0.09060992897692548,
              0.09060992897692548,
              0.09060992897692548,
              0.09060992897692548,
              0.09156913526362236,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09163374965754591,
              0.09049625659208368,
              0.08827585051064699,
              0.08827585051064699,
              0.08827585051064699,
              0.08827585051064699,
              0.08827585051064699,
              0.08827585051064699,
              0.08800505336367892,
              0.08725051386643654,
              0.08603256925454035,
              0.07941069357880264,
              0.07941069357880264,
              0.07941069357880264,
              0.07941069357880264,
              0.07941069357880264,
              0.0790035145724596,
              0.07086245619986502,
              0.06835247590873877,
              0.06835247590873877,
              0.06835247590873877,
              0.06835247590873877,
              0.06835247590873877,
              0.06335368058471053,
              0.05846262918619414,
              0.05800145640879349,
              0.0569519634829951,
              0.0569519634829951,
              0.0569519634829951,
              0.056115597883039735,
              0.05556037647208276,
              0.05567492626658178,
              0.055954196062523845,
              0.055954196062523845,
              0.055954196062523845,
              0.05696114968843027,
              0.05714867427900186,
              0.05714867427900186,
              0.05714867427900186,
              0.05714867427900186,
              0.057545244660049524,
              0.05725781555262877,
              0.05725781555262877,
              0.05598746895169712,
              0.05598746895169712,
              0.05598746895169712,
              0.05598746895169712,
              0.05586387479465029,
              0.052638449050853374,
              0.052638449050853374,
              0.051345902193089,
              0.04892714561088855,
              0.04892714561088855,
              0.04459218891886208,
              0.03914948123801598,
              0.03914948123801598,
              0.03694944102782719,
              0.029783025257923118,
              0.02744801365478054,
              0.02744801365478054,
              0.0239269341498377,
              0.019567222963653777,
              0.018071008873936,
              0.018071008873936,
              0.016458888062434705,
              0.014219756073477195,
              0.012525946658387526,
              0.011868409186776443,
              0.011176594804772367,
              0.010511408321133566,
              0.01007418212538554,
              0.009173932595896757,
              0.008434532592807234,
              0.007974202083267093,
              0.007974202083267093,
              0.00778788882290116,
              0.00714146601655312,
              0.006414726620555567,
              0.005584449670307301,
              0.004697458443142818,
              0.0038341110404593657,
              0.003054416196589331,
              0.002373685928086046,
              0.0017792818577320822,
              0.001261131713853737
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset1 Mean",
            "x": [
              13.357043050461298,
              13.357043050461298
            ],
            "y": [
              0,
              0.08800505336367892
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b"
            },
            "mode": "lines",
            "name": "Dataset1 Median",
            "x": [
              12.69,
              12.69
            ],
            "y": [
              0,
              0.09163374965754591
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "fill": "tozeroy",
            "line": {
              "color": "#69b3a2",
              "shape": "spline"
            },
            "name": "Dataset2 Dataset",
            "x": [
              5.32,
              5.803061224489796,
              5.93,
              6.24,
              6.286122448979592,
              6.39,
              6.39,
              6.769183673469388,
              6.89,
              6.89,
              6.92,
              7.252244897959184,
              7.26,
              7.73530612244898,
              7.89,
              7.89,
              7.89,
              7.89,
              7.89,
              7.91,
              8.18,
              8.18,
              8.18,
              8.218367346938775,
              8.39,
              8.701428571428572,
              9.17,
              9.17,
              9.17,
              9.17,
              9.184489795918367,
              9.667551020408164,
              9.8,
              9.99,
              9.99,
              9.99,
              9.99,
              10.15061224489796,
              10.633673469387755,
              10.99,
              10.99,
              10.99,
              10.99,
              10.99,
              11.11673469387755,
              11.47,
              11.53,
              11.53,
              11.53,
              11.53,
              11.53,
              11.599795918367347,
              11.99,
              12.082857142857144,
              12.29,
              12.29,
              12.29,
              12.29,
              12.29,
              12.565918367346939,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              12.69,
              13.048979591836734,
              13.33,
              13.33,
              13.33,
              13.33,
              13.33,
              13.33,
              13.357043050461298,
              13.427965165239181,
              13.53204081632653,
              13.99,
              13.99,
              13.99,
              13.99,
              13.99,
              14.015102040816327,
              14.498163265306122,
              14.65,
              14.65,
              14.65,
              14.65,
              14.65,
              14.981224489795919,
              15.41,
              15.464285714285714,
              15.61,
              15.61,
              15.61,
              15.77,
              15.94734693877551,
              16.430408163265305,
              16.55,
              16.55,
              16.55,
              16.9134693877551,
              16.99,
              16.99,
              16.99,
              16.99,
              17.396530612244895,
              17.57,
              17.57,
              17.86,
              17.86,
              17.86,
              17.86,
              17.879591836734694,
              18.25,
              18.25,
              18.36265306122449,
              18.55,
              18.55,
              18.845714285714287,
              19.19,
              19.19,
              19.328775510204082,
              19.811836734693877,
              19.99,
              19.99,
              20.294897959183672,
              20.777959183673467,
              20.99,
              20.99,
              21.261020408163265,
              21.74408163265306,
              22.227142857142855,
              22.45,
              22.710204081632654,
              22.99,
              23.19326530612245,
              23.676326530612243,
              24.15938775510204,
              24.5,
              24.5,
              24.642448979591837,
              25.125510204081632,
              25.608571428571427,
              26.09163265306122,
              26.57469387755102,
              27.057755102040815,
              27.54081632653061,
              28.023877551020405,
              28.506938775510203,
              28.99
            ],
            "y": [
              0.02073214261353887,
              0.02979921230665281,
              0.032315904549442644,
              0.03847563092085582,
              0.039378099560599095,
              0.041382835931674744,
              0.041382835931674744,
              0.04817870964101878,
              0.05010208930063725,
              0.05010208930063725,
              0.05055736401406772,
              0.05492423027704741,
              0.05501026027424274,
              0.05879374054844459,
              0.059405349339471565,
              0.059405349339471565,
              0.059405349339471565,
              0.059405349339471565,
              0.059405349339471565,
              0.0594639015328762,
              0.059846435128378055,
              0.059846435128378055,
              0.059846435128378055,
              0.05984630740346294,
              0.05971687625802165,
              0.05913953830663915,
              0.058348223517972954,
              0.058348223517972954,
              0.058348223517972954,
              0.058348223517972954,
              0.05833913889470452,
              0.05902503341874124,
              0.05961706354744619,
              0.06080862826359193,
              0.06080862826359193,
              0.06080862826359193,
              0.06080862826359193,
              0.06213369495081791,
              0.06773467551646817,
              0.07303664126068302,
              0.07303664126068302,
              0.07303664126068302,
              0.07303664126068302,
              0.07303664126068302,
              0.07504975912241407,
              0.08066997736403811,
              0.0815907232475536,
              0.0815907232475536,
              0.0815907232475536,
              0.0815907232475536,
              0.0815907232475536,
              0.08263780590685209,
              0.08777782091962329,
              0.08876368929055509,
              0.09054645369082093,
              0.09054645369082093,
              0.09054645369082093,
              0.09054645369082093,
              0.09054645369082093,
              0.09191441612767483,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09212507467330444,
              0.09128805382530193,
              0.08920473651349235,
              0.08920473651349235,
              0.08920473651349235,
              0.08920473651349235,
              0.08920473651349235,
              0.08920473651349235,
              0.0889440519502412,
              0.08821419702462623,
              0.08702863334881543,
              0.08053078952265617,
              0.08053078952265617,
              0.08053078952265617,
              0.08053078952265617,
              0.08053078952265617,
              0.0801307334428004,
              0.07215394037904267,
              0.06970834740635723,
              0.06970834740635723,
              0.06970834740635723,
              0.06970834740635723,
              0.06970834740635723,
              0.06487496237018978,
              0.06026091057046454,
              0.059840825278072406,
              0.05890876157215343,
              0.05890876157215343,
              0.05890876157215343,
              0.05821301210559923,
              0.05782616692888628,
              0.05842595862682891,
              0.058817733865368205,
              0.058817733865368205,
              0.058817733865368205,
              0.060093772066429914,
              0.0603175512193064,
              0.0603175512193064,
              0.0603175512193064,
              0.0603175512193064,
              0.060739493559561264,
              0.060365473528237505,
              0.060365473528237505,
              0.05881683750814302,
              0.05881683750814302,
              0.05881683750814302,
              0.05881683750814302,
              0.05866877968713613,
              0.05487141288638834,
              0.05487141288638834,
              0.05337346826925002,
              0.05059600606384,
              0.05059600606384,
              0.04568730668760727,
              0.0396324709231411,
              0.0396324709231411,
              0.037217085629209445,
              0.029477937269444577,
              0.02700117497475736,
              0.02700117497475736,
              0.023313275704100158,
              0.01884225926772803,
              0.01734104731732009,
              0.01734104731732009,
              0.015752443943294617,
              0.01362690268639477,
              0.012131346401182052,
              0.011591305006089063,
              0.011053524986456604,
              0.010567784931756438,
              0.010264691254107894,
              0.009669836316703302,
              0.009173551807485702,
              0.008821846546229719,
              0.008821846546229719,
              0.00866176061355705,
              0.008007688585937214,
              0.00711692634971311,
              0.00599150530237463,
              0.004750584854727983,
              0.0035697114201474144,
              0.0025828406482508955,
              0.0018290976879786135,
              0.0012731810390227939,
              0.0008591807255261163
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset2 Mean",
            "x": [
              13.427965165239181,
              13.427965165239181
            ],
            "y": [
              0,
              0.08821419702462623
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2"
            },
            "mode": "lines",
            "name": "Dataset2 Median",
            "x": [
              12.69,
              12.69
            ],
            "y": [
              0,
              0.09212507467330444
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          }
        ],
        "layout": {
          "xaxis": {
            "anchor": "y",
            "domain": [
              0,
              1
            ],
            "showgrid": false,
            "gridcolor": "black",
            "linecolor": "black",
            "range": [
              0,
              0.4
            ],
            "dtick": 0.05,
            "fixedrange": true
          },
          "yaxis": {
            "anchor": "x",
            "domain": [
              0.9200000000000002,
              1
            ],
            "showgrid": false,
            "showline": false,
            "showticklabels": false,
            "zeroline": false,
            "color": "black",
            "autorange": true,
            "rangemode": "normal",
            "fixedrange": true
          },
          "xaxis2": {
            "anchor": "y2",
            "domain": [
              0,
              1
            ],
            "fixedrange": false,
            "range": [
              5.93,
              24.5
            ],
            "title": {
              "text": "features.int_rate"
            }
          },
          "yaxis2": {
            "anchor": "x2",
            "title": {
              "text": "Probability Density"
            },
            "fixedrange": true
          },
          "legend": {
            "title": {
              "text": "Legend"
            },
            "yanchor": "top",
            "y": 0.6
          },
          "height": 400,
          "bargroupgap": 0,
          "showlegend": false
        }
      }
    },
    "features.installment": {
      "drift": {
        "Method": "Earth Mover's Distance",
        "Importance": null,
        "score": 0.0048621954292175935
      },
      "plotly": {
        "data": [
          {
            "fill": "tozeroy",
            "line": {
              "color": "#00008b",
              "shape": "spline"
            },
            "name": "Dataset1 Dataset",
            "x": [
              30.54,
              58.61224489795919,
              73.33,
              80.66,
              86.68448979591837,
              102.1756,
              107.35,
              114.75673469387755,
              127.52,
              133.42,
              142.82897959183674,
              150.82,
              155.274,
              163.67,
              166.77,
              170.9012244897959,
              173.2,
              179.69,
              188.52,
              193.58,
              198.9734693877551,
              200.49840000000006,
              203.97,
              213.89,
              218.58,
              227.04571428571427,
              229.14,
              232.64,
              241.12,
              245.51,
              251.59,
              255.04,
              255.11795918367346,
              261.88,
              263.93,
              270.83,
              273.32,
              280.17,
              282.78,
              283.19020408163266,
              292.18,
              294.61,
              303.55,
              305.0472000000001,
              311.26244897959185,
              312.62,
              312.86,
              319.09,
              320.79040000000003,
              329.59600000000006,
              329.91,
              337.25320000000005,
              337.63600000000014,
              339.33469387755105,
              346.69,
              347.21,
              360.14,
              360.98,
              367.40693877551024,
              371.96,
              372.21,
              382.55,
              382.55,
              382.55,
              382.55,
              393.65160000000003,
              395.31,
              395.47918367346944,
              405.08,
              406.24,
              420.47,
              423.5514285714286,
              423.98,
              438.0528000000003,
              440.58831022654925,
              442.195091321057,
              442.77,
              451.62367346938777,
              456.28799999999995,
              459.54,
              469.29,
              472.14,
              479.69591836734696,
              483.94,
              488.5,
              496.95,
              500.3,
              507.76816326530616,
              509.30960000000005,
              512.8884000000002,
              526.01,
              531.86,
              535.8404081632654,
              544.1884000000001,
              551.6548000000009,
              563.9126530612244,
              566.27,
              573.83,
              590.5696,
              591.9848979591836,
              600.22,
              615.5532000000001,
              620.0571428571428,
              622.45,
              637.58,
              645.0860000000001,
              648.129387755102,
              661.1860000000003,
              667.8472000000002,
              676.2016326530612,
              684.44,
              692.8,
              704.2738775510204,
              722.9368,
              732.3461224489796,
              734.8504000000001,
              760.4183673469388,
              763.28,
              770.68,
              788.4906122448979,
              796.98,
              804.75,
              816.5628571428571,
              844.6351020408163,
              845.55,
              854.87,
              872.7073469387755,
              893.54,
              900.7795918367347,
              901.92,
              928.8518367346938,
              956.924081632653,
              979.02,
              984.9963265306122,
              987.7460000000001,
              1013.0685714285714,
              1041.1408163265307,
              1069.2130612244898,
              1097.285306122449,
              1115.77,
              1125.3575510204082,
              1129.19,
              1153.4297959183673,
              1181.5020408163266,
              1209.5742857142857,
              1237.6465306122448,
              1265.718775510204,
              1293.7910204081631,
              1321.8632653061225,
              1349.9355102040815,
              1378.0077551020408,
              1406.08
            ],
            "y": [
              0.0003247263157989278,
              0.0004919192496444524,
              0.0005911180519834219,
              0.0006427840718543626,
              0.0006861602865514673,
              0.0008005313202918948,
              0.0008393584773452033,
              0.0008952171588462045,
              0.0009915957914964084,
              0.0010359200586544412,
              0.0011059299585578367,
              0.0011644561674422507,
              0.0011966084343442603,
              0.0012561431898530705,
              0.0012777332636501797,
              0.0013061495039479467,
              0.001321777703091408,
              0.0013651538017603255,
              0.0014222900436186019,
              0.001453993875606468,
              0.0014869096922987032,
              0.0014960467684239117,
              0.0015165616004708432,
              0.001572897777517537,
              0.0015982909630654477,
              0.001641956376306269,
              0.0016523042635376306,
              0.0016691745549487977,
              0.0017077297738357347,
              0.0017263129914931508,
              0.0017503762557922046,
              0.001763116145744433,
              0.0017633960808312722,
              0.001786286061799102,
              0.0017926635149170618,
              0.001812095854544111,
              0.0018183082420755404,
              0.001833096196840186,
              0.001837812346122304,
              0.0018385063231291744,
              0.0018504086044737287,
              0.0018525164542502934,
              0.0018560971337129213,
              0.0018560487893520097,
              0.001853852445936898,
              0.0018529458057448879,
              0.001852769670189644,
              0.0018465440767053453,
              0.0018442976757160879,
              0.0018290284835008656,
              0.0018283744992995002,
              0.0018110429908196272,
              0.0018100357238435534,
              0.001805446986411503,
              0.0017834460833854912,
              0.0017817667509601811,
              0.0017354797998825433,
              0.0017322076001995495,
              0.0017063173247336013,
              0.001687201544046411,
              0.0016861365532840362,
              0.0016410783510303034,
              0.0016410783510303034,
              0.0016410783510303034,
              0.0016410783510303034,
              0.0015916756594598906,
              0.0015843082916757228,
              0.0015835576590271206,
              0.001541434134446327,
              0.0015364288424440013,
              0.0014771776311309933,
              0.0014649536881826207,
              0.0014632721890219453,
              0.0014106303788474805,
              0.0014016667842686916,
              0.001396064347433147,
              0.0013940741249388626,
              0.0013643138319703334,
              0.0013492334882929608,
              0.00133892291956463,
              0.0013087771152130985,
              0.0013001208259319989,
              0.0012773433471974806,
              0.0012645865667794056,
              0.0012508515628788536,
              0.0012251845847424884,
              0.0012148957559159593,
              0.0011916741082312649,
              0.0011868295715261414,
              0.0011755136598124267,
              0.001133293209984098,
              0.001114197763495151,
              0.0011011586154772372,
              0.0010738174237579297,
              0.0010495390258634944,
              0.0010105153142049756,
              0.0010031766298114048,
              0.0009800837263569786,
              0.0009316288476735076,
              0.000927706482455638,
              0.0009053960700761112,
              0.000865882994501554,
              0.0008546699085734421,
              0.0008487675243345893,
              0.0008120540960205916,
              0.0007940581215739956,
              0.0007867771730354615,
              0.0007555743055247188,
              0.0007396776623072759,
              0.0007198128255652252,
              0.0007003930595224246,
              0.0006809836840106272,
              0.0006550865651212184,
              0.0006155618962001098,
              0.0005970953504689637,
              0.0005923530361332035,
              0.0005477549705055964,
              0.0005431349833893723,
              0.0005314471860313593,
              0.0005043932585677188,
              0.000491776687184035,
              0.00048026171514115517,
              0.0004626754913166677,
              0.0004198592815415395,
              0.000418434290211239,
              0.0004038144057920244,
              0.00037538186048341986,
              0.000341763609487527,
              0.0003300926193686822,
              0.0003282589639354942,
              0.00028581003186651095,
              0.00024492409756729483,
              0.00021660641251374416,
              0.00020965620010795827,
              0.0002065670106545906,
              0.00018148399809032284,
              0.0001609429728837856,
              0.0001474791285863368,
              0.00013939853309844592,
              0.00013582853612117835,
              0.00013421472600289765,
              0.00013358157964567152,
              0.00012923866606487884,
              0.00012205938481678056,
              0.00011093167666638846,
              0.00009522857752263483,
              0.00007583118519929279,
              0.000055078741298046436,
              0.00003598567085687132,
              0.000020962055474763943,
              0.000010865340867800933,
              0.000005039854532546575
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset1 Mean",
            "x": [
              442.195091321057,
              442.195091321057
            ],
            "y": [
              0,
              0.001396064347433147
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#00008b"
            },
            "mode": "lines",
            "name": "Dataset1 Median",
            "x": [
              382.55,
              382.55
            ],
            "y": [
              0,
              0.0016410783510303034
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "fill": "tozeroy",
            "line": {
              "color": "#69b3a2",
              "shape": "spline"
            },
            "name": "Dataset2 Dataset",
            "x": [
              30.54,
              58.61224489795919,
              73.33,
              80.66,
              86.68448979591837,
              102.1756,
              107.35,
              114.75673469387755,
              127.52,
              133.42,
              142.82897959183674,
              150.82,
              155.274,
              163.67,
              166.77,
              170.9012244897959,
              173.2,
              179.69,
              188.52,
              193.58,
              198.9734693877551,
              200.49840000000006,
              203.97,
              213.89,
              218.58,
              227.04571428571427,
              229.14,
              232.64,
              241.12,
              245.51,
              251.59,
              255.04,
              255.11795918367346,
              261.88,
              263.93,
              270.83,
              273.32,
              280.17,
              282.78,
              283.19020408163266,
              292.18,
              294.61,
              303.55,
              305.0472000000001,
              311.26244897959185,
              312.62,
              312.86,
              319.09,
              320.79040000000003,
              329.59600000000006,
              329.91,
              337.25320000000005,
              337.63600000000014,
              339.33469387755105,
              346.69,
              347.21,
              360.14,
              360.98,
              367.40693877551024,
              371.96,
              372.21,
              382.55,
              382.55,
              382.55,
              382.55,
              393.65160000000003,
              395.31,
              395.47918367346944,
              405.08,
              406.24,
              420.47,
              423.5514285714286,
              423.98,
              438.0528000000003,
              440.58831022654925,
              442.195091321057,
              442.77,
              451.62367346938777,
              456.28799999999995,
              459.54,
              469.29,
              472.14,
              479.69591836734696,
              483.94,
              488.5,
              496.95,
              500.3,
              507.76816326530616,
              509.30960000000005,
              512.8884000000002,
              526.01,
              531.86,
              535.8404081632654,
              544.1884000000001,
              551.6548000000009,
              563.9126530612244,
              566.27,
              573.83,
              590.5696,
              591.9848979591836,
              600.22,
              615.5532000000001,
              620.0571428571428,
              622.45,
              637.58,
              645.0860000000001,
              648.129387755102,
              661.1860000000003,
              667.8472000000002,
              676.2016326530612,
              684.44,
              692.8,
              704.2738775510204,
              722.9368,
              732.3461224489796,
              734.8504000000001,
              760.4183673469388,
              763.28,
              770.68,
              788.4906122448979,
              796.98,
              804.75,
              816.5628571428571,
              844.6351020408163,
              845.55,
              854.87,
              872.7073469387755,
              893.54,
              900.7795918367347,
              901.92,
              928.8518367346938,
              956.924081632653,
              979.02,
              984.9963265306122,
              987.7460000000001,
              1013.0685714285714,
              1041.1408163265307,
              1069.2130612244898,
              1097.285306122449,
              1115.77,
              1125.3575510204082,
              1129.19,
              1153.4297959183673,
              1181.5020408163266,
              1209.5742857142857,
              1237.6465306122448,
              1265.718775510204,
              1293.7910204081631,
              1321.8632653061225,
              1349.9355102040815,
              1378.0077551020408,
              1406.08
            ],
            "y": [
              0.0002940386368049347,
              0.00045628326456148566,
              0.0005545936314959222,
              0.0006063226517508009,
              0.0006500102917967992,
              0.0007662566957153076,
              0.0008060473191519263,
              0.0008635658829528619,
              0.0009635343182477998,
              0.0010098065666029226,
              0.0010832643797905428,
              0.0011450188381221017,
              0.0011790791521950066,
              0.0012424029197984215,
              0.001265450824909975,
              0.00129585548808233,
              0.0013126116285483785,
              0.0013592496376647611,
              0.0014209898424784985,
              0.001455407298133223,
              0.0014912672530350997,
              0.0015012453200372096,
              0.0015236871489084666,
              0.0015856044813085941,
              0.0016136607822670448,
              0.0016621392172994863,
              0.0016736739309707084,
              0.001692519804839329,
              0.001735795522291818,
              0.001756768406506263,
              0.0017840564332454545,
              0.0017985729284801877,
              0.0017988925088855405,
              0.0018251271544107485,
              0.001832478495827414,
              0.0018550319774963866,
              0.001862306061660074,
              0.0018798186553366223,
              0.0018854922429598043,
              0.0018863324526656024,
              0.0019011358802030539,
              0.0019039242378041477,
              0.0019096092356001132,
              0.0019098506553404605,
              0.0019086622595876855,
              0.0019079339057402568,
              0.0019077877298087245,
              0.001902176805815176,
              0.0019000441057929964,
              0.0018850033507571244,
              0.0018843466431807132,
              0.001866748745453602,
              0.0018657172541849633,
              0.0018610091518787711,
              0.0018382781750435771,
              0.001836534793971193,
              0.0017881956006458527,
              0.0017847628246616429,
              0.0017575535051687584,
              0.001737418897360207,
              0.0017362962212976976,
              0.0016887271325018306,
              0.0016887271325018306,
              0.0016887271325018306,
              0.0016887271325018306,
              0.0016364562452518498,
              0.001628653760460989,
              0.0016278587051304547,
              0.001583218788824951,
              0.0015779117502752729,
              0.0015150514254629274,
              0.001502074610782917,
              0.0015002893143232538,
              0.0014443639198815398,
              0.001434833060396959,
              0.0014288745264531557,
              0.0014267574971685065,
              0.001395078641988017,
              0.0013790067940431434,
              0.0013680092086569239,
              0.0013358044576246744,
              0.0013265414054004204,
              0.0013021309316617743,
              0.0012884358765018692,
              0.0012736717264654597,
              0.0012460317535893757,
              0.0012349354021455523,
              0.0012098615135028353,
              0.0012046260629451832,
              0.0011923920140359545,
              0.00114670301375466,
              0.0011260292413770006,
              0.0011119136142496032,
              0.0010823286863544776,
              0.00105608631297277,
              0.0010140015186312266,
              0.001006105111229612,
              0.000981305124029638,
              0.0009295748275205811,
              0.0009254097222895873,
              0.0009017944203257211,
              0.0008603220858721329,
              0.0008486405654901817,
              0.0008425072730132018,
              0.0008045885991025972,
              0.0007861328853706879,
              0.0007786869462421121,
              0.0007468916791893517,
              0.0007307522893331937,
              0.0007106278432323516,
              0.0006909929572541193,
              0.0006714001471378846,
              0.000645298839042902,
              0.0006055297121658641,
              0.0005869653475144798,
              0.0005821983844763239,
              0.0005373445466133561,
              0.0005326933938453729,
              0.0005209226589287105,
              0.0004936729849249947,
              0.0004809819792260911,
              0.00046942184931799395,
              0.0004518275095659188,
              0.0004093701646670093,
              0.0004079657367161057,
              0.00039357985723332173,
              0.0003656690676764002,
              0.00033262259122695425,
              0.0003211068400812338,
              0.0003192948966048412,
              0.00027712374581859324,
              0.0002361244419035551,
              0.00020755426677576488,
              0.00020051902713680232,
              0.0001973882932578751,
              0.00017183609509150802,
              0.00015055054029477474,
              0.00013618163897755064,
              0.000127366764005913,
              0.00012363889923658816,
              0.00012209773876977568,
              0.00012152764001938915,
              0.00011807151786498752,
              0.00011294054703457413,
              0.00010459105589123458,
              0.00009170565573594721,
              0.00007448786572386611,
              0.00005497013983206881,
              0.00003631730779051185,
              0.000021308907766042545,
              0.000011100112137041007,
              0.000005165784980487044
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2",
              "dash": "dash"
            },
            "mode": "lines+markers",
            "name": "Dataset2 Mean",
            "x": [
              440.58831022654925,
              440.58831022654925
            ],
            "y": [
              0,
              0.001434833060396959
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          },
          {
            "line": {
              "color": "#69b3a2"
            },
            "mode": "lines",
            "name": "Dataset2 Median",
            "x": [
              382.55,
              382.55
            ],
            "y": [
              0,
              0.0016887271325018306
            ],
            "type": "scatter",
            "xaxis": "x2",
            "yaxis": "y2"
          }
        ],
        "layout": {
          "xaxis": {
            "anchor": "y",
            "domain": [
              0,
              1
            ],
            "showgrid": false,
            "gridcolor": "black",
            "linecolor": "black",
            "range": [
              0,
              0.4
            ],
            "dtick": 0.05,
            "fixedrange": true
          },
          "yaxis": {
            "anchor": "x",
            "domain": [
              0.9200000000000002,
              1
            ],
            "showgrid": false,
            "showline": false,
            "showticklabels": false,
            "zeroline": false,
            "color": "black",
            "autorange": true,
            "rangemode": "normal",
            "fixedrange": true
          },
          "xaxis2": {
            "anchor": "y2",
            "domain": [
              0,
              1
            ],
            "fixedrange": false,
            "range": [
              73.33,
              1129.19
            ],
            "title": {
              "text": "features.installment"
            }
          },
          "yaxis2": {
            "anchor": "x2",
            "title": {
              "text": "Probability Density"
            },
            "fixedrange": true
          },
          "legend": {
            "title": {
              "text": "Legend"
            },
            "yanchor": "top",
            "y": 0.6
          },
          "height": 400,
          "bargroupgap": 0,
          "showlegend": false
        }
      }
    }
  }
}

// export const const_hardcoded_payload =
export const plot_oai = {
  "data": [
    {
      "hovertemplate": "species=setosa<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "setosa",
      "marker": {
        "color": "#636efa",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "setosa",
      "scene": "scene",
      "showlegend": true,
      "x": [
        0.28283989429473877,
        2.3636531829833984,
        1.643042802810669,
        1.7929620742797852,
        0.4548288583755493,
        -0.5381966829299927,
        1.4682378768920898,
        0.7368460297584534,
        1.8824576139450073,
        2.1645610332489014,
        -0.5898259282112122,
        1.3367421627044678,
        2.2687597274780273,
        1.7737575769424438,
        -0.9751380681991577,
        -0.8620076179504395,
        -0.7443939447402954,
        0.3579058051109314,
        -0.6436717510223389,
        -0.32121118903160095,
        -0.18910878896713257,
        -0.12435546517372131,
        1.24015212059021,
        0.4641224443912506,
        1.4254212379455566,
        2.4070799350738525,
        0.5640162229537964,
        -0.08337931334972382,
        0.08698226511478424,
        1.744332194328308,
        2.076950788497925,
        -0.1711176633834839,
        -0.6265192031860352,
        -0.789490282535553,
        2.3334264755249023,
        1.027299404144287,
        -0.3475992679595947,
        2.22965407371521,
        1.8302584886550903,
        0.23831510543823242,
        0.5480448007583618,
        2.0212554931640625,
        1.5607349872589111,
        0.4090850055217743,
        -0.3295954763889313,
        2.0155928134918213,
        -0.28179115056991577,
        1.6732105016708374,
        -0.4262945353984833,
        0.8565565943717957
      ],
      "y": [
        4.904141902923584,
        4.614861011505127,
        4.845006942749023,
        4.642330646514893,
        5.143205642700195,
        4.611292362213135,
        4.809710502624512,
        4.8123698234558105,
        4.969247341156006,
        4.320122241973877,
        4.740809917449951,
        4.583493709564209,
        4.621564865112305,
        5.078163146972656,
        4.630337238311768,
        4.816268444061279,
        4.672443389892578,
        5.027857303619385,
        4.5683465003967285,
        5.048624038696289,
        4.548396587371826,
        5.064208984375,
        4.942130088806152,
        4.683613300323486,
        4.46714448928833,
        4.43164587020874,
        4.761963844299316,
        4.8053765296936035,
        4.7272233963012695,
        4.467284202575684,
        4.397632598876953,
        4.581003665924072,
        5.104785442352295,
        4.742345333099365,
        4.540831565856934,
        4.793844223022461,
        4.509068012237549,
        4.3997039794921875,
        5.051393032073975,
        4.827348709106445,
        4.803049087524414,
        4.857615947723389,
        5.0178656578063965,
        4.850410461425781,
        4.980226516723633,
        4.720286846160889,
        5.050652980804443,
        4.7811970710754395,
        4.717691421508789,
        4.791926383972168
      ],
      "z": [
        13.035382270812988,
        12.182660102844238,
        11.96230697631836,
        12.038212776184082,
        13.247925758361816,
        13.890151023864746,
        12.091362953186035,
        12.99549674987793,
        11.687398910522461,
        12.343928337097168,
        13.70475959777832,
        12.634908676147461,
        12.203653335571289,
        11.775773048400879,
        13.976181030273438,
        13.932230949401855,
        13.882976531982422,
        13.15311336517334,
        13.805052757263184,
        13.834616661071777,
        13.357667922973633,
        13.645652770996094,
        12.251811981201172,
        13.328019142150879,
        12.766789436340332,
        12.29621410369873,
        13.323087692260742,
        13.231203079223633,
        13.018950462341309,
        12.345664978027344,
        12.342652320861816,
        13.337776184082031,
        14.035361289978027,
        13.988527297973633,
        12.24136734008789,
        12.693988800048828,
        13.39988899230957,
        12.373894691467285,
        11.801684379577637,
        13.027064323425293,
        13.081245422363281,
        11.759599685668945,
        11.768841743469238,
        13.473443031311035,
        13.8228759765625,
        12.097257614135742,
        13.854607582092285,
        12.013988494873047,
        13.580092430114746,
        12.826454162597656
      ],
      "type": "scatter3d"
    },
    {
      "hovertemplate": "species=versicolor<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "versicolor",
      "marker": {
        "color": "#EF553B",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "versicolor",
      "scene": "scene",
      "showlegend": true,
      "x": [
        8.157316207885742,
        7.962797164916992,
        8.12516975402832,
        9.077370643615723,
        7.983676433563232,
        8.310076713562012,
        7.503152370452881,
        8.956679344177246,
        8.14101505279541,
        8.702278137207031,
        8.731273651123047,
        8.296464920043945,
        8.990736961364746,
        7.464832305908203,
        8.591508865356445,
        8.08858585357666,
        8.116430282592773,
        8.832433700561523,
        7.4376983642578125,
        8.835644721984863,
        6.882636547088623,
        8.58941650390625,
        7.021752834320068,
        7.748582363128662,
        8.176712989807129,
        8.159761428833008,
        8.23605728149414,
        7.955766201019287,
        7.7489495277404785,
        8.762846946716309,
        8.901923179626465,
        8.727438926696777,
        8.802299499511719,
        6.5330634117126465,
        8.302818298339844,
        7.46946907043457,
        8.133570671081543,
        7.7292585372924805,
        8.552118301391602,
        8.908806800842285,
        8.606082916259766,
        7.62516975402832,
        8.779806137084961,
        8.805989265441895,
        8.530290603637695,
        8.373745918273926,
        8.346013069152832,
        8.026449203491211,
        8.90823745727539,
        8.569648742675781
      ],
      "y": [
        4.013482570648193,
        3.475470542907715,
        3.9346237182617188,
        1.092305302619934,
        3.412374258041382,
        1.839935302734375,
        3.4600605964660645,
        0.46106618642807007,
        3.588784694671631,
        1.0208865404129028,
        0.6411640644073486,
        2.2216203212738037,
        1.3507341146469116,
        2.8866326808929443,
        1.2323206663131714,
        3.7099297046661377,
        1.7755800485610962,
        1.6927354335784912,
        2.8416967391967773,
        1.1247625350952148,
        3.4771807193756104,
        2.1462435722351074,
        3.174457550048828,
        2.775909423828125,
        3.1918392181396484,
        3.530766725540161,
        3.822723150253296,
        4.09730863571167,
        2.698718786239624,
        0.9454508423805237,
        0.9210201501846313,
        0.8883463144302368,
        1.4258298873901367,
        3.444777011871338,
        1.6448938846588135,
        3.253371238708496,
        3.800886392593384,
        2.7597053050994873,
        1.9858813285827637,
        1.2006839513778687,
        1.592673420906067,
        2.885986089706421,
        1.4992122650146484,
        0.5616950392723083,
        1.4987486600875854,
        1.9266300201416016,
        1.9414393901824951,
        2.8312511444091797,
        0.5161522030830383,
        1.738358736038208
      ],
      "z": [
        3.27951979637146,
        3.554769277572632,
        3.1973416805267334,
        5.263380527496338,
        3.0992953777313232,
        4.534695148468018,
        3.5751612186431885,
        5.36515474319458,
        3.2104363441467285,
        4.967105865478516,
        5.285630702972412,
        4.503807544708252,
        5.190262317657471,
        3.45721697807312,
        5.4921345710754395,
        3.435607433319092,
        4.696383953094482,
        5.089709281921387,
        2.8867251873016357,
        5.494051933288574,
        3.28440260887146,
        4.416955471038818,
        2.481739044189453,
        3.5611977577209473,
        3.4910643100738525,
        3.3947174549102783,
        3.155364990234375,
        3.016745090484619,
        3.8206188678741455,
        5.485256195068359,
        5.560921669006348,
        5.585117340087891,
        5.341315269470215,
        2.5064830780029297,
        4.628015995025635,
        3.723257303237915,
        3.093020439147949,
        3.118350028991699,
        5.002904415130615,
        5.169388294219971,
        4.937466621398926,
        3.6256706714630127,
        5.268548011779785,
        5.226296424865723,
        4.930573463439941,
        4.898448467254639,
        4.879852771759033,
        3.7214572429656982,
        5.289479732513428,
        5.108554840087891
      ],
      "type": "scatter3d"
    },
    {
      "hovertemplate": "species=virginica<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "virginica",
      "marker": {
        "color": "#00cc96",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "virginica",
      "scene": "scene",
      "showlegend": true,
      "x": [
        4.7522687911987305,
        6.215164661407471,
        3.755023241043091,
        5.232428073883057,
        4.75087833404541,
        3.3288514614105225,
        8.528048515319824,
        3.5229368209838867,
        4.991549968719482,
        3.9106359481811523,
        4.981497764587402,
        5.299943447113037,
        4.4596710205078125,
        6.169271469116211,
        6.2385101318359375,
        4.8873491287231445,
        5.051162242889404,
        3.418842077255249,
        3.319460153579712,
        6.999414920806885,
        3.9955289363861084,
        6.149507999420166,
        3.296036958694458,
        6.894285678863525,
        4.197579383850098,
        3.6525392532348633,
        6.887113094329834,
        6.7767181396484375,
        5.053820610046387,
        3.724125385284424,
        3.5750670433044434,
        3.5706474781036377,
        4.961328506469727,
        6.71744441986084,
        5.703354835510254,
        3.404629945755005,
        4.857645034790039,
        5.014423847198486,
        6.745270252227783,
        4.309038162231445,
        4.3434157371521,
        4.589776992797852,
        6.262762546539307,
        4.1159749031066895,
        4.325124263763428,
        4.680212020874023,
        6.813770771026611,
        4.88995885848999,
        4.900014877319336,
        6.471556186676025
      ],
      "y": [
        4.960685729980469,
        3.699594259262085,
        5.033940315246582,
        4.425368785858154,
        4.719629287719727,
        5.039942741394043,
        1.3768129348754883,
        4.990103244781494,
        4.269835472106934,
        5.260443210601807,
        5.2410149574279785,
        4.365882396697998,
        4.949580669403076,
        3.554594039916992,
        3.725926399230957,
        5.153733730316162,
        4.594907760620117,
        5.36839485168457,
        5.1333746910095215,
        3.025874614715576,
        5.211645603179932,
        3.604405403137207,
        5.112452030181885,
        3.3431644439697266,
        5.009037494659424,
        5.0164899826049805,
        3.292398452758789,
        3.5152182579040527,
        4.48868465423584,
        5.032498836517334,
        5.135725021362305,
        5.372132301330566,
        4.668494701385498,
        3.3765974044799805,
        4.031891822814941,
        5.326527118682861,
        5.086298942565918,
        4.769809246063232,
        3.4128146171569824,
        5.1829094886779785,
        5.127948760986328,
        5.173641681671143,
        3.6235640048980713,
        5.088520526885986,
        5.070090293884277,
        5.197475433349609,
        3.477752208709717,
        4.945324420928955,
        5.138882160186768,
        3.6092238426208496
      ],
      "z": [
        0.05575557053089142,
        3.093029022216797,
        -0.2624789774417877,
        1.0442335605621338,
        0.2200004607439041,
        -1.0408580303192139,
        4.736396789550781,
        -0.6963408589363098,
        0.6569582223892212,
        -0.4394775629043579,
        0.9055399894714355,
        1.1233395338058472,
        0.4505331218242645,
        3.110152006149292,
        3.233858346939087,
        0.5728517770767212,
        1.0931304693222046,
        -0.9425045251846313,
        -0.9045315384864807,
        2.611375331878662,
        -0.086641326546669,
        3.162769079208374,
        -0.879566490650177,
        2.564309597015381,
        0.0846014991402626,
        -0.5352096557617188,
        2.855065107345581,
        3.116396903991699,
        0.7205502390861511,
        -0.44297337532043457,
        -0.6985517740249634,
        -0.7590739130973816,
        0.6000275015830994,
        2.4584004878997803,
        1.4831156730651855,
        -0.8125135898590088,
        0.1574726104736328,
        1.0167573690414429,
        3.1574137210845947,
        0.5491154193878174,
        0.1857832819223404,
        0.7322923541069031,
        3.06211256980896,
        -0.14638924598693848,
        -0.07839028537273407,
        0.645548403263092,
        2.403102397918701,
        0.9855795502662659,
        0.30366095900535583,
        3.0098953247070312
      ],
      "type": "scatter3d"
    }
  ],
  "layout": {
    "template": {
      "data": {
        "histogram2dcontour": [
          {
            "type": "histogram2dcontour",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "choropleth": [
          {
            "type": "choropleth",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "histogram2d": [
          {
            "type": "histogram2d",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "heatmap": [
          {
            "type": "heatmap",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "heatmapgl": [
          {
            "type": "heatmapgl",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "contourcarpet": [
          {
            "type": "contourcarpet",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "contour": [
          {
            "type": "contour",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "surface": [
          {
            "type": "surface",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "mesh3d": [
          {
            "type": "mesh3d",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "scatter": [
          {
            "fillpattern": {
              "fillmode": "overlay",
              "size": 10,
              "solidity": 0.2
            },
            "type": "scatter"
          }
        ],
        "parcoords": [
          {
            "type": "parcoords",
            "line": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterpolargl": [
          {
            "type": "scatterpolargl",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "bar": [
          {
            "error_x": {
              "color": "#2a3f5f"
            },
            "error_y": {
              "color": "#2a3f5f"
            },
            "marker": {
              "line": {
                "color": "#E5ECF6",
                "width": 0.5
              },
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "bar"
          }
        ],
        "scattergeo": [
          {
            "type": "scattergeo",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterpolar": [
          {
            "type": "scatterpolar",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "histogram": [
          {
            "marker": {
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "histogram"
          }
        ],
        "scattergl": [
          {
            "type": "scattergl",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatter3d": [
          {
            "type": "scatter3d",
            "line": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            },
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scattermapbox": [
          {
            "type": "scattermapbox",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterternary": [
          {
            "type": "scatterternary",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scattercarpet": [
          {
            "type": "scattercarpet",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "carpet": [
          {
            "aaxis": {
              "endlinecolor": "#2a3f5f",
              "gridcolor": "white",
              "linecolor": "white",
              "minorgridcolor": "white",
              "startlinecolor": "#2a3f5f"
            },
            "baxis": {
              "endlinecolor": "#2a3f5f",
              "gridcolor": "white",
              "linecolor": "white",
              "minorgridcolor": "white",
              "startlinecolor": "#2a3f5f"
            },
            "type": "carpet"
          }
        ],
        "table": [
          {
            "cells": {
              "fill": {
                "color": "#EBF0F8"
              },
              "line": {
                "color": "white"
              }
            },
            "header": {
              "fill": {
                "color": "#C8D4E3"
              },
              "line": {
                "color": "white"
              }
            },
            "type": "table"
          }
        ],
        "barpolar": [
          {
            "marker": {
              "line": {
                "color": "#E5ECF6",
                "width": 0.5
              },
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "barpolar"
          }
        ],
        "pie": [
          {
            "automargin": true,
            "type": "pie"
          }
        ]
      },
      "layout": {
        "autotypenumbers": "strict",
        "colorway": [
          "#636efa",
          "#EF553B",
          "#00cc96",
          "#ab63fa",
          "#FFA15A",
          "#19d3f3",
          "#FF6692",
          "#B6E880",
          "#FF97FF",
          "#FECB52"
        ],
        "font": {
          "color": "#2a3f5f"
        },
        "hovermode": "closest",
        "hoverlabel": {
          "align": "left"
        },
        "paper_bgcolor": "white",
        "plot_bgcolor": "#E5ECF6",
        "polar": {
          "bgcolor": "#E5ECF6",
          "angularaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "radialaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          }
        },
        "ternary": {
          "bgcolor": "#E5ECF6",
          "aaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "baxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "caxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          }
        },
        "coloraxis": {
          "colorbar": {
            "outlinewidth": 0,
            "ticks": ""
          }
        },
        "colorscale": {
          "sequential": [
            [
              0,
              "#0d0887"
            ],
            [
              0.1111111111111111,
              "#46039f"
            ],
            [
              0.2222222222222222,
              "#7201a8"
            ],
            [
              0.3333333333333333,
              "#9c179e"
            ],
            [
              0.4444444444444444,
              "#bd3786"
            ],
            [
              0.5555555555555556,
              "#d8576b"
            ],
            [
              0.6666666666666666,
              "#ed7953"
            ],
            [
              0.7777777777777778,
              "#fb9f3a"
            ],
            [
              0.8888888888888888,
              "#fdca26"
            ],
            [
              1,
              "#f0f921"
            ]
          ],
          "sequentialminus": [
            [
              0,
              "#0d0887"
            ],
            [
              0.1111111111111111,
              "#46039f"
            ],
            [
              0.2222222222222222,
              "#7201a8"
            ],
            [
              0.3333333333333333,
              "#9c179e"
            ],
            [
              0.4444444444444444,
              "#bd3786"
            ],
            [
              0.5555555555555556,
              "#d8576b"
            ],
            [
              0.6666666666666666,
              "#ed7953"
            ],
            [
              0.7777777777777778,
              "#fb9f3a"
            ],
            [
              0.8888888888888888,
              "#fdca26"
            ],
            [
              1,
              "#f0f921"
            ]
          ],
          "diverging": [
            [
              0,
              "#8e0152"
            ],
            [
              0.1,
              "#c51b7d"
            ],
            [
              0.2,
              "#de77ae"
            ],
            [
              0.3,
              "#f1b6da"
            ],
            [
              0.4,
              "#fde0ef"
            ],
            [
              0.5,
              "#f7f7f7"
            ],
            [
              0.6,
              "#e6f5d0"
            ],
            [
              0.7,
              "#b8e186"
            ],
            [
              0.8,
              "#7fbc41"
            ],
            [
              0.9,
              "#4d9221"
            ],
            [
              1,
              "#276419"
            ]
          ]
        },
        "xaxis": {
          "gridcolor": "white",
          "linecolor": "white",
          "ticks": "",
          "title": {
            "standoff": 15
          },
          "zerolinecolor": "white",
          "automargin": true,
          "zerolinewidth": 2
        },
        "yaxis": {
          "gridcolor": "white",
          "linecolor": "white",
          "ticks": "",
          "title": {
            "standoff": 15
          },
          "zerolinecolor": "white",
          "automargin": true,
          "zerolinewidth": 2
        },
        "scene": {
          "xaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          },
          "yaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          },
          "zaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          }
        },
        "shapedefaults": {
          "line": {
            "color": "#2a3f5f"
          }
        },
        "annotationdefaults": {
          "arrowcolor": "#2a3f5f",
          "arrowhead": 0,
          "arrowwidth": 1
        },
        "geo": {
          "bgcolor": "white",
          "landcolor": "#E5ECF6",
          "subunitcolor": "white",
          "showland": true,
          "showlakes": true,
          "lakecolor": "white"
        },
        "title": {
          "x": 0.05
        },
        "mapbox": {
          "style": "light"
        }
      }
    },
    "scene": {
      "domain": {
        "x": [
          0,
          1
        ],
        "y": [
          0,
          1
        ]
      },
      "xaxis": {
        "title": {
          "text": "0"
        }
      },
      "yaxis": {
        "title": {
          "text": "1"
        }
      },
      "zaxis": {
        "title": {
          "text": "2"
        }
      }
    },
    "legend": {
      "title": {
        "text": "species"
      },
      "tracegroupgap": 0
    },
    "margin": {
      "t": 60
    },
    "dragmode": "lasso"
  }
}

export const plot_dc = {
  "data": [
    {
      "hovertemplate": "species=setosa<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "setosa",
      "marker": {
        "color": "#636efa",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "setosa",
      "scene": "scene",
      "showlegend": true,
      "x": [
        0.28283989429473877,
        2.3636531829833984,
        1.643042802810669,
        1.7929620742797852,
        0.4548288583755493,
        -0.5381966829299927,
        1.4682378768920898,
        0.7368460297584534,
        1.8824576139450073,
        2.1645610332489014,
        -0.5898259282112122,
        1.3367421627044678,
        2.2687597274780273,
        1.7737575769424438,
        -0.9751380681991577,
        -0.8620076179504395,
        -0.7443939447402954,
        0.3579058051109314,
        -0.6436717510223389,
        -0.32121118903160095,
        -0.18910878896713257,
        -0.12435546517372131,
        1.24015212059021,
        0.4641224443912506,
        1.4254212379455566,
        2.4070799350738525,
        0.5640162229537964,
        -0.08337931334972382,
        0.08698226511478424,
        1.744332194328308,
        2.076950788497925,
        -0.1711176633834839,
        -0.6265192031860352,
        -0.789490282535553,
        2.3334264755249023,
        1.027299404144287,
        -0.3475992679595947,
        2.22965407371521,
        1.8302584886550903,
        0.23831510543823242,
        0.5480448007583618,
        2.0212554931640625,
        1.5607349872589111,
        0.4090850055217743,
        -0.3295954763889313,
        2.0155928134918213,
        -0.28179115056991577,
        1.6732105016708374,
        -0.4262945353984833,
        0.8565565943717957
      ],
      "y": [
        4.904141902923584,
        4.614861011505127,
        4.845006942749023,
        4.642330646514893,
        5.143205642700195,
        4.611292362213135,
        4.809710502624512,
        4.8123698234558105,
        4.969247341156006,
        4.320122241973877,
        4.740809917449951,
        4.583493709564209,
        4.621564865112305,
        5.078163146972656,
        4.630337238311768,
        4.816268444061279,
        4.672443389892578,
        5.027857303619385,
        4.5683465003967285,
        5.048624038696289,
        4.548396587371826,
        5.064208984375,
        4.942130088806152,
        4.683613300323486,
        4.46714448928833,
        4.43164587020874,
        4.761963844299316,
        4.8053765296936035,
        4.7272233963012695,
        4.467284202575684,
        4.397632598876953,
        4.581003665924072,
        5.104785442352295,
        4.742345333099365,
        4.540831565856934,
        4.793844223022461,
        4.509068012237549,
        4.3997039794921875,
        5.051393032073975,
        4.827348709106445,
        4.803049087524414,
        4.857615947723389,
        5.0178656578063965,
        4.850410461425781,
        4.980226516723633,
        4.720286846160889,
        5.050652980804443,
        4.7811970710754395,
        4.717691421508789,
        4.791926383972168
      ],
      "z": [
        13.035382270812988,
        12.182660102844238,
        11.96230697631836,
        12.038212776184082,
        13.247925758361816,
        13.890151023864746,
        12.091362953186035,
        12.99549674987793,
        11.687398910522461,
        12.343928337097168,
        13.70475959777832,
        12.634908676147461,
        12.203653335571289,
        11.775773048400879,
        13.976181030273438,
        13.932230949401855,
        13.882976531982422,
        13.15311336517334,
        13.805052757263184,
        13.834616661071777,
        13.357667922973633,
        13.645652770996094,
        12.251811981201172,
        13.328019142150879,
        12.766789436340332,
        12.29621410369873,
        13.323087692260742,
        13.231203079223633,
        13.018950462341309,
        12.345664978027344,
        12.342652320861816,
        13.337776184082031,
        14.035361289978027,
        13.988527297973633,
        12.24136734008789,
        12.693988800048828,
        13.39988899230957,
        12.373894691467285,
        11.801684379577637,
        13.027064323425293,
        13.081245422363281,
        11.759599685668945,
        11.768841743469238,
        13.473443031311035,
        13.8228759765625,
        12.097257614135742,
        13.854607582092285,
        12.013988494873047,
        13.580092430114746,
        12.826454162597656
      ],
      "type": "scatter3d"
    },
    {
      "hovertemplate": "species=versicolor<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "versicolor",
      "marker": {
        "color": "#EF553B",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "versicolor",
      "scene": "scene",
      "showlegend": true,
      "x": [
        8.157316207885742,
        7.962797164916992,
        8.12516975402832,
        9.077370643615723,
        7.983676433563232,
        8.310076713562012,
        7.503152370452881,
        8.956679344177246,
        8.14101505279541,
        8.702278137207031,
        8.731273651123047,
        8.296464920043945,
        8.990736961364746,
        7.464832305908203,
        8.591508865356445,
        8.08858585357666,
        8.116430282592773,
        8.832433700561523,
        7.4376983642578125,
        8.835644721984863,
        6.882636547088623,
        8.58941650390625,
        7.021752834320068,
        7.748582363128662,
        8.176712989807129,
        8.159761428833008,
        8.23605728149414,
        7.955766201019287,
        7.7489495277404785,
        8.762846946716309,
        8.901923179626465,
        8.727438926696777,
        8.802299499511719,
        6.5330634117126465,
        8.302818298339844,
        7.46946907043457,
        8.133570671081543,
        7.7292585372924805,
        8.552118301391602,
        8.908806800842285,
        8.606082916259766,
        7.62516975402832,
        8.779806137084961,
        8.805989265441895,
        8.530290603637695,
        8.373745918273926,
        8.346013069152832,
        8.026449203491211,
        8.90823745727539,
        8.569648742675781
      ],
      "y": [
        4.013482570648193,
        3.475470542907715,
        3.9346237182617188,
        1.092305302619934,
        3.412374258041382,
        1.839935302734375,
        3.4600605964660645,
        0.46106618642807007,
        3.588784694671631,
        1.0208865404129028,
        0.6411640644073486,
        2.2216203212738037,
        1.3507341146469116,
        2.8866326808929443,
        1.2323206663131714,
        3.7099297046661377,
        1.7755800485610962,
        1.6927354335784912,
        2.8416967391967773,
        1.1247625350952148,
        3.4771807193756104,
        2.1462435722351074,
        3.174457550048828,
        2.775909423828125,
        3.1918392181396484,
        3.530766725540161,
        3.822723150253296,
        4.09730863571167,
        2.698718786239624,
        0.9454508423805237,
        0.9210201501846313,
        0.8883463144302368,
        1.4258298873901367,
        3.444777011871338,
        1.6448938846588135,
        3.253371238708496,
        3.800886392593384,
        2.7597053050994873,
        1.9858813285827637,
        1.2006839513778687,
        1.592673420906067,
        2.885986089706421,
        1.4992122650146484,
        0.5616950392723083,
        1.4987486600875854,
        1.9266300201416016,
        1.9414393901824951,
        2.8312511444091797,
        0.5161522030830383,
        1.738358736038208
      ],
      "z": [
        3.27951979637146,
        3.554769277572632,
        3.1973416805267334,
        5.263380527496338,
        3.0992953777313232,
        4.534695148468018,
        3.5751612186431885,
        5.36515474319458,
        3.2104363441467285,
        4.967105865478516,
        5.285630702972412,
        4.503807544708252,
        5.190262317657471,
        3.45721697807312,
        5.4921345710754395,
        3.435607433319092,
        4.696383953094482,
        5.089709281921387,
        2.8867251873016357,
        5.494051933288574,
        3.28440260887146,
        4.416955471038818,
        2.481739044189453,
        3.5611977577209473,
        3.4910643100738525,
        3.3947174549102783,
        3.155364990234375,
        3.016745090484619,
        3.8206188678741455,
        5.485256195068359,
        5.560921669006348,
        5.585117340087891,
        5.341315269470215,
        2.5064830780029297,
        4.628015995025635,
        3.723257303237915,
        3.093020439147949,
        3.118350028991699,
        5.002904415130615,
        5.169388294219971,
        4.937466621398926,
        3.6256706714630127,
        5.268548011779785,
        5.226296424865723,
        4.930573463439941,
        4.898448467254639,
        4.879852771759033,
        3.7214572429656982,
        5.289479732513428,
        5.108554840087891
      ],
      "type": "scatter3d"
    },
    {
      "hovertemplate": "species=virginica<br>0=%{x}<br>1=%{y}<br>2=%{z}<extra></extra>",
      "legendgroup": "virginica",
      "marker": {
        "color": "#00cc96",
        "symbol": "circle",
        "size": 5
      },
      "mode": "markers",
      "name": "virginica",
      "scene": "scene",
      "showlegend": true,
      "x": [
        4.7522687911987305,
        6.215164661407471,
        3.755023241043091,
        5.232428073883057,
        4.75087833404541,
        3.3288514614105225,
        8.528048515319824,
        3.5229368209838867,
        4.991549968719482,
        3.9106359481811523,
        4.981497764587402,
        5.299943447113037,
        4.4596710205078125,
        6.169271469116211,
        6.2385101318359375,
        4.8873491287231445,
        5.051162242889404,
        3.418842077255249,
        3.319460153579712,
        6.999414920806885,
        3.9955289363861084,
        6.149507999420166,
        3.296036958694458,
        6.894285678863525,
        4.197579383850098,
        3.6525392532348633,
        6.887113094329834,
        6.7767181396484375,
        5.053820610046387,
        3.724125385284424,
        3.5750670433044434,
        3.5706474781036377,
        4.961328506469727,
        6.71744441986084,
        5.703354835510254,
        3.404629945755005,
        4.857645034790039,
        5.014423847198486,
        6.745270252227783,
        4.309038162231445,
        4.3434157371521,
        4.589776992797852,
        6.262762546539307,
        4.1159749031066895,
        4.325124263763428,
        4.680212020874023,
        6.813770771026611,
        4.88995885848999,
        4.900014877319336,
        6.471556186676025
      ],
      "y": [
        4.960685729980469,
        3.699594259262085,
        5.033940315246582,
        4.425368785858154,
        4.719629287719727,
        5.039942741394043,
        1.3768129348754883,
        4.990103244781494,
        4.269835472106934,
        5.260443210601807,
        5.2410149574279785,
        4.365882396697998,
        4.949580669403076,
        3.554594039916992,
        3.725926399230957,
        5.153733730316162,
        4.594907760620117,
        5.36839485168457,
        5.1333746910095215,
        3.025874614715576,
        5.211645603179932,
        3.604405403137207,
        5.112452030181885,
        3.3431644439697266,
        5.009037494659424,
        5.0164899826049805,
        3.292398452758789,
        3.5152182579040527,
        4.48868465423584,
        5.032498836517334,
        5.135725021362305,
        5.372132301330566,
        4.668494701385498,
        3.3765974044799805,
        4.031891822814941,
        5.326527118682861,
        5.086298942565918,
        4.769809246063232,
        3.4128146171569824,
        5.1829094886779785,
        5.127948760986328,
        5.173641681671143,
        3.6235640048980713,
        5.088520526885986,
        5.070090293884277,
        5.197475433349609,
        3.477752208709717,
        4.945324420928955,
        5.138882160186768,
        3.6092238426208496
      ],
      "z": [
        0.05575557053089142,
        3.093029022216797,
        -0.2624789774417877,
        1.0442335605621338,
        0.2200004607439041,
        -1.0408580303192139,
        4.736396789550781,
        -0.6963408589363098,
        0.6569582223892212,
        -0.4394775629043579,
        0.9055399894714355,
        1.1233395338058472,
        0.4505331218242645,
        3.110152006149292,
        3.233858346939087,
        0.5728517770767212,
        1.0931304693222046,
        -0.9425045251846313,
        -0.9045315384864807,
        2.611375331878662,
        -0.086641326546669,
        3.162769079208374,
        -0.879566490650177,
        2.564309597015381,
        0.0846014991402626,
        -0.5352096557617188,
        2.855065107345581,
        3.116396903991699,
        0.7205502390861511,
        -0.44297337532043457,
        -0.6985517740249634,
        -0.7590739130973816,
        0.6000275015830994,
        2.4584004878997803,
        1.4831156730651855,
        -0.8125135898590088,
        0.1574726104736328,
        1.0167573690414429,
        3.1574137210845947,
        0.5491154193878174,
        0.1857832819223404,
        0.7322923541069031,
        3.06211256980896,
        -0.14638924598693848,
        -0.07839028537273407,
        0.645548403263092,
        2.403102397918701,
        0.9855795502662659,
        0.30366095900535583,
        3.0098953247070312
      ],
      "type": "scatter3d"
    }
  ],
  "layout": {
    "template": {
      "data": {
        "histogram2dcontour": [
          {
            "type": "histogram2dcontour",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "choropleth": [
          {
            "type": "choropleth",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "histogram2d": [
          {
            "type": "histogram2d",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "heatmap": [
          {
            "type": "heatmap",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "heatmapgl": [
          {
            "type": "heatmapgl",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "contourcarpet": [
          {
            "type": "contourcarpet",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "contour": [
          {
            "type": "contour",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "surface": [
          {
            "type": "surface",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            },
            "colorscale": [
              [
                0,
                "#0d0887"
              ],
              [
                0.1111111111111111,
                "#46039f"
              ],
              [
                0.2222222222222222,
                "#7201a8"
              ],
              [
                0.3333333333333333,
                "#9c179e"
              ],
              [
                0.4444444444444444,
                "#bd3786"
              ],
              [
                0.5555555555555556,
                "#d8576b"
              ],
              [
                0.6666666666666666,
                "#ed7953"
              ],
              [
                0.7777777777777778,
                "#fb9f3a"
              ],
              [
                0.8888888888888888,
                "#fdca26"
              ],
              [
                1,
                "#f0f921"
              ]
            ]
          }
        ],
        "mesh3d": [
          {
            "type": "mesh3d",
            "colorbar": {
              "outlinewidth": 0,
              "ticks": ""
            }
          }
        ],
        "scatter": [
          {
            "fillpattern": {
              "fillmode": "overlay",
              "size": 10,
              "solidity": 0.2
            },
            "type": "scatter"
          }
        ],
        "parcoords": [
          {
            "type": "parcoords",
            "line": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterpolargl": [
          {
            "type": "scatterpolargl",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "bar": [
          {
            "error_x": {
              "color": "#2a3f5f"
            },
            "error_y": {
              "color": "#2a3f5f"
            },
            "marker": {
              "line": {
                "color": "#E5ECF6",
                "width": 0.5
              },
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "bar"
          }
        ],
        "scattergeo": [
          {
            "type": "scattergeo",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterpolar": [
          {
            "type": "scatterpolar",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "histogram": [
          {
            "marker": {
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "histogram"
          }
        ],
        "scattergl": [
          {
            "type": "scattergl",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatter3d": [
          {
            "type": "scatter3d",
            "line": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            },
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scattermapbox": [
          {
            "type": "scattermapbox",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scatterternary": [
          {
            "type": "scatterternary",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "scattercarpet": [
          {
            "type": "scattercarpet",
            "marker": {
              "colorbar": {
                "outlinewidth": 0,
                "ticks": ""
              }
            }
          }
        ],
        "carpet": [
          {
            "aaxis": {
              "endlinecolor": "#2a3f5f",
              "gridcolor": "white",
              "linecolor": "white",
              "minorgridcolor": "white",
              "startlinecolor": "#2a3f5f"
            },
            "baxis": {
              "endlinecolor": "#2a3f5f",
              "gridcolor": "white",
              "linecolor": "white",
              "minorgridcolor": "white",
              "startlinecolor": "#2a3f5f"
            },
            "type": "carpet"
          }
        ],
        "table": [
          {
            "cells": {
              "fill": {
                "color": "#EBF0F8"
              },
              "line": {
                "color": "white"
              }
            },
            "header": {
              "fill": {
                "color": "#C8D4E3"
              },
              "line": {
                "color": "white"
              }
            },
            "type": "table"
          }
        ],
        "barpolar": [
          {
            "marker": {
              "line": {
                "color": "#E5ECF6",
                "width": 0.5
              },
              "pattern": {
                "fillmode": "overlay",
                "size": 10,
                "solidity": 0.2
              }
            },
            "type": "barpolar"
          }
        ],
        "pie": [
          {
            "automargin": true,
            "type": "pie"
          }
        ]
      },
      "layout": {
        "autotypenumbers": "strict",
        "colorway": [
          "#636efa",
          "#EF553B",
          "#00cc96",
          "#ab63fa",
          "#FFA15A",
          "#19d3f3",
          "#FF6692",
          "#B6E880",
          "#FF97FF",
          "#FECB52"
        ],
        "font": {
          "color": "#2a3f5f"
        },
        "hovermode": "closest",
        "hoverlabel": {
          "align": "left"
        },
        "paper_bgcolor": "white",
        "plot_bgcolor": "#E5ECF6",
        "polar": {
          "bgcolor": "#E5ECF6",
          "angularaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "radialaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          }
        },
        "ternary": {
          "bgcolor": "#E5ECF6",
          "aaxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "baxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          },
          "caxis": {
            "gridcolor": "white",
            "linecolor": "white",
            "ticks": ""
          }
        },
        "coloraxis": {
          "colorbar": {
            "outlinewidth": 0,
            "ticks": ""
          }
        },
        "colorscale": {
          "sequential": [
            [
              0,
              "#0d0887"
            ],
            [
              0.1111111111111111,
              "#46039f"
            ],
            [
              0.2222222222222222,
              "#7201a8"
            ],
            [
              0.3333333333333333,
              "#9c179e"
            ],
            [
              0.4444444444444444,
              "#bd3786"
            ],
            [
              0.5555555555555556,
              "#d8576b"
            ],
            [
              0.6666666666666666,
              "#ed7953"
            ],
            [
              0.7777777777777778,
              "#fb9f3a"
            ],
            [
              0.8888888888888888,
              "#fdca26"
            ],
            [
              1,
              "#f0f921"
            ]
          ],
          "sequentialminus": [
            [
              0,
              "#0d0887"
            ],
            [
              0.1111111111111111,
              "#46039f"
            ],
            [
              0.2222222222222222,
              "#7201a8"
            ],
            [
              0.3333333333333333,
              "#9c179e"
            ],
            [
              0.4444444444444444,
              "#bd3786"
            ],
            [
              0.5555555555555556,
              "#d8576b"
            ],
            [
              0.6666666666666666,
              "#ed7953"
            ],
            [
              0.7777777777777778,
              "#fb9f3a"
            ],
            [
              0.8888888888888888,
              "#fdca26"
            ],
            [
              1,
              "#f0f921"
            ]
          ],
          "diverging": [
            [
              0,
              "#8e0152"
            ],
            [
              0.1,
              "#c51b7d"
            ],
            [
              0.2,
              "#de77ae"
            ],
            [
              0.3,
              "#f1b6da"
            ],
            [
              0.4,
              "#fde0ef"
            ],
            [
              0.5,
              "#f7f7f7"
            ],
            [
              0.6,
              "#e6f5d0"
            ],
            [
              0.7,
              "#b8e186"
            ],
            [
              0.8,
              "#7fbc41"
            ],
            [
              0.9,
              "#4d9221"
            ],
            [
              1,
              "#276419"
            ]
          ]
        },
        "xaxis": {
          "gridcolor": "white",
          "linecolor": "white",
          "ticks": "",
          "title": {
            "standoff": 15
          },
          "zerolinecolor": "white",
          "automargin": true,
          "zerolinewidth": 2
        },
        "yaxis": {
          "gridcolor": "white",
          "linecolor": "white",
          "ticks": "",
          "title": {
            "standoff": 15
          },
          "zerolinecolor": "white",
          "automargin": true,
          "zerolinewidth": 2
        },
        "scene": {
          "xaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          },
          "yaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          },
          "zaxis": {
            "backgroundcolor": "#E5ECF6",
            "gridcolor": "white",
            "linecolor": "white",
            "showbackground": true,
            "ticks": "",
            "zerolinecolor": "white",
            "gridwidth": 2
          }
        },
        "shapedefaults": {
          "line": {
            "color": "#2a3f5f"
          }
        },
        "annotationdefaults": {
          "arrowcolor": "#2a3f5f",
          "arrowhead": 0,
          "arrowwidth": 1
        },
        "geo": {
          "bgcolor": "white",
          "landcolor": "#E5ECF6",
          "subunitcolor": "white",
          "showland": true,
          "showlakes": true,
          "lakecolor": "white"
        },
        "title": {
          "x": 0.05
        },
        "mapbox": {
          "style": "light"
        }
      }
    },
    "scene": {
      "domain": {
        "x": [
          0,
          1
        ],
        "y": [
          0,
          1
        ]
      },
      "xaxis": {
        "title": {
          "text": "0"
        }
      },
      "yaxis": {
        "title": {
          "text": "1"
        }
      },
      "zaxis": {
        "title": {
          "text": "2"
        }
      }
    },
    "legend": {
      "title": {
        "text": "species"
      },
      "tracegroupgap": 0
    },
    "margin": {
      "t": 60
    }
  }
}

export const const_test_numeric = {
  data: [
      {
          "fill": "tozeroy",
          "line": {
              "color": "#00008b",
              "shape": "spline"
          },
          "name": "Dataset1 Dataset",
          "x": [
              1000,
              1795.9183673469388,
              2000,
              2000,
              2591.8367346938776,
              2985.000000000001,
              3000,
              3387.7551020408164,
              3500,
              3500,
              4000,
              4000,
              4183.673469387755,
              4775,
              4800,
              4979.591836734694,
              5000,
              5000,
              5000,
              5100,
              5500,
              5775.510204081633,
              6000,
              6000,
              6000,
              6000,
              6500,
              6571.428571428572,
              6600,
              7000,
              7000,
              7367.34693877551,
              7500,
              7500,
              8000,
              8000,
              8000,
              8000,
              8163.265306122449,
              8600,
              8959.183673469388,
              9000,
              9200,
              9575,
              9755.102040816328,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10067.500000000007,
              10400,
              10551.020408163266,
              11000,
              11200,
              11346.938775510203,
              12000,
              12000,
              12000,
              12000,
              12000,
              12125,
              12125,
              12142.857142857143,
              12938.775510204083,
              13000,
              13000,
              13000,
              13734.69387755102,
              14000,
              14000,
              14500,
              14530.612244897959,
              14551.318469895881,
              14587.000000000035,
              14885.705850135213,
              15000,
              15000,
              15000,
              15000,
              15326.530612244898,
              15575,
              15600,
              16000,
              16000,
              16122.448979591838,
              16800,
              16800,
              16918.367346938776,
              17714.285714285714,
              18000,
              18000,
              18000,
              18187.500000000022,
              18510.204081632655,
              19306.122448979593,
              19585.000000000036,
              19950,
              20000,
              20000,
              20000,
              20000,
              20102.04081632653,
              20800,
              20897.95918367347,
              21000,
              21509.000000000015,
              21693.877551020407,
              22100,
              22489.79591836735,
              23100,
              23285.714285714286,
              24000,
              24000,
              24000,
              24081.632653061224,
              24877.551020408166,
              24975,
              25000,
              25000,
              25673.469387755104,
              26469.38775510204,
              27000,
              27265.30612244898,
              27600,
              28000,
              28061.224489795917,
              28500,
              28857.14285714286,
              29653.061224489797,
              30000,
              30000,
              30448.979591836734,
              31244.897959183676,
              32000,
              32040.816326530614,
              32200,
              32836.73469387755,
              33632.65306122449,
              34428.57142857143,
              35000,
              35000,
              35000,
              35000,
              35224.489795918365,
              36020.40816326531,
              36816.32653061225,
              37612.244897959186,
              38408.163265306124,
              39204.08163265306,
              40000
          ],
          "y": [
              0.000014178268937245307,
              0.00001941043773549561,
              0.000020826143499363397,
              0.000020826143499363397,
              0.000024997273361762213,
              0.000027752481922197255,
              0.000027856417683074622,
              0.000030494612083158328,
              0.00003123672319517708,
              0.00003123672319517708,
              0.00003438433550353276,
              0.00003438433550353276,
              0.00003546407756301701,
              0.00003860696669405834,
              0.000038727819195864474,
              0.000039566015834803046,
              0.00003965791281300741,
              0.00003965791281300741,
              0.00003965791281300741,
              0.00004009825211759627,
              0.00004169400413668969,
              0.0000426411567962031,
              0.0000433247272900552,
              0.0000433247272900552,
              0.0000433247272900552,
              0.0000433247272900552,
              0.000044582791528052826,
              0.000044734876316603255,
              0.00004479389723795791,
              0.00004551753595396356,
              0.00004551753595396356,
              0.00004602836866480536,
              0.000046180201591901594,
              0.000046180201591901594,
              0.00004661021297238,
              0.00004661021297238,
              0.00004661021297238,
              0.00004661021297238,
              0.000046704107700807425,
              0.000046845000971979065,
              0.00004683820221333633,
              0.000046830210535218115,
              0.00004676923104778639,
              0.00004655524519060047,
              0.000046405648501864466,
              0.000046153793174947296,
              0.000046153793174947296,
              0.000046153793174947296,
              0.000046153793174947296,
              0.000046153793174947296,
              0.000046153793174947296,
              0.00004607473800408173,
              0.000045627232315868124,
              0.000045393943035892516,
              0.000044605634732535064,
              0.00004421727568296717,
              0.00004392107592664851,
              0.000042544512117511874,
              0.000042544512117511874,
              0.000042544512117511874,
              0.000042544512117511874,
              0.000042544512117511874,
              0.00004227829218971405,
              0.00004227829218971405,
              0.00004224036118623541,
              0.00004060991525395611,
              0.00004049095715219725,
              0.00004049095715219725,
              0.00004049095715219725,
              0.00003913209605848251,
              0.00003866077736472974,
              0.00003866077736472974,
              0.00003776659797883138,
              0.000037710795918728185,
              0.000037672949032475484,
              0.00003760752810499163,
              0.00003704829357945614,
              0.00003682811410797229,
              0.00003682811410797229,
              0.00003682811410797229,
              0.00003682811410797229,
              0.00003617821201135908,
              0.00003566337104344816,
              0.000035610683174443415,
              0.00003475049978218288,
              0.00003475049978218288,
              0.00003448271881314567,
              0.000033009543572732516,
              0.000033009543572732516,
              0.00003276023694863167,
              0.00003121577326428276,
              0.00003072650289841334,
              0.00003072650289841334,
              0.00003072650289841334,
              0.000030423083733667413,
              0.000029927495293007044,
              0.000028766199498536772,
              0.000028346947440018173,
              0.00002776634148126056,
              0.000027683409931405958,
              0.000027683409931405958,
              0.000027683409931405958,
              0.000027683409931405958,
              0.000027511443097717085,
              0.00002624046441669129,
              0.000026050775856917127,
              0.000025851070463579914,
              0.00002483653053546108,
              0.000024467122258553197,
              0.000023669706170839194,
              0.00002293505166337108,
              0.000021855358514155022,
              0.000021540545990345082,
              0.000020347476834214265,
              0.000020347476834214265,
              0.000020347476834214265,
              0.000020209705689496208,
              0.000018809588814702212,
              0.00001862997400668068,
              0.000018583621350836798,
              0.000018583621350836798,
              0.00001730538915288153,
              0.000015811575907082797,
              0.00001490656084996025,
              0.00001449564480142337,
              0.00001402036857711269,
              0.00001351311022198357,
              0.000013440814650803547,
              0.000012957755319192811,
              0.000012601084894557362,
              0.000011882285988480297,
              0.000011597222742955026,
              0.000011597222742955026,
              0.00001126501666524074,
              0.000010849544302102007,
              0.000010762279895349078,
              0.000010767034003428762,
              0.00001079425761301709,
              0.000011014318973488327,
              0.000011348656847968451,
              0.000011354704646835219,
              0.000010943188399536574,
              0.000010943188399536574,
              0.000010943188399536574,
              0.000010943188399536574,
              0.000010667352968070368,
              0.000009197877063996259,
              0.000007194047638963688,
              0.00000509419821904637,
              0.0000032937380477381705,
              0.0000019872997820182034,
              0.0000011599826050910222
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      },
      {
          "line": {
              "color": "#00008b",
              "dash": "dash"
          },
          "mode": "lines+markers",
          "name": "Dataset1 Mean",
          "x": [
              14885.705850135213,
              14885.705850135213
          ],
          "y": [
              0,
              0.00003704829357945614
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      },
      {
          "line": {
              "color": "#00008b"
          },
          "mode": "lines",
          "name": "Dataset1 Median",
          "x": [
              13000,
              13000
          ],
          "y": [
              0,
              0.00004049095715219725
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      },
      {
          "fill": "tozeroy",
          "line": {
              "color": "#69b3a2",
              "shape": "spline"
          },
          "name": "Dataset2 Dataset",
          "x": [
              1000,
              1795.9183673469388,
              2000,
              2000,
              2591.8367346938776,
              2985.000000000001,
              3000,
              3387.7551020408164,
              3500,
              3500,
              4000,
              4000,
              4183.673469387755,
              4775,
              4800,
              4979.591836734694,
              5000,
              5000,
              5000,
              5100,
              5500,
              5775.510204081633,
              6000,
              6000,
              6000,
              6000,
              6500,
              6571.428571428572,
              6600,
              7000,
              7000,
              7367.34693877551,
              7500,
              7500,
              8000,
              8000,
              8000,
              8000,
              8163.265306122449,
              8600,
              8959.183673469388,
              9000,
              9200,
              9575,
              9755.102040816328,
              10000,
              10000,
              10000,
              10000,
              10000,
              10000,
              10067.500000000007,
              10400,
              10551.020408163266,
              11000,
              11200,
              11346.938775510203,
              12000,
              12000,
              12000,
              12000,
              12000,
              12125,
              12125,
              12142.857142857143,
              12938.775510204083,
              13000,
              13000,
              13000,
              13734.69387755102,
              14000,
              14000,
              14500,
              14530.612244897959,
              14551.318469895881,
              14587.000000000035,
              14885.705850135213,
              15000,
              15000,
              15000,
              15000,
              15326.530612244898,
              15575,
              15600,
              16000,
              16000,
              16122.448979591838,
              16800,
              16800,
              16918.367346938776,
              17714.285714285714,
              18000,
              18000,
              18000,
              18187.500000000022,
              18510.204081632655,
              19306.122448979593,
              19585.000000000036,
              19950,
              20000,
              20000,
              20000,
              20000,
              20102.04081632653,
              20800,
              20897.95918367347,
              21000,
              21509.000000000015,
              21693.877551020407,
              22100,
              22489.79591836735,
              23100,
              23285.714285714286,
              24000,
              24000,
              24000,
              24081.632653061224,
              24877.551020408166,
              24975,
              25000,
              25000,
              25673.469387755104,
              26469.38775510204,
              27000,
              27265.30612244898,
              27600,
              28000,
              28061.224489795917,
              28500,
              28857.14285714286,
              29653.061224489797,
              30000,
              30000,
              30448.979591836734,
              31244.897959183676,
              32000,
              32040.816326530614,
              32200,
              32836.73469387755,
              33632.65306122449,
              34428.57142857143,
              35000,
              35000,
              35000,
              35000,
              35224.489795918365,
              36020.40816326531,
              36816.32653061225,
              37612.244897959186,
              38408.163265306124,
              39204.08163265306,
              40000
          ],
          "y": [
              0.000014850432361467795,
              0.00002053054335365177,
              0.000022084389462882192,
              0.000022084389462882192,
              0.000026698689975870842,
              0.000029771139019338586,
              0.000029887339981374288,
              0.00003284199029952414,
              0.000033674321529185184,
              0.000033674321529185184,
              0.00003720354387851216,
              0.00003720354387851216,
              0.00003841102063872208,
              0.00004189721816429313,
              0.00004202987960104483,
              0.000042945581974302425,
              0.00004304544887972335,
              0.00004304544887972335,
              0.00004304544887972335,
              0.000043522282920362045,
              0.00004521941763403531,
              0.000046192629087527026,
              0.000046870023286640584,
              0.000046870023286640584,
              0.000046870023286640584,
              0.000046870023286640584,
              0.00004802513463885812,
              0.00004815258853986766,
              0.000048201076840276446,
              0.00004873761398035352,
              0.00004873761398035352,
              0.000049015494315623235,
              0.00004907018295194309,
              0.00004907018295194309,
              0.00004908127039624811,
              0.00004908127039624811,
              0.00004908127039624811,
              0.00004908127039624811,
              0.000049023307640447366,
              0.00004873294603182813,
              0.00004835624215239055,
              0.00004830597449622878,
              0.000048038483397206564,
              0.0000474462681556979,
              0.000047122523388511026,
              0.000046645070956058376,
              0.000046645070956058376,
              0.000046645070956058376,
              0.000046645070956058376,
              0.000046645070956058376,
              0.000046645070956058376,
              0.000046506484975341656,
              0.00004578513769247447,
              0.00004543915646043798,
              0.000044363350986617796,
              0.00004387064694483101,
              0.00004350708953467492,
              0.000041920263115879363,
              0.000041920263115879363,
              0.000041920263115879363,
              0.000041920263115879363,
              0.000041920263115879363,
              0.00004162862591543056,
              0.00004162862591543056,
              0.00004158740262996076,
              0.00003987816218398821,
              0.00003975724670222969,
              0.00003975724670222969,
              0.00003975724670222969,
              0.000038394178696602375,
              0.00003792365557232055,
              0.00003792365557232055,
              0.0000370247367120037,
              0.000036968239172828165,
              0.00003692989053035216,
              0.000036863544482176376,
              0.00003629335909360123,
              0.00003606737155210371,
              0.00003606737155210371,
              0.00003606737155210371,
              0.00003606737155210371,
              0.000035395546075272446,
              0.00003485845807429695,
              0.000034803255759047525,
              0.00003389574807028593,
              0.00003389574807028593,
              0.000033610751435868524,
              0.00003201870478441691,
              0.00003201870478441691,
              0.000031744613897420853,
              0.000030008261363953424,
              0.000029443345208024007,
              0.000029443345208024007,
              0.000029443345208024007,
              0.00002909002683302961,
              0.00002851054880422379,
              0.000027175899783668936,
              0.00002671438771607045,
              0.00002609664712781466,
              0.000026010283372272848,
              0.000026010283372272848,
              0.000026010283372272848,
              0.000026010283372272848,
              0.00002583254107833959,
              0.000024562713131298486,
              0.000024378030680448443,
              0.000024184523928032426,
              0.000023211908236271823,
              0.00002286044136779949,
              0.000022103399516268932,
              0.000021405603564964385,
              0.00002037744648848414,
              0.000020077609795036807,
              0.000018949154764336286,
              0.000018949154764336286,
              0.000018949154764336286,
              0.00001882025177331877,
              0.000017533089530484903,
              0.000017370968838318922,
              0.000017329233953724886,
              0.000017329233953724886,
              0.00001619309643158013,
              0.00001489268639107448,
              0.000014113276442807373,
              0.000013760037088730498,
              0.000013350710527873856,
              0.000012910900919063181,
              0.000012847784391437598,
              0.000012421595367402495,
              0.000012099970845973989,
              0.000011424880864497103,
              0.000011143871526188503,
              0.000011143871526188503,
              0.000010801831361373295,
              0.000010319539750341841,
              0.000010103664418816111,
              0.000010099770498424367,
              0.000010091882015461494,
              0.00001015658132323148,
              0.000010306460955646533,
              0.000010221537407143242,
              0.000009845698342015857,
              0.000009845698342015857,
              0.000009845698342015857,
              0.000009845698342015857,
              0.000009610843075254428,
              0.000008404086693288842,
              0.000006799336129419653,
              0.000005141326211658802,
              0.000003728268234934483,
              0.000002685323787694502,
              0.000001968969933676703
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      },
      {
          "line": {
              "color": "#69b3a2",
              "dash": "dash"
          },
          "mode": "lines+markers",
          "name": "Dataset2 Mean",
          "x": [
              14551.318469895881,
              14551.318469895881
          ],
          "y": [
              0,
              0.00003692989053035216
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      },
      {
          "line": {
              "color": "#69b3a2"
          },
          "mode": "lines",
          "name": "Dataset2 Median",
          "x": [
              12125,
              12125
          ],
          "y": [
              0,
              0.00004162862591543056
          ],
          "type": "scatter",
          "xaxis": "x2",
          "yaxis": "y2"
      }
  ],
  "layout": {
      "xaxis": {
          "anchor": "y",
          "domain": [
              0,
              1
          ],
          "showgrid": false,
          "gridcolor": "black",
          "linecolor": "black",
          "range": [
              0,
              0.4
          ],
          "dtick": 0.05,
          "fixedrange": true
      },
      "yaxis": {
          "anchor": "x",
          "domain": [
              0.9200000000000002,
              1
          ],
          "showgrid": false,
          "showline": false,
          "showticklabels": false,
          "zeroline": false,
          "color": "black",
          "autorange": true,
          "rangemode": "normal",
          "fixedrange": true
      },
      "xaxis2": {
          "anchor": "y2",
          "domain": [
              0,
              1
          ],
          "fixedrange": false,
          "range": [
              2000,
              35000
          ],
          "title": {
              "text": "features.loan_amnt"
          }
      },
      "yaxis2": {
          "anchor": "x2",
          // "domain": [
          //     0,
          //     0.7200000000000001
          // ],
          "title": {
              "text": "Probability Density"
          },
          "fixedrange": true
      },
      // "legend": {
      //     "title": {
      //         "text": "Legend"
      //     },
      //     "yanchor": "top",
      //     "y": 0.6
      // },
      "height": 400,
      "bargroupgap": 0,
      "showlegend": false
  }
};

// export const const_test_numeric =
// {
//                 "data": [
//                     {
//                         "fill": "tozeroy",
//                         "line": {
//                             "color": "#00008b",
//                             "shape": "spline"
//                         },
//                         "name": "Prod1 Dataset",
//                         "x": [
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30204.081632653062,
//                             30408.163265306124,
//                             30612.244897959183,
//                             30625.0,
//                             30816.326530612245,
//                             31020.408163265307,
//                             31175.0,
//                             31224.48979591837,
//                             31428.571428571428,
//                             31632.65306122449,
//                             31836.73469387755,
//                             31895.00000000003,
//                             32000.0,
//                             32000.0,
//                             32040.816326530614,
//                             32244.897959183672,
//                             32448.979591836734,
//                             32475.0,
//                             32653.061224489797,
//                             32857.142857142855,
//                             33000.0,
//                             33061.22448979592,
//                             33265.30612244898,
//                             33298.56536610083,
//                             33469.38775510204,
//                             33575.0,
//                             33673.4693877551,
//                             33877.551020408166,
//                             34000.0,
//                             34081.63265306123,
//                             34285.71428571429,
//                             34489.795918367345,
//                             34693.87755102041,
//                             34800.0,
//                             34897.95918367347,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35073.970655141035,
//                             35102.04081632653,
//                             35306.12244897959,
//                             35510.204081632655,
//                             35714.28571428572,
//                             35918.36734693877,
//                             36122.448979591834,
//                             36326.5306122449,
//                             36530.61224489796,
//                             36734.69387755102,
//                             36938.77551020408,
//                             37142.857142857145,
//                             37346.93877551021,
//                             37551.02040816327,
//                             37755.102040816324,
//                             37959.183673469386,
//                             38163.26530612245,
//                             38367.34693877551,
//                             38571.42857142857,
//                             38775.510204081635,
//                             38979.5918367347,
//                             39183.67346938775,
//                             39387.755102040814,
//                             39591.836734693876,
//                             39795.91836734694,
//                             40000.0
//                         ],
//                         "y": [
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             3.5646321802060945e-259,
//                             1.0319814686871365e-238,
//                             4.195271048863496e-219,
//                             2.3948543319509023e-200,
//                             3.336211599744957e-199,
//                             1.9196812842101365e-182,
//                             2.1607778048428315e-165,
//                             4.825207013235217e-153,
//                             3.415246387597936e-149,
//                             7.579925237570444e-134,
//                             2.3623201511994585e-119,
//                             1.0338166875708165e-105,
//                             5.656244365720907e-102,
//                             2.0581529681737555e-95,
//                             2.0581529681737555e-95,
//                             6.353006105407278e-93,
//                             5.48208743975972e-81,
//                             6.642684028419398e-70,
//                             1.4934673374664733e-68,
//                             1.1302439280065012e-59,
//                             2.700422520823051e-50,
//                             3.082725211055596e-44,
//                             9.059875109054359e-42,
//                             4.268189093091734e-34,
//                             6.3087158672243e-33,
//                             2.823553645542381e-27,
//                             4.422501662326555e-24,
//                             2.622885826224968e-21,
//                             3.421319873265557e-16,
//                             1.5639004796625333e-13,
//                             6.2667002929033684e-12,
//                             1.611814731579677e-08,
//                             5.821330648852002e-06,
//                             0.0002952299178816169,
//                             0.0010468799675764334,
//                             0.0021024681564811285,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002687204374256091,
//                             0.002362101611455128,
//                             0.0021024772924089037,
//                             0.0002954317824275155,
//                             6.498812170618454e-06,
//                             1.8984827617247916e-06,
//                             8.16637311217071e-06,
//                             7.133446318063179e-06,
//                             2.0376400903718527e-06,
//                             9.960250705467098e-07,
//                             1.0635662627106188e-06,
//                             1.4832762116700463e-06,
//                             8.269072311008995e-07,
//                             4.320282100371202e-07,
//                             5.869496675395437e-07,
//                             1.3548467956191387e-07,
//                             6.371424140453007e-08,
//                             4.642564617070174e-07,
//                             5.096085168254976e-07,
//                             7.855333954045821e-08,
//                             1.7029374351658768e-09,
//                             1.061637676855379e-09,
//                             6.010416821276785e-08,
//                             5.307706691306921e-07,
//                             1.683905177743943e-06,
//                             1.3888669631541282e-05,
//                             3.5590129903590234e-05
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2"
//                     },
//                     {
//                         "line": {
//                             "color": "#00008b",
//                             "dash": "dash"
//                         },
//                         "mode": "lines+markers",
//                         "name": "Prod1 Mean",
//                         "x": [
//                             35073.970655141035,
//                             35073.970655141035
//                         ],
//                         "y": [
//                             0,
//                             0.002362101611455128
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2",
//                         "showlegend": false
//                     },
//                     {
//                         "line": {
//                             "color": "#00008b"
//                         },
//                         "mode": "lines",
//                         "name": "Prod1 Median",
//                         "x": [
//                             35000.0,
//                             35000.0
//                         ],
//                         "y": [
//                             0,
//                             0.002687204374256091
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2"
//                     },
//                     {
//                         "fill": "tozeroy",
//                         "line": {
//                             "color": "#69b3a2",
//                             "shape": "spline"
//                         },
//                         "name": "Prod2 Dataset",
//                         "x": [
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30000.0,
//                             30204.081632653062,
//                             30408.163265306124,
//                             30612.244897959183,
//                             30625.0,
//                             30816.326530612245,
//                             31020.408163265307,
//                             31175.0,
//                             31224.48979591837,
//                             31428.571428571428,
//                             31632.65306122449,
//                             31836.73469387755,
//                             31895.00000000003,
//                             32000.0,
//                             32000.0,
//                             32040.816326530614,
//                             32244.897959183672,
//                             32448.979591836734,
//                             32475.0,
//                             32653.061224489797,
//                             32857.142857142855,
//                             33000.0,
//                             33061.22448979592,
//                             33265.30612244898,
//                             33298.56536610083,
//                             33469.38775510204,
//                             33575.0,
//                             33673.4693877551,
//                             33877.551020408166,
//                             34000.0,
//                             34081.63265306123,
//                             34285.71428571429,
//                             34489.795918367345,
//                             34693.87755102041,
//                             34800.0,
//                             34897.95918367347,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35000.0,
//                             35073.970655141035,
//                             35102.04081632653,
//                             35306.12244897959,
//                             35510.204081632655,
//                             35714.28571428572,
//                             35918.36734693877,
//                             36122.448979591834,
//                             36326.5306122449,
//                             36530.61224489796,
//                             36734.69387755102,
//                             36938.77551020408,
//                             37142.857142857145,
//                             37346.93877551021,
//                             37551.02040816327,
//                             37755.102040816324,
//                             37959.183673469386,
//                             38163.26530612245,
//                             38367.34693877551,
//                             38571.42857142857,
//                             38775.510204081635,
//                             38979.5918367347,
//                             39183.67346938775,
//                             39387.755102040814,
//                             39591.836734693876,
//                             39795.91836734694,
//                             40000.0
//                         ],
//                         "y": [
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.00018701670271804388,
//                             0.0001809575868348343,
//                             0.0001569915311078945,
//                             0.00012455812415759318,
//                             0.0001224954684642913,
//                             9.385142976685075e-05,
//                             7.152712207227359e-05,
//                             6.146525340331665e-05,
//                             5.940958839800563e-05,
//                             5.570086285106147e-05,
//                             5.692486018845062e-05,
//                             5.946933845235082e-05,
//                             6.002183365967717e-05,
//                             6.0570429972921855e-05,
//                             6.0570429972921855e-05,
//                             6.059923001620534e-05,
//                             5.9061235776390486e-05,
//                             5.5159146121526024e-05,
//                             5.4553690883245454e-05,
//                             5.021608610419224e-05,
//                             4.574804031173941e-05,
//                             4.3551447751553645e-05,
//                             4.294283549783287e-05,
//                             4.286121804511594e-05,
//                             4.320929838105352e-05,
//                             4.73290390754551e-05,
//                             5.2540480713930706e-05,
//                             5.9997014103446745e-05,
//                             8.653243448756316e-05,
//                             0.00011161036870156079,
//                             0.00013265528144887578,
//                             0.00019960018419483733,
//                             0.0002790398209067967,
//                             0.00035191815782462197,
//                             0.00037926378422007366,
//                             0.0003946874185255276,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003990720201000377,
//                             0.0003944327689932408,
//                             0.00039097572888881465,
//                             0.0003410109416684116,
//                             0.000261560945921911,
//                             0.00017643384303621596,
//                             0.00010481199368290276,
//                             5.5039456650673336e-05,
//                             2.5766544228789598e-05,
//                             1.0961324842361408e-05,
//                             4.41984729544233e-06,
//                             1.8337053486914794e-06,
//                             8.746861502480331e-07,
//                             5.091004663875341e-07,
//                             3.4731183049558804e-07,
//                             2.607822515498891e-07,
//                             2.115279804138084e-07,
//                             1.9331927172235337e-07,
//                             2.208536510026966e-07,
//                             3.3574894555843867e-07,
//                             6.101780102856977e-07,
//                             1.1302876008863648e-06,
//                             1.947649541988492e-06,
//                             3.010236506038046e-06,
//                             4.118634992690392e-06,
//                             4.964279688627868e-06,
//                             5.260678014987974e-06
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2"
//                     },
//                     {
//                         "line": {
//                             "color": "#69b3a2",
//                             "dash": "dash"
//                         },
//                         "mode": "lines+markers",
//                         "name": "Prod2 Mean",
//                         "x": [
//                             33298.56536610083,
//                             33298.56536610083
//                         ],
//                         "y": [
//                             0,
//                             4.320929838105352e-05
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2"
//                     },
//                     {
//                         "line": {
//                             "color": "#69b3a2"
//                         },
//                         "mode": "lines",
//                         "name": "Prod2 Median",
//                         "x": [
//                             35000.0,
//                             35000.0
//                         ],
//                         "y": [
//                             0,
//                             0.0003990720201000377
//                         ],
//                         "type": "scatter",
//                         "xaxis": "x2",
//                         "yaxis": "y2"
//                     }
//                 ],
//                 "layout": {
//                     "xaxis": {
//                         "anchor": "y",
//                         "domain": [
//                             0.0,
//                             1.0
//                         ],
//                         "showgrid": false,
//                         "gridcolor": "black",
//                         "linecolor": "black",
//                         "range": [
//                             0,
//                             0.45222890795032644
//                         ],
//                         "dtick": 0.05,
//                         "fixedrange": true
//                     },
//                     "yaxis": {
//                         "anchor": "x",
//                         "domain": [
//                             0.9200000000000002,
//                             1.0
//                         ],
//                         "showgrid": false,
//                         "showline": false,
//                         "showticklabels": false,
//                         "zeroline": false,
//                         "color": "black",
//                         "autorange": true,
//                         "rangemode": "normal",
//                         "fixedrange": true
//                     },
//                     "xaxis2": {
//                         "anchor": "y2",
//                         "domain": [
//                             0.0,
//                             1.0
//                         ],
//                         "fixedrange": false,
//                         "range": [
//                             30000.0,
//                             35000.0
//                         ],
//                         "title": {
//                             "text": "features.loan_amnt"
//                         }
//                     },
//                     "yaxis2": {
//                         "anchor": "x2",
//                         "domain": [
//                             0.0,
//                             0.7200000000000001
//                         ],
//                         "title": {
//                             "text": "Probability Density"
//                         },
//                         "fixedrange": true
//                     },
//                     "height": 400,
//                     "bargroupgap": 0,
//                     "legend": {
//                         "tracegroupgap": 0,
//                         "orientation": "h",
//                         "yanchor": "bottom",
//                         "y": 1.02,
//                         "xanchor": "right",
//                         "x": 1
//                     }
//                 }
//             }

export const const_test_string = {
                "data": [
                    {
                        "marker": {
                            "color": "#00008b"
                        },
                        "name": "Prod1 Dataset",
                        "x": [
                            "A3",
                            "A4",
                            "B1",
                            "B2",
                            "C3",
                            "D2",
                            "D3",
                            "D4",
                            "E1",
                            "F1"
                        ],
                        "y": [
                            0.0018198362147406734,
                            0.012283894449499545,
                            0.01592356687898089,
                            0.02684258416742493,
                            0.0632393084622384,
                            0.03184713375796178,
                            0.04253867151956324,
                            0.055505004549590536,
                            0.053230209281164696,
                            0.029799818016378526
                        ],
                        "type": "bar",
                        "xaxis": "x2",
                        "yaxis": "y2"
                    },
                    {
                        "marker": {
                            "color": "#69b3a2"
                        },
                        "name": "Prod2 Dataset",
                        "x": [
                            "A3",
                            "A4",
                            "B1",
                            "B2",
                            "C3",
                            "D2",
                            "D3",
                            "D4",
                            "E1",
                            "F1"
                        ],
                        "y": [
                            0.005953889029642767,
                            0.015581454269065112,
                            0.019761844438814288,
                            0.03166962249809982,
                            0.057258677476564476,
                            0.03483658474790981,
                            0.03889029642766658,
                            0.051051431466936914,
                            0.046744362807195336,
                            0.025462376488472258
                        ],
                        "type": "bar",
                        "xaxis": "x2",
                        "yaxis": "y2"
                    }
                ],
                "layout": {
                    "xaxis": {
                        "anchor": "y",
                        "domain": [
                            0.0,
                            1.0
                        ],
                        "showgrid": false,
                        "gridcolor": "black",
                        "linecolor": "black",
                        "range": [
                            0,
                            0.4
                        ],
                        "dtick": 0.05,
                        "fixedrange": true
                    },
                    "yaxis": {
                        "anchor": "x",
                        "domain": [
                            0.9200000000000002,
                            1.0
                        ],
                        "showgrid": false,
                        "showline": false,
                        "showticklabels": false,
                        "zeroline": false,
                        "color": "black",
                        "autorange": true,
                        "rangemode": "normal",
                        "fixedrange": true
                    },
                    "xaxis2": {
                        "anchor": "y2",
                        "domain": [
                            0.0,
                            1.0
                        ],
                        "type": "category",
                        "range": [
                            -3,
                            12
                        ]
                    },
                    "yaxis2": {
                        "anchor": "x2",
                        "domain": [
                            0.0,
                            0.7200000000000001
                        ],
                        "fixedrange": true,
                        "autorange": true,
                        "rangemode": "normal",
                        "title": {
                            "text": "Frequency"
                        }
                    },
                    "legend": {
                        "title": {
                            "text": "Legend"
                        },
                        "yanchor": "top",
                        "y": 0.6
                    },
                    "bargroupgap": 0,
                    "showlegend": false,
                    "margin": {
                        "t": 10,
                        "pad": 4
                    },
                    "automargin": true,
                    "height": 250,
                    "width": 0
                }
            }

export const const_plot_drift = {
  data: [
    {
      base: 0,
      marker: {color: '#01B8AA'},
      offsetgroup: '0',
      orientation: 'h',
      showlegend: false,
      x: [0.1],
      y: ['Drift Score'],
      type: 'bar',
      xaxis: 'x',
      yaxis: 'y',
    },
    {
      base: 0.1,
      marker: {color: '#F2C80F'},
      offsetgroup: '0',
      orientation: 'h',
      showlegend: false,
      x: [0.1],
      y: ['Drift Score'],
      type: 'bar',
      xaxis: 'x',
      yaxis: 'y',
    },
    {
      base: 0.2,
      marker: {color: '#FE9666'},
      offsetgroup: '0',
      orientation: 'h',
      showlegend: false,
      x: [0.09999999999999998],
      y: ['Drift Score'],
      type: 'bar',
      xaxis: 'x',
      yaxis: 'y',
    },
    {
      base: 0.3,
      marker: {color: '#FD625E'},
      offsetgroup: '0',
      orientation: 'h',
      showlegend: false,
      x: [0.043086734931430615],
      y: ['Drift Score'],
      type: 'bar',
      xaxis: 'x',
      yaxis: 'y',
    },
    {
      fill: 'tozeroy',
      line: {color: '#00008b', shape: 'spline'},
      name: 'Prod Dataset',
      x: [
        -2.9403886346642802, -2.718764792449416, -2.497140950234552, -2.275517108019687,
        -2.053893265804823, -1.9522394460096473, -1.8322694235899588, -1.6615589392996284,
        -1.6106455813750946, -1.5029806858785304, -1.3890217391602302, -1.3803173200348469,
        -1.2481162214068948, -1.167397896945366, -1.129130381230076, -1.0266776772231458,
        -0.9457740547305018, -0.93815379934108, -0.8579003525004392, -0.7984761969608137,
        -0.7474921477777441, -0.7241502125156374, -0.6772945894897103, -0.6011966376436214,
        -0.5480704382650716, -0.5025263703007732, -0.4932276292095767, -0.4301142680349475,
        -0.3735089549474293, -0.3232243983583615, -0.303807755374735, -0.28338806180795656,
        -0.280902528085909, -0.22468605075528816, -0.17928677247888014, -0.11908608725458732,
        -0.05927868587104479, -0.05849581418656411, -0.011678968276559606, -0.005733163465857652,
        0.047741808988898, 0.047741808988898, 0.056982148184147645, 0.11646795382050189,
        0.16234515634381985, 0.18390795615138109, 0.21818855315277805, 0.2790056549133476,
        0.2886118397580669, 0.33072228829686745, 0.38396899855868405, 0.38778229748484644,
        0.4275854276244711, 0.4275949465926318, 0.47293576480857014, 0.510120350227489,
        0.5659159846146, 0.5670970501705798, 0.6055928407735482, 0.628704842408893,
        0.6826838956553363, 0.7516331603456333, 0.7524990239972061, 0.8230642682006,
        0.8272166829884124, 0.8953985144339989, 0.9642044459328458, 0.9730927715759433,
        1.0488405252032766, 1.0662275531855239, 1.1073187703133764, 1.140421128619949,
        1.1978395382807978, 1.2390074032574945, 1.2704643674181413, 1.3494647541824527,
        1.3737364999637436, 1.480971102107083, 1.4920882096330055, 1.501546398885663,
        1.5873777550732753, 1.677554135964612, 1.6950933580121719, 1.7137120518478697,
        1.7742207758610855, 1.84693016008695, 1.8715504167171473, 1.9353358940627339,
        1.941993068984651, 2.0267131748094562, 2.107895708909522, 2.156959736277598,
        2.1632896757373725, 2.1794221433146435, 2.2709567507472617, 2.3785835784924623,
        2.4068880846144673, 2.512055769620731, 2.595100472364528, 2.6002074207073265,
        2.678735695029947, 2.762513380478547, 2.762513380478547, 2.779575791784628,
        2.8218312629221907, 2.8427777482701817, 2.9340819924380366, 3.040325509518532,
        3.0434551051370557, 3.1031715268077535, 3.2030356398684625, 3.26507894735192,
        3.2784379466437734, 3.3389618203131866, 3.4315085799413954, 3.486702789566784,
        3.5443656413223157, 3.6621240001387116, 3.7083266317816483, 3.744742002928959,
        3.8473823263819082, 3.9299504739965125, 3.939851984425977, 4.0363390448695435,
        4.151574316211377, 4.152479814842194, 4.3324684159383855, 4.373198158426241,
        4.464560454102148, 4.594822000641106, 4.631994625050779, 4.8041082481264175,
        4.81644584285597, 4.9702645084171895, 5.038069685070834, 5.188747400317569,
        5.259693527285698, 5.420162820651218, 5.481317369500562, 5.702941211715427,
        5.703694340290479, 5.924565053930291, 6.146188896145155, 6.176979021411623,
        6.367812738360019, 6.589436580574883, 6.8110604227897475, 7.032684265004612,
        7.254308107219476, 7.47593194943434, 7.697555791649204, 7.9191796338640685,
      ],
      y: [
        8.64356451e-3, 9.7313599e-3, 1.22911148e-2, 2.38028798e-2, 4.61743456e-2, 5.95652947e-2,
        7.75241344e-2, 1.06166414e-1, 1.15075997e-1, 1.33878698e-1, 1.53441392e-1, 1.5493261e-1,
        1.78296618e-1, 1.94003124e-1, 2.02021056e-1, 2.25377189e-1, 2.45326453e-1, 2.47243449e-1,
        2.67464812e-1, 2.82097779e-1, 2.9412305e-1, 2.99412301e-1, 3.09546476e-1, 3.24432907e-1,
        3.33527722e-1, 3.40394057e-1, 3.41685284e-1, 3.4941706e-1, 3.5478498e-1, 3.58323171e-1,
        3.59394768e-1, 3.60356204e-1, 3.60462138e-1, 3.62280499e-1, 3.63087454e-1, 3.63610861e-1,
        3.6396274e-1, 3.63968273e-1, 3.64412377e-1, 3.64488501e-1, 3.65422183e-1, 3.65422183e-1,
        3.65630952e-1, 3.67276587e-1, 3.68779849e-1, 3.69494153e-1, 3.70550379e-1, 3.71818859e-1,
        3.71907712e-1, 3.71797741e-1, 3.70218193e-1, 3.70033567e-1, 3.67478303e-1, 3.67477551e-1,
        3.63099758e-1, 3.58318316e-1, 3.49219749e-1, 3.49003879e-1, 3.41494677e-1, 3.3658609e-1,
        3.24194684e-1, 3.07130476e-1, 3.06911149e-1, 2.8891876e-1, 2.87860808e-1, 2.70679607e-1,
        2.53868533e-1, 2.5174047e-1, 2.33988185e-1, 2.30003249e-1, 2.20706968e-1, 2.13338519e-1,
        2.00820093e-1, 1.92072359e-1, 1.85535414e-1, 1.69779786e-1, 1.65151402e-1, 1.45995748e-1,
        1.44128985e-1, 1.42557447e-1, 1.28929745e-1, 1.15553297e-1, 1.13024601e-1, 1.10357846e-1,
        1.01781389e-1, 9.15973709e-2, 8.81786648e-2, 7.94366488e-2, 7.85375217e-2, 6.74406566e-2,
        5.76704831e-2, 5.23091195e-2, 5.16504677e-2, 5.00069804e-2, 4.16435731e-2, 3.37246781e-2,
        3.19183016e-2, 2.58684391e-2, 2.15385272e-2, 2.12804584e-2, 1.7428859e-2, 1.36511605e-2,
        1.36511605e-2, 1.29418207e-2, 1.12958004e-2, 1.05441674e-2, 7.81783513e-3, 5.73083329e-3,
        5.6841673e-3, 4.91318432e-3, 3.95340158e-3, 3.44872592e-3, 3.34433279e-3, 2.88894008e-3,
        2.28230309e-3, 2.00621406e-3, 1.80595887e-3, 1.65928782e-3, 1.66412255e-3, 1.67478386e-3,
        1.66010324e-3, 1.53634323e-3, 1.51360153e-3, 1.21936469e-3, 7.87598106e-4, 7.84272949e-4,
        2.63300962e-4, 1.91868627e-4, 8.59096603e-5, 2.18370288e-5, 1.40752283e-5, 1.39263254e-6,
        1.15917141e-6, 9.64784363e-8, 2.86942814e-8, 1.50130326e-9, 3.31229981e-10, 8.1347909e-12,
        1.7829938e-12, 4.47564737e-15, 4.37980337e-15, 5.23899182e-18, 2.85973248e-21,
        9.47974405e-22, 7.27928836e-25, 8.64048794e-29, 4.78270332e-33, 1.23450952e-37,
        1.48594001e-42, 8.34053551e-48, 2.18309394e-53, 2.66462895e-59,
      ],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      line: {color: '#00008b', dash: 'dash'},
      mode: 'lines+markers',
      name: 'Prod Mean',
      x: [0.056982148184147645, 0.056982148184147645],
      y: [0, 0.3656309524593372],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      line: {color: '#00008b'},
      mode: 'lines',
      name: 'Prod Median',
      x: [0.047741808988898, 0.047741808988898],
      y: [0, 0.36542218254809605],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      fill: 'tozeroy',
      line: {color: '#69b3a2', shape: 'spline'},
      name: 'v0 Prod Dataset',
      x: [
        -2.9403886346642802, -2.718764792449416, -2.497140950234552, -2.275517108019687,
        -2.053893265804823, -1.9522394460096473, -1.8322694235899588, -1.6615589392996284,
        -1.6106455813750946, -1.5029806858785304, -1.3890217391602302, -1.3803173200348469,
        -1.2481162214068948, -1.167397896945366, -1.129130381230076, -1.0266776772231458,
        -0.9457740547305018, -0.93815379934108, -0.8579003525004392, -0.7984761969608137,
        -0.7474921477777441, -0.7241502125156374, -0.6772945894897103, -0.6011966376436214,
        -0.5480704382650716, -0.5025263703007732, -0.4932276292095767, -0.4301142680349475,
        -0.3735089549474293, -0.3232243983583615, -0.303807755374735, -0.28338806180795656,
        -0.280902528085909, -0.22468605075528816, -0.17928677247888014, -0.11908608725458732,
        -0.05927868587104479, -0.05849581418656411, -0.011678968276559606, -0.005733163465857652,
        0.047741808988898, 0.047741808988898, 0.056982148184147645, 0.11646795382050189,
        0.16234515634381985, 0.18390795615138109, 0.21818855315277805, 0.2790056549133476,
        0.2886118397580669, 0.33072228829686745, 0.38396899855868405, 0.38778229748484644,
        0.4275854276244711, 0.4275949465926318, 0.47293576480857014, 0.510120350227489,
        0.5659159846146, 0.5670970501705798, 0.6055928407735482, 0.628704842408893,
        0.6826838956553363, 0.7516331603456333, 0.7524990239972061, 0.8230642682006,
        0.8272166829884124, 0.8953985144339989, 0.9642044459328458, 0.9730927715759433,
        1.0488405252032766, 1.0662275531855239, 1.1073187703133764, 1.140421128619949,
        1.1978395382807978, 1.2390074032574945, 1.2704643674181413, 1.3494647541824527,
        1.3737364999637436, 1.480971102107083, 1.4920882096330055, 1.501546398885663,
        1.5873777550732753, 1.677554135964612, 1.6950933580121719, 1.7137120518478697,
        1.7742207758610855, 1.84693016008695, 1.8715504167171473, 1.9353358940627339,
        1.941993068984651, 2.0267131748094562, 2.107895708909522, 2.156959736277598,
        2.1632896757373725, 2.1794221433146435, 2.2709567507472617, 2.3785835784924623,
        2.4068880846144673, 2.512055769620731, 2.595100472364528, 2.6002074207073265,
        2.678735695029947, 2.762513380478547, 2.762513380478547, 2.779575791784628,
        2.8218312629221907, 2.8427777482701817, 2.9340819924380366, 3.040325509518532,
        3.0434551051370557, 3.1031715268077535, 3.2030356398684625, 3.26507894735192,
        3.2784379466437734, 3.3389618203131866, 3.4315085799413954, 3.486702789566784,
        3.5443656413223157, 3.6621240001387116, 3.7083266317816483, 3.744742002928959,
        3.8473823263819082, 3.9299504739965125, 3.939851984425977, 4.0363390448695435,
        4.151574316211377, 4.152479814842194, 4.3324684159383855, 4.373198158426241,
        4.464560454102148, 4.594822000641106, 4.631994625050779, 4.8041082481264175,
        4.81644584285597, 4.9702645084171895, 5.038069685070834, 5.188747400317569,
        5.259693527285698, 5.420162820651218, 5.481317369500562, 5.702941211715427,
        5.703694340290479, 5.924565053930291, 6.146188896145155, 6.176979021411623,
        6.367812738360019, 6.589436580574883, 6.8110604227897475, 7.032684265004612,
        7.254308107219476, 7.47593194943434, 7.697555791649204, 7.9191796338640685,
      ],
      y: [
        1.67933739e-5, 7.88873472e-5, 2.83015973e-4, 7.87071824e-4, 1.74386239e-3, 2.35590596e-3,
        3.2248999e-3, 4.76676934e-3, 5.30908272e-3, 6.61021829e-3, 8.25930777e-3, 8.39833367e-3,
        1.07653759e-2, 1.24657043e-2, 1.33447753e-2, 1.59450684e-2, 1.82721197e-2, 1.85046846e-2,
        2.11045941e-2, 2.32197641e-2, 2.51756013e-2, 2.61172927e-2, 2.81000248e-2, 3.15982435e-2,
        3.42560492e-2, 3.6681251e-2, 3.71933041e-2, 4.08211035e-2, 4.42992938e-2, 4.7562335e-2,
        4.88643417e-2, 5.02579535e-2, 5.04292559e-2, 5.43960062e-2, 5.77191045e-2, 6.22665174e-2,
        6.69100618e-2, 6.69714978e-2, 7.06679605e-2, 7.11399834e-2, 7.53987776e-2, 7.53987776e-2,
        7.61356526e-2, 8.08671125e-2, 8.44807647e-2, 8.61618615e-2, 8.88050567e-2, 9.33855319e-2,
        9.40944972e-2, 9.71507531e-2, 1.00888328e-1, 1.01150408e-1, 1.03841433e-1, 1.03842067e-1,
        1.0681105e-1, 1.09174713e-1, 1.12617641e-1, 1.12689361e-1, 1.15005795e-1, 1.16380199e-1,
        1.19562431e-1, 1.23625573e-1, 1.23676924e-1, 1.27920245e-1, 1.28174519e-1, 1.32449526e-1,
        1.36997255e-1, 1.37604535e-1, 1.4298143e-1, 1.44268327e-1, 1.47389357e-1, 1.49984424e-1,
        1.54650405e-1, 1.5811526e-1, 1.60822763e-1, 1.67806231e-1, 1.69990377e-1, 1.79699954e-1,
        1.80700505e-1, 1.81549183e-1, 1.89079428e-1, 1.96438821e-1, 1.97777673e-1, 1.99160122e-1,
        2.03349133e-1, 2.07696089e-1, 2.08984852e-1, 2.11879271e-1, 2.1214448e-1, 2.14935074e-1,
        2.16691254e-1, 2.17399668e-1, 2.17475219e-1, 2.17653452e-1, 2.1836674e-1, 2.18914512e-1,
        2.19065029e-1, 2.19796145e-1, 2.20616558e-1, 2.20673604e-1, 2.21617508e-1, 2.22665448e-1,
        2.22665448e-1, 2.22868347e-1, 2.23335073e-1, 2.23541341e-1, 2.24156224e-1, 2.24059717e-1,
        2.24040764e-1, 2.23483684e-1, 2.21680573e-1, 2.19998926e-1, 2.1958131e-1, 2.17449983e-1,
        2.13471265e-1, 2.10714906e-1, 2.07553389e-1, 2.00277215e-1, 1.97144596e-1, 1.94572893e-1,
        1.86874891e-1, 1.80263104e-1, 1.79449289e-1, 1.71343128e-1, 1.61442493e-1, 1.61364637e-1,
        1.46246772e-1, 1.42989913e-1, 1.35990883e-1, 1.26793055e-1, 1.24329741e-1, 1.13669402e-1,
        1.12941842e-1, 1.04089636e-1, 1.00241566e-1, 9.15871226e-2, 8.74041873e-2, 7.76156604e-2,
        7.37842488e-2, 5.98863555e-2, 5.98402276e-2, 4.71645575e-2, 3.67173694e-2, 3.5448255e-2,
        2.83417539e-2, 2.12363273e-2, 1.51761146e-2, 1.04790698e-2, 7.21591292e-3, 5.00990343e-3,
        3.43316053e-3, 2.24538616e-3,
      ],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      line: {color: '#69b3a2', dash: 'dash'},
      mode: 'lines+markers',
      name: 'v0 Prod Mean',
      x: [2.779575791784628, 2.779575791784628],
      y: [0, 0.22286834717400214],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      line: {color: '#69b3a2'},
      mode: 'lines',
      name: 'v0 Prod Median',
      x: [2.762513380478547, 2.762513380478547],
      y: [0, 0.2226654477262099],
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
    },
  ],
  layout: {
    template: {
      data: {
        bar: [
          {
            error_x: {color: '#2a3f5f'},
            error_y: {color: '#2a3f5f'},
            marker: {
              line: {color: '#E5ECF6', width: 0.5},
              pattern: {fillmode: 'overlay', size: 10, solidity: 0.2},
            },
            type: 'bar',
          },
        ],
        barpolar: [
          {
            marker: {
              line: {color: '#E5ECF6', width: 0.5},
              pattern: {fillmode: 'overlay', size: 10, solidity: 0.2},
            },
            type: 'barpolar',
          },
        ],
        carpet: [
          {
            aaxis: {
              endlinecolor: '#2a3f5f',
              gridcolor: 'white',
              linecolor: 'white',
              minorgridcolor: 'white',
              startlinecolor: '#2a3f5f',
            },
            baxis: {
              endlinecolor: '#2a3f5f',
              gridcolor: 'white',
              linecolor: 'white',
              minorgridcolor: 'white',
              startlinecolor: '#2a3f5f',
            },
            type: 'carpet',
          },
        ],
        choropleth: [{colorbar: {outlinewidth: 0, ticks: ''}, type: 'choropleth'}],
        contour: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'contour',
          },
        ],
        contourcarpet: [{colorbar: {outlinewidth: 0, ticks: ''}, type: 'contourcarpet'}],
        heatmap: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'heatmap',
          },
        ],
        heatmapgl: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'heatmapgl',
          },
        ],
        histogram: [
          {marker: {pattern: {fillmode: 'overlay', size: 10, solidity: 0.2}}, type: 'histogram'},
        ],
        histogram2d: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'histogram2d',
          },
        ],
        histogram2dcontour: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'histogram2dcontour',
          },
        ],
        mesh3d: [{colorbar: {outlinewidth: 0, ticks: ''}, type: 'mesh3d'}],
        parcoords: [{line: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'parcoords'}],
        pie: [{automargin: true, type: 'pie'}],
        scatter: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scatter'}],
        scatter3d: [
          {
            line: {colorbar: {outlinewidth: 0, ticks: ''}},
            marker: {colorbar: {outlinewidth: 0, ticks: ''}},
            type: 'scatter3d',
          },
        ],
        scattercarpet: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scattercarpet'}],
        scattergeo: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scattergeo'}],
        scattergl: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scattergl'}],
        scattermapbox: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scattermapbox'}],
        scatterpolar: [{marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scatterpolar'}],
        scatterpolargl: [
          {marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scatterpolargl'},
        ],
        scatterternary: [
          {marker: {colorbar: {outlinewidth: 0, ticks: ''}}, type: 'scatterternary'},
        ],
        surface: [
          {
            colorbar: {outlinewidth: 0, ticks: ''},
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
            type: 'surface',
          },
        ],
        table: [
          {
            cells: {fill: {color: '#EBF0F8'}, line: {color: 'white'}},
            header: {fill: {color: '#C8D4E3'}, line: {color: 'white'}},
            type: 'table',
          },
        ],
      },
      layout: {
        annotationdefaults: {arrowcolor: '#2a3f5f', arrowhead: 0, arrowwidth: 1},
        autotypenumbers: 'strict',
        coloraxis: {colorbar: {outlinewidth: 0, ticks: ''}},
        colorscale: {
          diverging: [
            [0, '#8e0152'],
            [0.1, '#c51b7d'],
            [0.2, '#de77ae'],
            [0.3, '#f1b6da'],
            [0.4, '#fde0ef'],
            [0.5, '#f7f7f7'],
            [0.6, '#e6f5d0'],
            [0.7, '#b8e186'],
            [0.8, '#7fbc41'],
            [0.9, '#4d9221'],
            [1, '#276419'],
          ],
          sequential: [
            [0.0, '#0d0887'],
            [0.1111111111111111, '#46039f'],
            [0.2222222222222222, '#7201a8'],
            [0.3333333333333333, '#9c179e'],
            [0.4444444444444444, '#bd3786'],
            [0.5555555555555556, '#d8576b'],
            [0.6666666666666666, '#ed7953'],
            [0.7777777777777778, '#fb9f3a'],
            [0.8888888888888888, '#fdca26'],
            [1.0, '#f0f921'],
          ],
          sequentialminus: [
            [0.0, '#0d0887'],
            [0.1111111111111111, '#46039f'],
            [0.2222222222222222, '#7201a8'],
            [0.3333333333333333, '#9c179e'],
            [0.4444444444444444, '#bd3786'],
            [0.5555555555555556, '#d8576b'],
            [0.6666666666666666, '#ed7953'],
            [0.7777777777777778, '#fb9f3a'],
            [0.8888888888888888, '#fdca26'],
            [1.0, '#f0f921'],
          ],
        },
        colorway: [
          '#636efa',
          '#EF553B',
          '#00cc96',
          '#ab63fa',
          '#FFA15A',
          '#19d3f3',
          '#FF6692',
          '#B6E880',
          '#FF97FF',
          '#FECB52',
        ],
        font: {color: '#2a3f5f'},
        geo: {
          bgcolor: 'white',
          lakecolor: 'white',
          landcolor: '#E5ECF6',
          showlakes: true,
          showland: true,
          subunitcolor: 'white',
        },
        hoverlabel: {align: 'left'},
        hovermode: 'closest',
        mapbox: {style: 'light'},
        paper_bgcolor: 'white',
        plot_bgcolor: '#E5ECF6',
        polar: {
          angularaxis: {gridcolor: 'white', linecolor: 'white', ticks: ''},
          bgcolor: '#E5ECF6',
          radialaxis: {gridcolor: 'white', linecolor: 'white', ticks: ''},
        },
        scene: {
          xaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            gridwidth: 2,
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
          },
          yaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            gridwidth: 2,
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
          },
          zaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            gridwidth: 2,
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
          },
        },
        shapedefaults: {line: {color: '#2a3f5f'}},
        ternary: {
          aaxis: {gridcolor: 'white', linecolor: 'white', ticks: ''},
          baxis: {gridcolor: 'white', linecolor: 'white', ticks: ''},
          bgcolor: '#E5ECF6',
          caxis: {gridcolor: 'white', linecolor: 'white', ticks: ''},
        },
        title: {x: 0.05},
        xaxis: {
          automargin: true,
          gridcolor: 'white',
          linecolor: 'white',
          ticks: '',
          title: {standoff: 15},
          zerolinecolor: 'white',
          zerolinewidth: 2,
        },
        yaxis: {
          automargin: true,
          gridcolor: 'white',
          linecolor: 'white',
          ticks: '',
          title: {standoff: 15},
          zerolinecolor: 'white',
          zerolinewidth: 2,
        },
      },
    },
    xaxis: {
      anchor: 'y',
      domain: [0.0, 1.0],
      showgrid: false,
      gridcolor: 'black',
      linecolor: 'black',
      range: [0, 0.4430867349314306],
      dtick: 0.05,
      fixedrange: true,
    },
    yaxis: {
      anchor: 'x',
      domain: [0.9200000000000002, 1.0],
      showgrid: false,
      showline: false,
      showticklabels: false,
      zeroline: false,
      color: 'black',
      autorange: true,
      rangemode: 'normal',
      fixedrange: true,
    },
    xaxis2: {
      anchor: 'y2',
      domain: [0.0, 1.0],
      fixedrange: false,
      range: [-1.9522394460096473, 6.176979021411623],
      title: {text: 'Loan Amount Drift'},
    },
    yaxis2: {
      anchor: 'x2',
      domain: [0.0, 0.7200000000000001],
      title: {text: 'Probability Density'},
      fixedrange: true,
    },
    annotations: [
      {
        font: {size: 16},
        showarrow: false,
        text: "Drift Score (Earth Mover's Distance)",
        x: 0.5,
        xanchor: 'center',
        xref: 'paper',
        y: 1.0000000000000002,
        yanchor: 'bottom',
        yref: 'paper',
      },
      {
        font: {size: 16},
        showarrow: false,
        text: 'Distribution Plot',
        x: 0.5,
        xanchor: 'center',
        xref: 'paper',
        y: 0.7200000000000001,
        yanchor: 'bottom',
        yref: 'paper',
      },
    ],
    legend: {title: {text: 'Legend'}, yanchor: 'top', y: 0.6},
    title: {text: 'Drift in feature loan_amnt', x: 0.5, xanchor: 'center'},
    height: 400,
    bargroupgap: 0,
  },
}

export const plot_Test = {
  data: [
    {
      alignmentgroup: 'true',
      hovertemplate: 'City=SF<br>Fruit=%{x}<br>Amount=%{y}<extra></extra>',
      legendgroup: 'SF',
      marker: {
        color: '#636efa',
        pattern: {
          shape: '',
        },
      },
      name: 'SF',
      offsetgroup: 'SF',
      orientation: 'v',
      showlegend: true,
      textposition: 'auto',
      x: ['Apples', 'Oranges', 'Bananas'],
      xaxis: 'x',
      y: [4, 1, 2],
      yaxis: 'y',
      type: 'bar',
    },
    {
      alignmentgroup: 'true',
      hovertemplate: 'City=Montreal<br>Fruit=%{x}<br>Amount=%{y}<extra></extra>',
      legendgroup: 'Montreal',
      marker: {
        color: '#00cc96',
        pattern: {
          shape: '',
        },
      },
      name: 'Montreal',
      offsetgroup: 'Montreal',
      orientation: 'v',
      showlegend: true,
      textposition: 'auto',
      x: ['Apples', 'Oranges', 'Bananas'],
      xaxis: 'x',
      y: [2, 4, 5],
      yaxis: 'y',
      type: 'bar',
    },
  ],
  layout: {
    template: {
      data: {
        histogram2dcontour: [
          {
            type: 'histogram2dcontour',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        choropleth: [
          {
            type: 'choropleth',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
          },
        ],
        histogram2d: [
          {
            type: 'histogram2d',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        heatmap: [
          {
            type: 'heatmap',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        heatmapgl: [
          {
            type: 'heatmapgl',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        contourcarpet: [
          {
            type: 'contourcarpet',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
          },
        ],
        contour: [
          {
            type: 'contour',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        surface: [
          {
            type: 'surface',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
            colorscale: [
              [0.0, '#0d0887'],
              [0.1111111111111111, '#46039f'],
              [0.2222222222222222, '#7201a8'],
              [0.3333333333333333, '#9c179e'],
              [0.4444444444444444, '#bd3786'],
              [0.5555555555555556, '#d8576b'],
              [0.6666666666666666, '#ed7953'],
              [0.7777777777777778, '#fb9f3a'],
              [0.8888888888888888, '#fdca26'],
              [1.0, '#f0f921'],
            ],
          },
        ],
        mesh3d: [
          {
            type: 'mesh3d',
            colorbar: {
              outlinewidth: 0,
              ticks: '',
            },
          },
        ],
        scatter: [
          {
            fillpattern: {
              fillmode: 'overlay',
              size: 10,
              solidity: 0.2,
            },
            type: 'scatter',
          },
        ],
        parcoords: [
          {
            type: 'parcoords',
            line: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scatterpolargl: [
          {
            type: 'scatterpolargl',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        bar: [
          {
            error_x: {
              color: '#2a3f5f',
            },
            error_y: {
              color: '#2a3f5f',
            },
            marker: {
              line: {
                color: '#E5ECF6',
                width: 0.5,
              },
              pattern: {
                fillmode: 'overlay',
                size: 10,
                solidity: 0.2,
              },
            },
            type: 'bar',
          },
        ],
        scattergeo: [
          {
            type: 'scattergeo',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scatterpolar: [
          {
            type: 'scatterpolar',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        histogram: [
          {
            marker: {
              pattern: {
                fillmode: 'overlay',
                size: 10,
                solidity: 0.2,
              },
            },
            type: 'histogram',
          },
        ],
        scattergl: [
          {
            type: 'scattergl',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scatter3d: [
          {
            type: 'scatter3d',
            line: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scattermapbox: [
          {
            type: 'scattermapbox',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scatterternary: [
          {
            type: 'scatterternary',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        scattercarpet: [
          {
            type: 'scattercarpet',
            marker: {
              colorbar: {
                outlinewidth: 0,
                ticks: '',
              },
            },
          },
        ],
        carpet: [
          {
            aaxis: {
              endlinecolor: '#2a3f5f',
              gridcolor: 'white',
              linecolor: 'white',
              minorgridcolor: 'white',
              startlinecolor: '#2a3f5f',
            },
            baxis: {
              endlinecolor: '#2a3f5f',
              gridcolor: 'white',
              linecolor: 'white',
              minorgridcolor: 'white',
              startlinecolor: '#2a3f5f',
            },
            type: 'carpet',
          },
        ],
        table: [
          {
            cells: {
              fill: {
                color: '#EBF0F8',
              },
              line: {
                color: 'white',
              },
            },
            header: {
              fill: {
                color: '#C8D4E3',
              },
              line: {
                color: 'white',
              },
            },
            type: 'table',
          },
        ],
        barpolar: [
          {
            marker: {
              line: {
                color: '#E5ECF6',
                width: 0.5,
              },
              pattern: {
                fillmode: 'overlay',
                size: 10,
                solidity: 0.2,
              },
            },
            type: 'barpolar',
          },
        ],
        pie: [
          {
            automargin: true,
            type: 'pie',
          },
        ],
      },
      layout: {
        autotypenumbers: 'strict',
        colorway: [
          '#636efa',
          '#EF553B',
          '#00cc96',
          '#ab63fa',
          '#FFA15A',
          '#19d3f3',
          '#FF6692',
          '#B6E880',
          '#FF97FF',
          '#FECB52',
        ],
        font: {
          color: '#2a3f5f',
        },
        hovermode: 'closest',
        hoverlabel: {
          align: 'left',
        },
        paper_bgcolor: 'white',
        plot_bgcolor: '#E5ECF6',
        polar: {
          bgcolor: '#E5ECF6',
          angularaxis: {
            gridcolor: 'white',
            linecolor: 'white',
            ticks: '',
          },
          radialaxis: {
            gridcolor: 'white',
            linecolor: 'white',
            ticks: '',
          },
        },
        ternary: {
          bgcolor: '#E5ECF6',
          aaxis: {
            gridcolor: 'white',
            linecolor: 'white',
            ticks: '',
          },
          baxis: {
            gridcolor: 'white',
            linecolor: 'white',
            ticks: '',
          },
          caxis: {
            gridcolor: 'white',
            linecolor: 'white',
            ticks: '',
          },
        },
        coloraxis: {
          colorbar: {
            outlinewidth: 0,
            ticks: '',
          },
        },
        colorscale: {
          sequential: [
            [0.0, '#0d0887'],
            [0.1111111111111111, '#46039f'],
            [0.2222222222222222, '#7201a8'],
            [0.3333333333333333, '#9c179e'],
            [0.4444444444444444, '#bd3786'],
            [0.5555555555555556, '#d8576b'],
            [0.6666666666666666, '#ed7953'],
            [0.7777777777777778, '#fb9f3a'],
            [0.8888888888888888, '#fdca26'],
            [1.0, '#f0f921'],
          ],
          sequentialminus: [
            [0.0, '#0d0887'],
            [0.1111111111111111, '#46039f'],
            [0.2222222222222222, '#7201a8'],
            [0.3333333333333333, '#9c179e'],
            [0.4444444444444444, '#bd3786'],
            [0.5555555555555556, '#d8576b'],
            [0.6666666666666666, '#ed7953'],
            [0.7777777777777778, '#fb9f3a'],
            [0.8888888888888888, '#fdca26'],
            [1.0, '#f0f921'],
          ],
          diverging: [
            [0, '#8e0152'],
            [0.1, '#c51b7d'],
            [0.2, '#de77ae'],
            [0.3, '#f1b6da'],
            [0.4, '#fde0ef'],
            [0.5, '#f7f7f7'],
            [0.6, '#e6f5d0'],
            [0.7, '#b8e186'],
            [0.8, '#7fbc41'],
            [0.9, '#4d9221'],
            [1, '#276419'],
          ],
        },
        xaxis: {
          gridcolor: 'white',
          linecolor: 'white',
          ticks: '',
          title: {
            standoff: 15,
          },
          zerolinecolor: 'white',
          automargin: true,
          zerolinewidth: 2,
        },
        yaxis: {
          gridcolor: 'white',
          linecolor: 'white',
          ticks: '',
          title: {
            standoff: 15,
          },
          zerolinecolor: 'white',
          automargin: true,
          zerolinewidth: 2,
        },
        scene: {
          xaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
            gridwidth: 2,
          },
          yaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
            gridwidth: 2,
          },
          zaxis: {
            backgroundcolor: '#E5ECF6',
            gridcolor: 'white',
            linecolor: 'white',
            showbackground: true,
            ticks: '',
            zerolinecolor: 'white',
            gridwidth: 2,
          },
        },
        shapedefaults: {
          line: {
            color: '#2a3f5f',
          },
        },
        annotationdefaults: {
          arrowcolor: '#2a3f5f',
          arrowhead: 0,
          arrowwidth: 1,
        },
        geo: {
          bgcolor: 'white',
          landcolor: '#E5ECF6',
          subunitcolor: 'white',
          showland: true,
          showlakes: true,
          lakecolor: 'white',
        },
        title: {
          x: 0.05,
        },
        mapbox: {
          style: 'light',
        },
      },
    },
    xaxis: {
      anchor: 'y',
      domain: [0.0, 1.0],
      title: {
        text: 'Fruit',
      },
    },
    yaxis: {
      anchor: 'x',
      domain: [0.0, 1.0],
      title: {
        text: 'Amount',
      },
    },
    legend: {
      title: {
        text: 'City',
      },
      tracegroupgap: 0,
    },
    margin: {
      t: 60,
    },
    barmode: 'group',
  },
}
