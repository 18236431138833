/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { setStyles } from '../helpers/ActivityUtil';
import { options, severity, status } from "../helpers/ActivityVariables";
import { AnnotateModalEdit } from './AnnotateModalEdit';

export const AnnotateModal = ({ currentAnnot, show, handleClose, chartData,
  applicationId, ts, query, queryPropName, fields,
  userModalMessage, defaultComment, share }) => {
  // ts is the XAxis key to use for fetching annotations to render
  const annotateData = {
    // chartData: chartData,
    applicationId: applicationId,
  }

  const [message, setMessage] = useState('')
  // const { applicationState } = useSelector(
  //   (state) => ({ applicationState: state.application }),
  //   shallowEqual
  // )

  useEffect(() => {
    // ts value can be of type string or number depending on where it is called from
    // always convert to number
    setMessage('')
    // let time_key = parseInt(ts)

  }, [ts])

  const discardForm = () => {
    setStyles(options, options[0].value);
    setStyles(severity, severity[0].value);
    setStyles(status, status[0].value);
    handleClose()
  }

  return (
    <Modal
      id='oai_modal_invite'
      tabIndex={-2}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-650px h-auto'
      show={show}
      onHide={discardForm}
    >
      <AnnotateModalEdit
        currentAnnot={currentAnnot}
        annotateData={annotateData}
        handleClose={discardForm}
        ts={ts}
        query={query}
        queryPropName={queryPropName}
        userModalMessage={userModalMessage}
        defaultComment={defaultComment}
        share={share}
        fields={fields}

      />
      {/*)}*/}
    </Modal>
  )
}
