import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activities: [],
  annots: [],
  startDate: null,
  endDate: null,
  filters: {},
};

export const activityStateSlice = createSlice({
  name: 'activityState',
  initialState: initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    addDates: (state, action) => {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    addStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    addEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    initAnnots: (state, action) => {
      state.annots = action.payload;
    },
    addAnnots: (state, action) => {
      const annot = action.payload;
      state.annots = [annot, ...state.annots];
    },
    replaceAnnot: (state, action) => {
      const { data: annot, index } = action.payload;
      state.annots = [
        ...state.annots.slice(0, index),
        annot,
        ...state.annots.slice(index + 1)
      ];
    },
    initActivity: (state, action) => {
      state.activities = action.payload;
    },
    addActivity: (state, action) => {
      const activity = action.payload;
      state.activities = [activity, ...state.activities];
    },
    replaceActivity: (state, action) => {
      const { data: activity, index } = action.payload;
      state.activities = [
        ...state.activities.slice(0, index),
        activity,
        ...state.activities.slice(index + 1)
      ];
    },
  },
});

export const {
  reset,
  addStartDate,
  addEndDate,
  initAnnots,
  addAnnots,
  replaceAnnot,
  initActivity,
  addActivity,
  replaceActivity,
} = activityStateSlice.actions;

export default activityStateSlice.reducer;
