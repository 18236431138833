/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/*<AsideMenuItem*/}
      {/*  to='/dashboard'*/}
      {/*  icon='/media/icons/duotune/general/gen001.svg'*/}
      {/*  title={intl.formatMessage({id: 'MENU.DASHBOARD'})}*/}
      {/*/>*/}
      <AsideMenuItem
        to='/application'
        icon='/media/icons/duotune/general/gen008.svg'
        title='Applications'
      />
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<AsideMenuItem to='/alerts' icon='/media/icons/duotune/general/gen007.svg' title='Alerts' />*/}
      <AsideMenuItem
        to='/application/activity'
        icon='/media/icons/duotune/text/txt001.svg'
        title='Activity Log'
      />

      <AsideMenuItem
        to='/application/integrations'
        icon='/media/icons/duotune/text/txt001.svg'
        title='Integrations'
      />

      <AsideMenuItem
        to='/application/status'
        icon='/media/icons/duotune/text/txt001.svg'
        title='Application Status'
      />      
      {/*<AsideMenuItem*/}
      {/*  to='/monitors'*/}
      {/*  icon='/media/icons/duotune/coding/cod007.svg'*/}
      {/*  // or /media/icons/duotune/electronics/elc004.svg*/}
      {/*  title='Monitors'*/}
      {/*/>*/}
    </>
  )
}
