/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {getUserByToken, signInGithub} from '../core/_requests'
import {useAuth} from '../core/Auth'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function SignInGithubCallback() {
  const query = useQuery()
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  // Added so that await can be used to block call until API call completes
  // without this login status is not registered and redirect to dashboard does not work
  async function getUserInfo(auth_token) {
    const user_info = await getUserByToken(auth_token)
    return user_info.data
  }

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      signInGithub(query.get('code'))
        .then((response) => {
          const auth_local = response.data
          saveAuth(auth_local)
          const user = getUserInfo(auth_local.api_token)
          setCurrentUser(user)
          navigate(`/`, {replace: true})
          return auth_local
        })
        // // .then(response => {
        // //     try {
        // //         const user = getUserInfo(response.api_token)
        // //         //const {data: user} = getUserByToken(response.api_token)
        // //         //setCurrentUser(user)
        // //         //setShouldRedirect(true)
        // //         return user
        // //     } catch (error) {
        // //         console.error(error)
        // //         saveAuth(undefined)
        // //         // navigate(`/auth/login?error=${error}`);
        // //         // setSubmitting(false)
        // //         setLoading(false)
        // //     }
        // // })
        // .then(response => {
        //     const user = response.data
        //     setCurrentUser(user)
        //     
        //     navigate(`/`, { replace: true });
        // })
        .catch((error) => {
          console.error(error)
          saveAuth(undefined)
          // navigate(`/auth/login?error=${error}`);
          // setSubmitting(false)
          setLoading(false)
        })
    } else {
      // loading completed
      // navigate(`/dash/dashboard`, { replace: true });
    }
  }, [loading, query, saveAuth, setCurrentUser, navigate])

  return (
    <>
      <div className='text-center mb-10'>
        <div className='text-gray-400 fw-bold fs-4'>Logging in to Outpace</div>
      </div>
    </>
  )
}
