import { createSlice } from '@reduxjs/toolkit';

// "filters": {
//     "1": {
//         "condition": "is", ... selected option from condition dropdown
//         "field": "conv_id",
//         "id": 1698784807775, ... random number for uniqueness of ui
//         "inputType": 1, ... to display the input elements, 1 for single input, 2 for tags (pills), 3 for betweem
//         "start_value": "a6e6cfc6-ed32-4d54-b663-cf3dfb0a120f"
//         "end_value": "a6e6cfc6-ed32-4d54-b663-cf3dfb0a120f" ... string or number
//     }
// }


const initialState = {
    instructions: {
        method: '',
    },
    aggregations: {
        type: '',
        field: '',
        interval: '',
    },
    application_id: '',
    metric: '',
    datasets: [
        {
            artifactId: '',
            startDate: '',
            endDate: '',
            filters: {},
            name: '',
            metric: ''
        },
        {
            artifactId: '',
            startDate: '',
            endDate: '',
            filters: {},
            name: '',
            metric: ''
        },
    ],
};


export const queryStateSlice = createSlice({
    name: 'queryState',
    initialState: initialState,
    reducers: {
        reset: () => {
            return initialState
        },
        setWholeState: (state, action) => {
            return action.payload;
        },
        setInstructions: (state, action) => {
            state.instructions = action.payload;
        },
        setAggregations: (state, action) => {
            state.aggregations = action.payload;
        },
        setApplicationId: (state, action) => {
            state.application_id = action.payload;
        },
        setMetric: (state, action) => {
            state.metric = action.payload;
        },
        setDatasetFilters: (state, action) => {
            const { datasetIndex, filters } = action.payload;
            state.datasets[datasetIndex].filters = filters;
        },
        setDataset: (state, action) => {
            const { datasetIndex, name, ...rest } = action.payload;
            state.datasets[datasetIndex] = {
                ...state.datasets[datasetIndex],
                name,
                ...rest,
            };
        },
    },
});

export const {
    setInstructions,
    setAggregations,
    setApplicationId,
    setMetric,
    setDatasetFilters,
    setDataset,
} = queryStateSlice.actions;

export default queryStateSlice.reducer;
