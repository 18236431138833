import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import draftToMarkdown from 'draftjs-to-markdown'
import { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

class ActivityMarkup extends Component {
  state = {
    editorState: this.props.markdown ?
      EditorState.createWithContent(convertFromRaw(this.props.markdown))
      : EditorState.createEmpty()
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    })
    this.props.setMarkdown(convertToRaw(editorState.getCurrentContent()))
    this.props.setMarkdownValue(draftToMarkdown(convertToRaw(editorState.getCurrentContent())))

  }

  render() {
    const { editorState } = this.state
    return (
      <div style={{ width: '100%' }}>
        <Editor
          editorState={editorState}
          wrapperClassName='demo-wrapper'
          editorClassName='demo-editor'
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              // 'fontSize',
              // 'fontFamily',
              // 'list',
              'colorPicker',
              'link',
            ],
          }}
          // toolbar={{options: ['bold','italic','underline','strikethrough']}}
          style={{
            marginBottom: '100px',
          }}
        />
        {/* <textarea
          disabled
          style={{ width: '100%', height: '100px' }}
          value={draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
    )
  }
}

export default ActivityMarkup

