import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entity: undefined,
  features: null,
  entitiesCompareFeatures: null,
  error: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const artifactSlice = createSlice({
  name: 'artifacts',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },

    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },

    artifactsFetched: (state, action) => {
      const {totalCount, entities} = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },

    artifactFetched: (state, action) => {
      const {entity} = action.payload
      state.listLoading = false
      state.error = null
      state.entity = entity
      state.totalCount = 1
    },

    artifactFeaturesFetched: (state, action) => {
      const {features} = action.payload
      state.listLoading = false
      state.error = null
      state.features = features
    },

    artifactFeaturesCompare: (state, action) => {
      const {entitiesCompareFeatures} = action.payload
      state.listLoading = false
      state.error = null
      state.entitiesCompareFeatures = entitiesCompareFeatures
    },
  },
})
