import React, { useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import ReactJson from "react-json-view";
import {API_URL} from "../../../constants";
import {template_plotly_white} from "../../partials/charts/plotly/plotly_template";
import Plot from "react-plotly.js";

const DataVisualizer = () => {
  const [json, setJson] = useState({});
  const [result, setResult] = useState({});
  const [plotData, setPlotData] = useState({});

  const handleButtonClick = async () => {
    // try {
    //   const response = await axios.post("https://your-api-url", json);
    //   setResult(response.data);
    // } catch (error) {
    //   console.error(error);
    // }
    
    setResult(await getPlot(json))
  };

  // const getPlot = async (query) => {
  async function getPlot(     query) {
    
    let data = await axios
      .post(`${API_URL}/data?src=compare&method=${query.instructions.method}`, query)
      .then((response) => {
        if (response.data.success) {
          let perfData = response.data.data

          perfData.layout = {};
          perfData.layout.template = template_plotly_white
          const firstKey = Object.keys(perfData)[0];
          const plotlyData = perfData[firstKey].plotly ? perfData[firstKey].plotly : {};
          setPlotData(plotlyData)
          return perfData
          // dispatch(actions.updateModelPerformanceData({ data: response.data['data'] }));
          // dispatch(actions.updateQuery({query: query}))
        } else {
          return {};
        }
      })
      .catch((error) => {
        //
        //
        //   error: { message: error.response.data['error_message'] },
        //   callType: callTypes.list,
        // })
        // setChart(initChartData)
        return {};
        // setChart([])
      })
    return data;
  }

  return (
    <div>
      {/*<div>*/}
      <Row>
      {Object.keys(result).length > 0 && (
          <>
            <div style={{position: 'relative', height: '400px'}}>
              <div>Data fetched </div>
              <Plot
                data={plotData.data}
                // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
                layout={plotData.layout}
                config={{displayModeBar: false, responsive: true}}
                style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
              />
            </div>
          </>
      )}
      {Object.keys(result).length <= 0 && (
          <div>No data to render plot</div>
      )}
      </Row>
      {/*</div>*/}
      {/*<div>*/}
        <Row>
        <Col>
          <Button variant="primary" onClick={handleButtonClick}>
            Submit
          </Button>
          <JSONInput
            placeholder={json}
            onChange={({ jsObject }) => setJson(jsObject)}
            height="auto"
            width="100%"
          />
        </Col>
        <Col>
          <ReactJson src={result} />
        </Col>
      </Row>
      {/*</div>*/}
    </div>
  );
};

export default DataVisualizer;
